import React from 'react'

export default function UseTabs({ activeTab, tabChange, allTabs, bg = true }) {
    return (
        <div className={`overflow-hidden p-1 py-2 ${!bg ? '' : 'border border-gray-100 bg-gray-50 btw-0'}`}>
            <ul className={`flex items-center gap-5 text-md font-medium ${!bg ? "" : "pl-4"}`}>
                {
                    allTabs.map((tab, index) => <li key={index} className='list-none'>
                        <p onClick={tabChange} id={tab?.id} className={`${activeTab === tab.id ? 'inline-flex cursor-pointer items-center gap-2 rounded-lg px-3 py-2 hover:bg-white hover:text-gray-700 hover:shadow bg-white shadow text-gray-700' : 'text-gray-500 cursor-pointer hover:bg-white hover:text-gray-700 hover:shadow hover:rounded-lg px-3 py-2'}`}> {tab.name}</p>
                    </li>
                    )
                }
            </ul>
        </div>
    )
}
