import React from 'react'

export default function LoadingButton({ title }) {
	return (
		<button type="button" className='btn btn-primary btn-md flex gap-2'>
			<span className="saving-loader"></span>
			{title}...
		</button>
	)
}
