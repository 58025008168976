import {
	ADDED_ADDRESS_ID,
	NEW_ORDER_ITEM,
	ADDED_ADDRESS_NAME,
	ADDED_FULL_ADDRESS,
	ADDED_ORDER_STATUS,
	LOADED_ORDER,
	ORDER_ADDED,
	SINGLE_ORDERED,
	CHANGE_FILTERING_STATUS,
	SET_TABLE_OF_ORDERS,
	SEARCH_ORDERS,
	ADDED_ORDER_PRODUCT_ITEM,
	ADDED_ALL_PRODUCTS,
	ADDED_PAYMENTS,
	ADDED_DUE_AMOUNT,
	ADDED_TOTAL_PAYMENT,
	ADDED_PRODUCT_DETAILS,
	ADDED_ORDERED_TOTAL_AMOUNT,
	SET_EDIT_ORDER_LOADING,
	REMOVE_ORDER_ITEM,
	IS_LOADING_ORDER,
	SET_IS_NEW_ORDER,
	SET_IS_WALK_IN_CUSTOMER,
	SET_ADDED_ORDER_PRODUCT_LOADING,
	SET_DELETE_ORDER_PRODUCT_LOADING,
	SET_DELETE_ORDER_PAYMENT_LOADING,
	ADDED_DISCOUNT_IN_ORDER,
	SET_DELETE_ORDER_LOADING,
} from "./actionType";

export const change_filtering_status = (value) => {
	return {
		type: CHANGE_FILTERING_STATUS,
		payload: value,
	};
};

export const set_is_new_order = (value) => {
	return {
		type: SET_IS_NEW_ORDER,
		payload: value,
	};
};

export const set_is_walk_in_customer = (value) => {
	return {
		type: SET_IS_WALK_IN_CUSTOMER,
		payload: value,
	};
};

export const is_loading_order = (value) => {
	return {
		type: IS_LOADING_ORDER,
		payload: value,
	};
};

export const set_edit_order_loading = (value) => {
	return {
		type: SET_EDIT_ORDER_LOADING,
		payload: value,
	};
};

export const set_all_orders = (value) => {
	return {
		type: LOADED_ORDER,
		payload: value,
	};
};

export const remove_order_item = (value) => {
	return {
		type: REMOVE_ORDER_ITEM,
		payload: value,
	};
};

export const set_table_of_orders = (value) => {
	return {
		type: SET_TABLE_OF_ORDERS,
		payload: value,
	};
};

export const search_orders = (value) => {
	return {
		type: SEARCH_ORDERS,
		payload: value,
	};
};

export const added_order = (value) => {
	return {
		type: ORDER_ADDED,
		payload: value,
	};
};

export const single_order = (value) => {
	return {
		type: SINGLE_ORDERED,
		payload: value,
	};
};

export const added_ordered_total_amount = (value) => {
	return {
		type: ADDED_ORDERED_TOTAL_AMOUNT,
		payload: value,
	};
};

export const added_all_product = (value) => {
	return {
		type: ADDED_ALL_PRODUCTS,
		payload: value,
	};
};

export const added_product_details = (value) => {
	return {
		type: ADDED_PRODUCT_DETAILS,
		payload: value,
	};
};

export const added_payments = (value) => {
	return {
		type: ADDED_PAYMENTS,
		payload: value,
	};
};

export const added_due_amount = (value) => {
	return {
		type: ADDED_DUE_AMOUNT,
		payload: value,
	};
};

export const added_address_name = (value) => {
	return {
		type: ADDED_ADDRESS_NAME,
		payload: value,
	};
};

export const added_full_address = (value) => {
	return {
		type: ADDED_FULL_ADDRESS,
		payload: value,
	};
};

export const added_address_id = (value) => {
	return {
		type: ADDED_ADDRESS_ID,
		payload: value,
	};
};

export const added_order_status = (value) => {
	return {
		type: ADDED_ORDER_STATUS,
		payload: value,
	};
};

export const add_total_payment_amount = (value) => {
	return {
		type: ADDED_TOTAL_PAYMENT,
		payload: value,
	};
};

export const added_order_product_item = (value) => {
	return {
		type: ADDED_ORDER_PRODUCT_ITEM,
		payload: value,
	};
};

export const added_discount_in_order = (value) => {
	return {
		type: ADDED_DISCOUNT_IN_ORDER,
		payload: value,
	};
};
export const set_delete_order_loading = (value) => {
	return {
		type: SET_DELETE_ORDER_LOADING,
		payload: value,
	};
};
export const added_add_order_product_loading = (value) => {
	return {
		type: SET_ADDED_ORDER_PRODUCT_LOADING,
		payload: value,
	};
};

export const delete_order_product_loading = (value) => {
	return {
		type: SET_DELETE_ORDER_PRODUCT_LOADING,
		payload: value,
	};
};

export const delete_order_payment_loading = (value) => {
	return {
		type: SET_DELETE_ORDER_PAYMENT_LOADING,
		payload: value,
	};
};

export const new_order_item = (value) => {
	return {
		type: NEW_ORDER_ITEM,
		payload: value,
	};
};
