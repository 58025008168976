import React, { useEffect, useState } from 'react'
import * as Yup from "yup";
import { DokaaniTable, ModalWithTable, useAxios } from '../../hooks';
import { getLocalStorage } from '../../hooks/useLocalStorage';
import toast from "react-hot-toast";

const formData = {
	fields: [
		{
			name: "name",
			type: "text",
			label: "Stuff Name",
			required: true,
			placeholder: "Rahim Hossain",
			value: "",
		},
		{
			name: "mobile",
			type: "phone",
			label: "Stuff Mobile",
			required: true,
			placeholder: "01*********",
			value: "",
		},
		{
			name: "role",
			type: "text",
			label: "Stuff Role",
			placeholder: "Manager",
			required: true,
			value: "",
		},
		{
			name: "status",
			type: "stuffStatus",
			label: "Stuff Status",
			required: true,
			value: "Active",
		},
		{
			name: "address",
			type: "textarea",
			label: "Stuff Address",
			placeholder: "Full Address",
			required: true,
			value: "",
		}
	],
	validation: {
		name: Yup.string().required("Name must not be empty!"),
		role: Yup.string().required("Role must not be empty!"),
		address: Yup.string().required("Address must not be empty!"),
	}
}
export default function StuffTab({ setAllStuff, allStuff, handleDelete }) {
	const store_id = getLocalStorage("activeStore");
	const axios = useAxios();
	const [stuffData, setStuffData] = useState([]);
	const [isSubmit, setIsSubmit] = useState(false);

	const addStuff = async () => {
		const response = await axios.post("/stores/stuff/add", { ...stuffData, store_id: store_id })
		const { status, data } = response.data;
		console.log(data)
		if (status) {
			setIsSubmit(false);
			setAllStuff(data)
			toast.success("Stuff added")
		} else {
			toast.error("Something went wrong")
		}
	}

	useEffect(() => {
		if (isSubmit) {
			addStuff();
		}
		// eslint-disable-next-line
	}, [isSubmit])

	return (
		<div className='py-6 px-6 form-section-wrapper rounded-none border-none'>
			<ModalWithTable
				twoColumn={true}
				tableName={"All Stuff"}
				newTableName={"Add Stuff"}
				btnLabel={"Add Stuff"}
				formData={formData}
				setFormData={setStuffData}
				isSubmit={setIsSubmit}
			>
				<DokaaniTable tblHeader={["ID", "Name", "Mobile", "Role", "Status", "Address", "Action"]} bodyKeys={["id", "stuff_name", "stuff_mobile", "stuff_role", "status", "address"]} bodyData={allStuff} handleDelete={handleDelete} />
			</ModalWithTable>
		</div>
	)
}
