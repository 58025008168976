import React, { useRef } from 'react'
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { parentContext } from '../App';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SetCookie } from '../hooks/Cookies';
import { useAuthAxios } from './useAuthAxios';
import toast from 'react-hot-toast';
import { UseHelmet } from '../hooks';
import { setLocalStorage } from '../hooks/useLocalStorage';

export default function CheckOTP() {

    const navigate = useNavigate();
    const axios = useAuthAxios();
    const mobile = localStorage.getItem("mobile");
    const isSetNewPin = localStorage.getItem("s_n_p");
    const [resendOTP, setResendOTP] = useState(false);
    const [showBtn, setShowBtn] = useState(false);
    const [timer, setTimer] = useState(60);
    const [error, setError] = useState("");
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const { setProgress } = useContext(parentContext);
    const intervalRef = useRef(null);

    /**  
     * check user logged in or not
    * if user logged in, navigate to dashboard
    * if user not logged in, navigate to login
    */
    useEffect(() => {
        setProgress(20);
        // check auth true or not
        const checkLogin = JSON.parse(localStorage.getItem("authentication"));
        setProgress(40)
        // if auth true then navigate to dashboard
        if (checkLogin) {
            setProgress(100)
            navigate("/dashboard");
        } else {
            // clear secure local storage

            setProgress(100)
        }
        // eslint-disable-next-line
    }, []);

    // if not exits mobile number in local storage then navigate to login page
    useEffect(() => {
        if (!mobile) {
            navigate("/");
        }
        // eslint-disable-next-line
    }, []);

    /**
     * handle otp length
     * @param {*} event otp
     * @return
     */
    function handleKeyDown(event) {
        if (event.target.value.length >= 6 && event.key !== "Enter" && event.key !== "Backspace" && event.key !== "Tab" && event.key !== "ArrowRight" && event.key !== "ArrowLeft" && event.key !== "a" && event.key !== "Delete" && event.key !== "Home" && event.key !== "End") { // limit to 3 digits
            event.preventDefault();
        }
    }

    /**
    * Handle pin number event.
    *
    * @param {Event} event - The event object.
    */
    const handlePinNumber = (event) => {
        //allow only numbers
        const restrictedKeys = /Enter|Backspace|Tab|ArrowRight|ArrowLeft|a|Delete|Home|Tab|End/;
        const isKeyRestricted = restrictedKeys.test(event.key);
        if (!isKeyRestricted && isNaN(Number(event.key))) {
            event.preventDefault();
        }
    }

    // realtime phone number validation
    useEffect(() => {
        if (watch().otp.length === 6) {
            setShowBtn(true);
        } else {
            setShowBtn(false);
        }
        // eslint-disable-next-line
    }, [watch().otp])

    // handle resend otp


    useEffect(() => {
        if (timer === 0) {
            setResendOTP(true);
            clearInterval(intervalRef.current);
        } else {
            intervalRef.current = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);
        }

        return () => {
            clearInterval(intervalRef.current);
        };
    }, [timer]);

    const handleResendOtp = async () => {
        setTimer(60);
        setResendOTP(false);
        const res = await axios.post(`/users/resend_otp`, { mobile });
        if (res.data.status === true) {
            toast.success("OTP sent")
        }
    };

    /**
     * when submit form then store to database
     * Save to user hash in local storage and navigate to new store page
     * @param {*} data otp 
     * @returns
     */
    const onSubmit = async data => {
        setProgress(30);
        const { otp, new_pin } = data;

        const response = !isSetNewPin ? await axios.post("/users/verify", { mobile, otp }) : await axios.post("/users/reset", { mobile, otp, new_pin });


        const result = response.data;
        setProgress(60);

        // if invalid otp code then show the error message 
        if (result.status === false) {
            setError(result?.data?.message);
            setProgress(100);
        }

        // if otp code is valid then go to the store page
        if (result.status === true) {
            const { type, hash } = result?.data;
            localStorage.removeItem("mobile");
            isSetNewPin && localStorage.removeItem("s_n_p");
            setLocalStorage("authentication", true);
            SetCookie('ask', btoa(hash), 7);
            const hashUserType = btoa(type);
            setLocalStorage("utype", hashUserType)
            if (type === "reseller") {
                window.location.replace("/reseller/dashboard");
                setProgress(100);
            } else {
                setProgress(100);
                window.location.replace("/dashboard");
            }
        }

    }

    return (
        <section className='h-screen flex overflow-hidden'>
            <UseHelmet title="OTP" />
            <main className="w-full p-6 max-w-sm m-auto">
                <img src="/assets/images/login/dokaani-en.svg" className="m-auto mb-8 h-10 w-auto" style={{ width: "160px" }} alt="dokaani logo" />

                <div className="basic-heading space-y-2 mb-4 px-4">
                    <h2 className="text-xl text-center font-semibold">
                        Send OTP to your phone
                    </h2>
                    <p className="text-base text-center">
                        Enter your valid OTP.
                    </p>
                    <p className="form-group-error text-xl text-center text-red-600 pt-2">{error && error}</p>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}><input type="hidden" name="authenticity_token" autoComplete="off" />
                    <div className="form-group">
                        <input autoComplete="off" placeholder="OTP" {...register("otp", { required: true, minLength: 6 })} onKeyDown={handleKeyDown} className="text-center form-control" type="number" name="otp" id="otp" />
                        {errors.otp && <span className='form-error'>Please enter valid OTP.</span>}
                    </div>
                    {
                        isSetNewPin && <div className="form-group">
                            <label htmlFor="pin">New Pin Number</label>
                            <input autoComplete="off" placeholder="******" onKeyDown={handlePinNumber} {...register("new_pin", { required: true, minLength: 5 })} className="form-control" type="password" name="new_pin" id="pin" />
                            {errors.new_pin && <span className='form-error'>PIN more than 5 character.</span>}
                        </div>
                    }

                    <button type="submit" className={`btn btn-primary btn-lg btn-block ${!showBtn ? "btn-disabled" : ""}`} data-controller="form-submit"><span>Verify OTP</span></button>
                    <div className="mt-4 flex space-x-4 justify-center">
                        {/* {timer === 0 && <p>Didn't receive the OTP? <span className='text-green-600 cursor-pointer' onClick={() => handleButtonClick()}> Resend OTP </span> </p>}
                        {timer > 0 && <p>Please Enter 6 digit OTP! {timer}</p>} */}
                        {resendOTP ? (
                            <p>Didn't receive the OTP? <span className='text-green-600 cursor-pointer' onClick={() => handleResendOtp()}> Resend OTP </span> </p>
                        ) : (
                            <p>Please Enter 6 digit OTP! {timer}</p>
                        )}
                    </div>
                </form>
                <div className="basic-links flex space-x-4 justify-center mt-4">
                    {/* if not send to otp code then again send otp */}
                    <span>Login with other account?</span><a data-controller="link" href="/">Sign In</a>
                </div>
            </main>
        </section>
    )
}
