export const ADDED_CUSTOMER = "customer/ADDED_CUSTOMER";
export const LOADED_CUSTOMER = "customer/LOADED_CUSTOMER";
export const UPDATE_CUSTOMER = "customer/UPDATE_CUSTOMER";
export const DELETE_CUSTOMER = "customer/DELETE_CUSTOMER";
export const ADDED_ADDRESS = "customer/ADDED_ADDRESS";
export const ADDED_HIGHLIGHT_ROW_ID = "customer/ADDED_HIGHLIGHT_ROW_ID";
export const DELETE_ADDRESS_DATA = "customer/DELETE_ADDRESS_DATA";
export const GET_EDIT_ADDRESS = "customer/GET_EDIT_ADDRESS";
export const CUSTOMER_FORM_DATA = "customer/CUSTOMER_FORM_DATA";
export const SET_TABLE_OF_CUSTOMER = "customer/SET_TABLE_OF_CUSTOMER";
export const SEARCH_CUSTOMER = "customer/SEARCH_CUSTOMER";
export const SET_ADDRESS = "customer/SET_ADDRESS";
export const SET_LOADING = "customer/SET_LOADING";
export const SET_CUSTOMER_DELETE_LOADING =
	"customer/SET_CUSTOMER_DELETE_LOADING";
