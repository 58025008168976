import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { bulkActionCreator } from '../../../../redux/actionCreators/bulkAction';
import NoDataTable from '../../../helpers/NoDataTable';
import { search_customer_action, is_customer_delete_loading } from '../../../../redux/customers/actions/actions';
import { delete_single_customer } from '../../../../redux/thunk/customers/customerThunk';
import UsePagination from '../../../hooks/UsePagination';
import DokaaniTablePagination from '../../../hooks/DokaaniTablePagination';
import useAxios from '../../../hooks/useAxios';
import toast from 'react-hot-toast';
import { set_is_new_order } from '../../../../redux/orders/action';
import { cn } from '../../../../util/cn';
import TableSkeleton from '../../../components/skeletonLoader/TableSkeleton';
import { PreLoaders } from '../../../helpers';
import Swal from 'sweetalert2';
import { parentContext } from '../../../App';

export default function CustomersTable() {

    const axios = useAxios();
    const navigate = useNavigate();
    const activeStore = localStorage.getItem("activeStore");
    const [, setCustomers] = useState();
    const [masterCheck, setMasterCheck] = useState(false);
    const [selectedList, setSelectedList] = useState([]);
    const bulkReducer = useSelector(state => state.bulkReducer);
    const customer = useSelector(state => state.customer);
    const { bulkState } = bulkReducer;
    const { isLoading, isDeleteLoading, customers, searchCustomers, ifSearchFalse } = customer;
    const dispatch = useDispatch();

    const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);
    const [isOrderConfirmationModalOpen, setIsOrderConfirmationModalOpen] = useState(false);
    const { setModalIndex } = useContext(parentContext);
    const [deleteCustomerId, seDeleteCustomerId] = useState(null);
    const [createOrderId, seCreateOrderId] = useState(null);

    //pagination
    const { currentPage, totalPages, paginatedData, handlePageChange } = UsePagination(customers, 10);

    // select / unselect table row 
    const onMasterCheck = (e) => {
        let tempList = paginatedData;
        tempList.map(p => (p.selected = e.target.checked));

        // update state
        setMasterCheck(e.target.checked);
        setSelectedList(paginatedData.filter(p => p.selected));
    }

    // update customer state and master checkbox state
    const oneItemChange = (e, item) => {
        let tempList = paginatedData;
        tempList.map(p => {
            if (p.id === item.id) {
                p.selected = e.target.checked;
            }
            return p;
        })
        // to control master checkbox state
        const totalCustomer = paginatedData.length;
        const totalCheckItems = tempList.filter(p => p.selected).length;
        // update state
        setMasterCheck(totalCheckItems === totalCustomer);
        setCustomers(tempList);
        setSelectedList(paginatedData.filter(p => p.selected));

    }
    const searchValue = (e) => {
        const keyword = e.target.value;

        if (keyword !== '') {
            const results = customers.filter((p) => {
                return p.mobile.includes(keyword);
            });

            if (results.length > 0) {
                dispatch(search_customer_action(results));
            }

            if (results.length < 1) {
                dispatch(search_customer_action(false));
            }

        } else if (keyword === '') {
            // If the text field is empty, show all customers
            dispatch(search_customer_action([]));
        } else {
            dispatch(search_customer_action([]));
        }
    };

    const handleCreateOrder = async (mobile) => {
        /**
         * Create order function
         */
        const response = await axios.post(`/orders/add`, { store_id: activeStore, mobile });
        const data = await response.data;

        if (data.status === true) {
            dispatch(set_is_new_order(true));
            navigate(`/order/${data?.data?.id}`)
        }
    }

    const handleMultipleDelete = () => {
        const ids = selectedList.map(p => p.id).join();

        dispatch(is_customer_delete_loading(true));
        dispatch(delete_single_customer(activeStore, ids));
        toast.success("Customer deleted.");
        dispatch(is_customer_delete_loading(false));
        setSelectedList([]);
        setMasterCheck(false);
    }

    // when click delete button then delete customer by id
    const handleCustomerDelete = async id => {
        dispatch(is_customer_delete_loading(true));
        await dispatch(delete_single_customer(activeStore, id));
        toast.success("Customer deleted.");
        dispatch(is_customer_delete_loading(false));
    }

    // const handleDeleteConfirmation = (id) => {
    //     Swal.fire({
    //         title: 'Are you sure?',
    //         text: "Want to delete this customer!",
    //         icon: "warning",
    //         showCancelButton: true,
    //         confirmButtonColor: '#3085d6',
    //         cancelButtonColor: '#d33',
    //         confirmButtonText: 'Yes, delete it!',
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             handleCustomerDelete(id);
    //         }
    //     });
    // };
    const handleCustomerDeleteConfirmation = (id) => {
        setIsDeleteConfirmationModalOpen(true);
        seDeleteCustomerId(id);
    };

    const handleDeleteCustomer = () => {
        if (deleteCustomerId) {
            handleCustomerDelete(deleteCustomerId, activeStore, setSelectedList, setMasterCheck);
            setIsDeleteConfirmationModalOpen(false);
        }
    };
    // isDeleteConfirmationModalOpen for stop the sidebar 
    useEffect(() => {
        setModalIndex(isDeleteConfirmationModalOpen);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDeleteConfirmationModalOpen])

    // const handleOrderConfirmation = (id) => {
    //     Swal.fire({
    //         title: 'Create an order?',
    //         text: "Want to create an order for this customer!",
    //         iconHtml: '<img src="assets/images/shopping-cart.png" height="50"/>',
    //         showCancelButton: true,
    //         confirmButtonColor: '#3085d6',
    //         cancelButtonColor: '#d33',
    //         confirmButtonText: 'Yes, Create it!',
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             handleCreateOrder(id);
    //         }
    //     });
    // };
    const handleOrderCreateConfirmation = (id) => {
        setIsOrderConfirmationModalOpen(true);
        seCreateOrderId(id);
    };

    const handleCreateAOrder = () => {
        if (createOrderId) {
            handleCreateOrder(createOrderId, activeStore, setSelectedList, setMasterCheck);
            setIsOrderConfirmationModalOpen(false);
        }
    };
    useEffect(() => {
        setModalIndex(isOrderConfirmationModalOpen);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOrderConfirmationModalOpen])


    /**
    * Extra function 
    * Handles the delete bulk action.
    **/
    const handleDeleteBulkAction = () => window.confirm(selectedList.length > 0 ? "Are you sure delete this customer?" : "Please select at least one customer.") && selectedList.length > 0 && handleMultipleDelete();

    return (
        <div>
            <form className="flex item-center lg:justify-between sm:justify-start gap-4">
                <div className="form-group relative inline-block text-left">
                    <button onClick={() => { !bulkState && dispatch(bulkActionCreator(true)); bulkState && dispatch(bulkActionCreator(bulkState)) }} type="button" className="inline-flex w-[115px] items-center justify-center rounded-md border border-gray-300 bg-white px-4 py75 text-sm font-semibold text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-0 focus:ring-offset-0"> Bulk Actions <svg data-v-c5e129fe="" className="-mr-1 ml-2" width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path data-v-c5e129fe="" d="M8.23332 0.741797L4.99998 3.97513L1.76665 0.741797C1.44165 0.416797 0.91665 0.416797 0.59165 0.741797C0.26665 1.0668 0.26665 1.5918 0.59165 1.9168L4.41665 5.7418C4.74165 6.0668 5.26665 6.0668 5.59165 5.7418L9.41665 1.9168C9.74165 1.5918 9.74165 1.0668 9.41665 0.741797C9.09165 0.42513 8.55832 0.416797 8.23332 0.741797Z" fill="#9CA3AF"></path></svg></button>

                    <div className={(cn(
                        bulkState ? 'opacity-100' : 'opacity-0 pointer-events-none',
                        'transition-opacity duration-300 absolute left-0 z-10 mt-2-i w-56 border shadow-custom  border-gray-300 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
                    ))}>
                        <div className="py-1 hover-children font-medium">
                            <li onClick={handleDeleteBulkAction} className="flex items-center px-4 py-2 text-sxm text-red-800">
                                <svg
                                    viewBox="0 0 1024 1024"
                                    fill="currentColor"
                                    height="1.3rem"
                                    width="1.3rem"
                                    className='mr-3 text-red'
                                >
                                    <path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z" />
                                </svg> Delete </li>
                        </div>
                    </div>
                </div>
                {/* search input field */}
                <div className="form-group sm:w-auto w-full">
                    <input autoComplete="off" type="text" name="search" id="search" onChange={e => searchValue(e)} className="form-control" placeholder="Search" />
                </div>

            </form>
            {/* table checkbox select in html Table in react js */}
            <div data-controller="table">
                <div className="table-wrapper " data-table-target="tableWrapper">
                    <div className="table">
                        <div className="table-header with-chevron-padding">
                            <div className="table-row">
                                <div className="table-cell w-xsm">
                                    <label className="inline-flex items-center">
                                        <input onChange={e => onMasterCheck(e)} checked={masterCheck} type="checkbox" className="form-checkbox rounded text-primary-300 h-5 w-5" />
                                        <span className="ml-2 text-gray-700">Checkbox Label</span>
                                    </label>
                                </div>
                                <div className="table-cell w-44 md:flex-1">Phone</div>
                                <div className="table-cell flex-1 hidden md:block">Address</div>
                                <div className="table-cell w-ids">Actions</div>
                            </div>
                        </div>
                        {isDeleteLoading ? <PreLoaders /> : ""}
                        <TableSkeleton isLoading={isLoading}>
                            {
                                ((!searchCustomers || searchCustomers?.length > 0) ? (searchCustomers === false ? ifSearchFalse : searchCustomers) : paginatedData)?.map((customer, index) => {
                                    return <div key={index} className={`table-body`}>
                                        <div className="table-row with-chevron" data-turbo-frame="modal">
                                            <div className="table-cell w-xsm">
                                                <input type="checkbox" onChange={e => oneItemChange(e, customer)} checked={customer?.selected || false} id={String(customer?.id)} className="form-checkbox h-5 w-5 rounded text-primary-300" />
                                            </div>
                                            <Link to={`/customer/${customer?.id}`} className="table-cell w-44 md:flex-1" style={{ display: "inline" }}>
                                                {customer?.mobile}
                                            </Link>
                                            <Link to={`/customer/${customer?.id}`} className="table-cell flex-1 hidden md:block">
                                                {customer?.addresses[0]?.street_address}
                                            </Link>
                                            <div className="table-cell w-ids">
                                                <button className="text-primary-300 hover:text-primary-400  font-normal px-2 mr-2 py-1 rounded-[4px] text-[13px]" onClick={() => handleOrderCreateConfirmation(customer?.mobile)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
                                                    </svg>

                                                </button>
                                                <button className="text-red-500 hover:bg-text-600 font-normal px-1.5 pb-[0.1rem] rounded-[4px]  text-[13px]" onClick={() => handleCustomerDeleteConfirmation(customer?.id)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                    </svg>

                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                }
                                )
                            }
                        </TableSkeleton>
                    </div>
                    {!isLoading && (customers.length < 1 || !searchCustomers) ? <NoDataTable /> : ""}
                </div>
            </div>
            {
                customers.length > 10 && <DokaaniTablePagination currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />
            }

            {/* Delete Confirmation Modal */}
            {isDeleteConfirmationModalOpen && (
                <div className={`modal-wrapper  ${isDeleteConfirmationModalOpen ? 'pr20 animate-fade-in' : "!hidden"}`} data-controller="modal" aria-modal="true">
                    <div className="modal-inner modal-align-responsive">
                        <div className="modal-overlay" aria-hidden="true" data-action="click->modal#close"></div>
                        <span className="modal-center-trick" aria-hidden="true">​</span>
                        <turbo-frame data-modal-target="turboFrame" id="modal" target="_top" src="https://app.dokaani.com" complete="">
                            <div className="modal w-responsive">
                                <div className="bg-white p-6 rounded shadow-lg">
                                    <h2 className="text-xl font-semibold mb-4">Are you sure?</h2>
                                    <p className="mb-6">Want to delete this Customer!</p>
                                    <div className="flex justify-end">
                                        <button
                                            className="bg-gray-200 text-gray-800 px-4 py-2 rounded mr-2"
                                            onClick={() => setIsDeleteConfirmationModalOpen(false)}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className="bg-red-500 text-white px-4 py-2 rounded"
                                            onClick={handleDeleteCustomer}
                                        >
                                            Yes, delete it!
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </turbo-frame>
                    </div>
                </div>
            )}
            {/*  create order Modal */}
            {isOrderConfirmationModalOpen && (
                <div className={`modal-wrapper  ${isOrderConfirmationModalOpen ? 'pr20 animate-fade-in' : "!hidden"}`} data-controller="modal" aria-modal="true">
                    <div className="modal-inner modal-align-responsive">
                        <div className="modal-overlay" aria-hidden="true" data-action="click->modal#close"></div>
                        <span className="modal-center-trick" aria-hidden="true">​</span>
                        <turbo-frame data-modal-target="turboFrame" id="modal" target="_top" src="https://app.dokaani.com" complete="">
                            <div className="modal w-responsive">
                                <div className="bg-white p-6 rounded shadow-lg">
                                    <h2 className="text-xl font-semibold mb-4">Are you sure?</h2>
                                    <p className="mb-6">Want to create an order!</p>
                                    <div className="flex justify-end">
                                        <button
                                            className="bg-gray-200 text-gray-800 px-4 py-2 rounded mr-2"
                                            onClick={() => setIsOrderConfirmationModalOpen(false)}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className="bg-[#2d986b] text-white px-4 py-2 rounded"
                                            onClick={handleCreateAOrder}
                                        >
                                            Yes, Create order!
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </turbo-frame>
                    </div>
                </div>
            )}
        </div>
    )
}
