export const initialValues = {
	customers: [],
	isNewCustomer: false,
	addresses: [],
	getAddressData: [],
	tableOfCustomers: [],
	searchCustomers: [],
	ifSearchFalse: [],
	customerFormData: {},
	showAddressModal: false,
	addressEditID: 0,
	highlightedRowID: 0,
	isSubmit: false,
	isLoading: true,
	isDeleteLoading: false,
};
