import useAxios from "../../../app/hooks/useAxios";
import {
  delete_product_count,
  delete_product_fail,
  loaded_products,
  removeProduct,
  set_is_loading,
  single_product,
} from "../../products/actions/productActions";

/**
 * Retrieves the loaded products from the server for a specific store.
 *
 * @param {string} store_id - The ID of the store to retrieve the products for.
 * @return {Promise} A promise that resolves with the loaded products.
 */
export const get_loaded_products = (store_id) => {
  return async (dispatch) => {
    const axios = useAxios();
    dispatch(set_is_loading(true));
    const response = await axios.get("/products/all?store_id=" + store_id);
    const { data, status } = await response.data;

    // if user unauthorize
    if (status === 400) {
      localStorage.clear();
      window.location.href = "/";
      return;
    }

    dispatch(loaded_products(data));
    dispatch(set_is_loading(false));
  };
};

/**
 * Retrieves a single product from the server.
 *
 * @param {number} id - The ID of the product to retrieve.
 * @param {number} storeID - The ID of the store to retrieve the product from.
 * @return {Promise} A Promise that resolves to the retrieved product.
 */
export const get_single_product = (id, storeID) => {
  return async (dispatch) => {
    const axios = useAxios();
    const response = await axios.get(
      "/product?store_id=" + storeID + "&id=" + id
    );
    const { data, status } = await response.data;

    // if data and status false then navigate to products page
    if (data === false || status === false) {
      window.location.href = "/products?status=false&msg=invalid_product";
      return;
    }

    dispatch(single_product(data));
  };
};

/**
 * Deletes a single product.
 *
 * @param {string} id - The ID of the product to be deleted.
 * @param {string} storeID - The ID of the store where the product belongs.
 * @return {Promise} A promise that resolves when the product is deleted successfully.
 */
export const delete_single_product = (id, storeID) => {
  return async (dispatch) => {
    const axios = useAxios();
    const response = await axios.post(
      "/products/delete?store_id=" + storeID + "&id=" + id
    );
    const { status } = await response.data;
    if (status === true) {
      dispatch(removeProduct(id));
      dispatch(delete_product_count(id));
    } else {
      dispatch(delete_product_fail("Something went wrong"));
    }
  };
};

/**
 * Executes a bulk action to remove multiple products.
 *
 * @param {array} ids - The array of product IDs to be removed.
 * @return {Promise} A promise that resolves to the dispatched action.
 */
export const bulk_action_product_remove = (ids) => {
  return async (dispatch) => {
    const axios = useAxios();
    const response = await axios.post("/products/bulk_remove", { ids });
    const { data, status } = await response.data;
    if (status === 400) {
      localStorage.clear();
      window.location.href = "/";
      return;
    }
    dispatch(loaded_products(data));
  };
};
