import React, { useEffect, useState } from "react";
import { cn } from "../../../../util/cn";
import { useSelector } from "react-redux";

const discountType = [
    {
        id: 1,
        name: "Percentage",
    },
    {
        id: 2,
        name: "Fixed",
    },
];

export default function AddOrderDiscount({
    discountLoading,
    discounts,
    setDiscounts,
    handleChangeDiscount,
    handleSaveDiscount,
}) {

    const { orderProductItem } = useSelector((state) => state.orders);
    const [currentDiscountType, setCurrentDiscountType] = useState("percentage");
    const handleDiscountTypeChange = (name) => {
        setCurrentDiscountType(name);
        setDiscounts({ type: name.toLowerCase(), value: 0 });
    };

    useEffect(() => {
        setCurrentDiscountType(discounts?.type);
    }, [discounts]);

    return (
        <div className="mt-3">
            <div className="form-section">
                <div className="tab-heading pt-1 mb-0">
                    <h2>Discount</h2>
                </div>
            </div>
            <div>
                <div className="form-group select-full-width-responsive">
                    <div className="discount-type-section flex mb-5 flex-wrap">
                        {discountType?.map((item, index) => (
                            <div
                                key={index}
                                onClick={() => handleDiscountTypeChange(item?.name)}
                                className={cn(
                                    "shadow-sm",
                                    orderProductItem?.length < 1 && "disabled !text-primary-200",
                                    index === 0 && "rounded-l-md",
                                    index !== discountType.length - 1 && "border-r",
                                    index === discountType.length - 1 && "rounded-r-md",
                                    "flex items-center cursor-pointer text-sm font-medium text-center py-1 px-4",
                                    item?.name?.toLowerCase() ===
                                        currentDiscountType?.toLowerCase()
                                        ? "bg-primary-900 text-white"
                                        : "bg-primary-100 text-primary-200"
                                )}
                            >
                                <button type="button">{item.name}</button>
                            </div>
                        ))}
                        <div className="flex items-center mt-3 lg:mt-0">
                            <div className="relative w-full">
                                <input
                                    className={`${orderProductItem?.length < 1 && "disabled"} lg:ml-2 ml-0 px-4 py-1 border rounded-md focus:ring-primary-500 focus:border-primary-500`}
                                    type="number"
                                    onChange={(e) => handleChangeDiscount(e)}
                                    value={discounts?.value}
                                    name="discount"
                                    placeholder="Enter discount"
                                />
                                {
                                    discounts?.type === "fixed" ? <div className={`${orderProductItem?.length < 1 && "disabled !text-black"} absolute inset-y-0 right-0 flex items-center pr-3s bg-primary-500 rounded-tr-md rounded-br-md px-3 text-white`}>
                                        <span className="rubik-400 text-sm"> ৳</span>
                                    </div> : <div className={`${orderProductItem?.length < 1 && "disabled !text-black"} absolute inset-y-0 right-0 flex items-center pr-3s bg-primary-500 rounded-tr-md rounded-br-md px-3 text-white`}>
                                        <span className="rubik-400 text-sm">%</span>
                                    </div>
                                }
                            </div>
                            {
                                discountLoading ? <>
                                    <button type="button" className='pt-1.5 pb-1.5 px-4 bg-primary-500 hover:bg-primary-900 text-white rounded-md ml-2 flex justify-center items-center gap-1'>
                                        <span className="saving-loader"></span>
                                        <span>Applying...</span>
                                    </button>
                                </> :
                                    <button
                                        className={`${(orderProductItem?.length < 1 || discounts?.value < 0 || discounts?.value === "") && "disabled"} pt-1.5 pb-1.5 px-4 bg-primary-500 hover:bg-primary-900 text-white rounded-md ml-2`}
                                        onClick={handleSaveDiscount}
                                    >
                                        Apply
                                    </button>
                            }
                        </div>
                    </div>
                    <div className="form-group"></div>
                </div>
            </div>
        </div>
    );
}
