import React from 'react'

export default function CrossMarkIcon() {
    return (
        <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 22.5599C17.8321 22.5599 22.56 17.8321 22.56 11.9999C22.56 6.16781 17.8321 1.43994 12 1.43994C6.16788 1.43994 1.44 6.16781 1.44 11.9999C1.44 17.8321 6.16788 22.5599 12 22.5599Z" fill="#EB3B5A"></path>
            <path d="M15.6224 8.16699L8.4274 16.3088" stroke="white" strokeWidth="1.5" strokeLinecap="round"></path>
            <path d="M8.37323 8.41309L16.103 16.0489" stroke="white" strokeWidth="1.5" strokeLinecap="round"></path>
        </svg>
    )
}
