import React, { useEffect, useState } from 'react'
import { useAxios, UseHelmet } from '../../../hooks'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux';

export default function SubscriberDetails() {
	const { id } = useParams();
	const axios = useAxios();
	const navigate = useNavigate();
	const reseller_id = useSelector(state => state.userInfo.user.id);
	const [subscriber, setSubscriber] = useState([]);
	const [loading, setLoading] = useState(false);

	// if find reseller by id then store to state otherwise navigate back
	useEffect(() => {
		const getSubscribers = async () => {
			setLoading(true)
			const res = await axios.post("/resellers/subscriber", { reseller_id, id });
			const { data } = await res.data;
			setSubscriber(data)
			setLoading(false)
			if (data.length === 0) {
				setLoading(false)
				navigate("/reseller/subscribers");
			}
		}
		getSubscribers();
	}, [id])


	return (
		<div>
			<main className="flex-1 z-0 overflow-y-auto bg-backgroundGray p-8">
				<UseHelmet title={`Subscriber #${id}`} />
				<div className="max-w-7xl mx-auto">
					<div className="section-heading borderless ">
						{/* section hading back button */}
						<div className="flex">
							<Link
								className="back-link btn btn-white btn-xs"
								data-controller="link"
								to="/reseller/subscribers"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									role="img"
									className="icon h-5 w-5"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M11 17l-5-5m0 0l5-5m-5 5h12"
									></path>
								</svg>
							</Link>
							{/* order number */}
							<div className="title">
								<h2>
									<span className="text-2xl">
										#{id} <span className="mx-10"></span>
									</span>
								</h2>
							</div>
						</div>
					</div>
					<div className="sm-relative">
						<div className="table-wrapper relative">
							<div className="overflow-hidden border border-gray-100 bg-gray-50 p-1 py-2 btw-0">
								<div className="px-6 my-8">
									<div className='mb-5'>
										<div className="detail-list-wrapper ">
											<dl className="detail-list">

												<div className="detail ">
													<dt className="detail-label lg:mb-0 mb-4">
														Name
													</dt>
													<dd className="details-content">
														{
															loading ? <div className='loader-line'></div> :
																subscriber?.full_name
														}
													</dd>
												</div>
												<div className="detail ">
													<dt className="detail-label lg:mb-0 mb-4">
														Mobile
													</dt>
													<dd className="details-content">
														{
															loading ? <div className='loader-line'></div> :
																subscriber?.mobile
														}
													</dd>
												</div>
												<div className="detail ">
													<dt className="detail-label lg:mb-0 mb-4">
														Status
													</dt>
													<dd className="details-content">
														{
															loading ? <div className='loader-line'></div> :
																<span className={`${subscriber?.status === "active" ? "text-primary-400" : "text-red-400"}`}>	{subscriber?.status?.toUpperCase()}</span>
														}
													</dd>
												</div>
												<div className="detail ">
													<dt className="detail-label lg:mb-0 mb-4">
														Stores
													</dt>
													<dd className="details-content">

														{
															loading ? <div className='loader-line'></div> :
																subscriber?.stores?.length > 0 ? subscriber?.stores?.map(store => store?.name).join(', ') : 'N/A'
														}
													</dd>
												</div>
											</dl>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</div>
	)
}
