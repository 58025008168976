import {
	SET_IS_ADD_ORDER_PRODUCT,
	SET_IS_MOBILE_MENU_OPEN,
	SET_IS_MODAL_CLOSE,
	SET_IS_MODAL_OPEN,
	SET_IS_NEW_CUSTOMER_MODAL,
	SET_IS_NEW_PRODUCT_MODAL,
	SET_IS_ORDER_MODAL,
	SET_IS_SUBSCRIPTION_MODAL,
} from "./actions";

const initialValue = {
	isModalOpen: false,
	isMobileMenuOpen: false,
	isNewProductModalOpen: false,
	isOrderModalOpen: false,
	isNewCustomerModalOpen: false,
	isAddOrderProductModalOpen: false,
	isSubscriptionModalOpen: false,
};

export const globalReducer = (state = initialValue, action) => {
	switch (action.type) {
		case SET_IS_MODAL_OPEN:
			return {
				...state,
				isModalOpen: true,
			};
		case SET_IS_MODAL_CLOSE:
			return {
				...state,
				isModalOpen: false,
			};
		case SET_IS_MOBILE_MENU_OPEN:
			return {
				...state,
				isMobileMenuOpen: action.payload,
			};
		case SET_IS_NEW_PRODUCT_MODAL:
			return {
				...state,
				isNewProductModalOpen: action.payload,
			};
		case SET_IS_ORDER_MODAL:
			return {
				...state,
				isOrderModalOpen: action.payload,
			};
		case SET_IS_ADD_ORDER_PRODUCT:
			return {
				...state,
				isAddOrderProductModalOpen: action.payload,
			};
		case SET_IS_NEW_CUSTOMER_MODAL:
			return {
				...state,
				isNewCustomerModalOpen: action.payload,
			};
		case SET_IS_SUBSCRIPTION_MODAL:
			return {
				...state,
				isSubscriptionModalOpen: action.payload,
			};
		default:
			return state;
	}
};
