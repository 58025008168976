import React, { createContext, useState } from 'react';
import { AuthProvider } from './contexts/AuthContext'
import { useRoutes } from 'react-router-dom';
import routes from './routes'
import LoadingBar from 'react-top-loading-bar';
import { Provider } from 'react-redux';
import store from '../redux/store';
import { Toaster } from 'react-hot-toast';

export const parentContext = createContext();

export default function App() {
    const content = useRoutes(routes);
    const [modalIndex, setModalIndex] = useState(false);
    const [progress, setProgress] = useState(0);
    // const [environment, setEnvironment] = useState("prod");

    return (
        <AuthProvider>
            <Toaster position="top-center" />
            <Provider store={store}>
                <parentContext.Provider value={{ modalIndex, setModalIndex, setProgress }}>
                    <LoadingBar
                        color='#098d56'
                        hight='4'
                        progress={progress}
                        onLoaderFinished={() => setProgress(0)}
                    />
                    {content}
                </parentContext.Provider>
            </Provider>
        </AuthProvider>
    )
}
