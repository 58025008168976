import React from 'react'

export default function PreLoaders() {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-5 z-50">
            <div className="flex items-center justify-center">
                <div className="border-2 border-b-[#b5b5b5c4] border-primary-300 w-10 h-10 rounded-full animate-spin"></div>
            </div>
        </div>
    )
}
