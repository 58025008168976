import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import useValidation from '../../../../util/validation/useValidation';
import useCustomerFunc from '../../../../util/customers/useCustomerFunc';
import { useDispatch, useSelector } from 'react-redux';
import { parentContext } from '../../../App';
import { set_is_new_customer_modal } from '../../../../redux/globals/actions';
import LoadingButton from '../../Buttons/LoadingButton';
export default function AddCustomerModal() {

	const { setModalIndex } = useContext(parentContext);
	const dispatch = useDispatch();
	const { isNewCustomerModalOpen } = useSelector(state => state.global);
	const { register, handleSubmit } = useForm();
	const { handlePhoneNumber: validate } = useValidation();
	const { onCustomerSubmit } = useCustomerFunc()
	const activeStore = localStorage.getItem("activeStore");
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		setModalIndex(isNewCustomerModalOpen);
		// eslint-disable-next-line
	}, [isNewCustomerModalOpen]);


	// After clicking the ESC button the modal will be closed
	useEffect(() => {
		const handleEsc = (event) => {
			if (event.key === 'Escape') {
				dispatch(set_is_new_customer_modal(false));
			}
		};
		document.addEventListener('keydown', handleEsc);
		return () => {
			document.removeEventListener('keydown', handleEsc);
		};
	}, [dispatch]);



	const onSubmit = async (data) => {
		await onCustomerSubmit(data, activeStore, setError, setLoading);
	}

	return (
		<div className={`modal-wrapper  ${isNewCustomerModalOpen ? 'pr20 animate-fade-in' : "!hidden"}`} data-controller="modal" aria-modal="true">
			<div className="modal-inner modal-align-responsive">
				<div className="modal-overlay" aria-hidden="true" data-action="click->modal#close"></div>
				<span className="modal-center-trick" aria-hidden="true">​</span>
				<turbo-frame data-modal-target="turboFrame" id="modal" target="_top" src="https://app.dokaani.com" complete="">
					<div className="modal w-responsive">
						<button className="close" type="button" onClick={() => dispatch(set_is_new_customer_modal(false))} data-action="click->modal#close">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" role="img" className="icon h-6 w-6">
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
							</svg>
						</button>

						<div className="modal-header border-b-1">
							<h3>Add Customer</h3>
						</div>

						<div className="modal-body">
							<form onSubmit={handleSubmit(onSubmit)}>
								<div className="form-group">
									<label htmlFor="phone">Phone Number</label>
									<input autoComplete='off' placeholder="Customer phone number" onKeyDown={validate} {...register("phone", { required: true, maxLength: 11 })} maxLength={11} className="form-control" type="number" name="phone" id="phone" />
									{error && <span className='form-error'>{error}</span>}
								</div>

								<div className="modal-f mt-7">
									<div className="secondary-action">
										{
											loading ? <LoadingButton title={"Creating"} />
												:
												<button type="submit" className='btn btn-primary btn-md'>Create Customer</button>
										}
									</div>
								</div>
							</form>
						</div>

					</div>
				</turbo-frame>
			</div>
		</div>
	)
}
