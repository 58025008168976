import useAxios from '../../app/hooks/useAxios';
import useAuth from '../../app/contexts/useAuth';
import { useNavigate } from "react-router-dom";
import { removeProduct, searchProduct } from '../../redux/products/actions/productActions';
import { useDispatch } from "react-redux";
import toast from 'react-hot-toast';
import { set_is_new_product_modal } from '../../redux/globals/actions';

export default function useProductFunc() {
    const axios = useAxios();
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { logout } = useAuth();

    // if product create successfully then show success toast  
    const saveProduct = async (formFieldData, activeStore, setLoading) => {
        setLoading(true);
        const addData = await axios.post("/products/add", { ...formFieldData, store_id: activeStore });
        const { code, message } = addData.data.data;
        const { status } = addData.data;

        // when response is invalid then logout
        if (message === 'Invalid store.') {
            window.location.reload();
            logout();
        }

        if (code === "invalid_authorization") {
            window.location.reload();
            logout();
        }

        // if response status is true then show success toast
        if (status === true) {
            await setLoading(false);
            dispatch(set_is_new_product_modal(false));
            navigate(`/product/${addData.data.data?.id}`);
        }
        // if response status is false then show error toast
        if (status === false) {
            toast.error('You Cannot create more product!');
            setLoading(false);
        }
    }

    /**
    * search product by name
   */
    const productSearchValue = (e, products) => {
        const keyword = e.target.value;

        if (keyword !== '') {
            const results = products.filter((p) => {
                return p.name.toLowerCase().includes(keyword.toLowerCase());
            });

            if (results.length > 0) {
                dispatch(searchProduct(results));
            }

            if (results.length < 1) {
                dispatch(searchProduct(false));
            }

        } else if (keyword === '') {
            // If the text field is empty, show all products
            dispatch(searchProduct([]));
        } else {
            dispatch(searchProduct([]));
        }
    };

    /**
     * If deleteProduct state found or change then show the delete product message
     */
    const handleProductDelete = async (id, activeStore, setLoading, setSelectedList, setMasterCheck) => {
        setLoading(true);
        const multipleDelete = async (ids) => await axios.post("/products/delete?store_id=" + activeStore + "&id=" + ids);
        if (typeof id === 'object') {
            id.forEach(ids => {
                multipleDelete(ids);
                dispatch(removeProduct(ids))
            });
            setSelectedList([]);
            setMasterCheck(false);
            toast.success('Product deleted!')
            setLoading(false);
            return;
        } else {
            const deleteData = await axios.post("/products/delete?store_id=" + activeStore + "&id=" + id);
            if (deleteData.data.status === true) {
                dispatch(removeProduct(id));
                toast.success('Product deleted!')
                setSelectedList([]);
                setMasterCheck(false);
                setLoading(false);
            } else {
                toast.error('Something went wrong!')
                setLoading(false);
            }
        }

    }

    // update products state and master checkbox state
    const singleItemCheck = (e, item, paginatedData, setMasterCheck, setSelectedList) => {
        let tempList = paginatedData;
        tempList.map(p => {
            if (p.id === item.id) {
                p.selected = e.target.checked;
            }
            return p;
        })
        // to control master checkbox state
        const totalProduct = paginatedData.length;
        const totalCheckItems = tempList.filter(p => p.selected).length;
        // update state
        setMasterCheck(totalCheckItems === totalProduct);
        // setData(tempList);
        setSelectedList(paginatedData.filter(p => p.selected === true));
    }

    return { saveProduct, productSearchValue, handleProductDelete, singleItemCheck }
}
