import { BULK_ACTION, TOGGLE_MODAL } from "../actions/BulkAction";

const initialState = {
    bulkState: false,
    toggleModal: false
};
const bulkReducer = (state = initialState, action) => {
    switch (action.type) {
        case BULK_ACTION:
            return {
                ...state,
                bulkState: !state.bulkState
            }
        case TOGGLE_MODAL:
            return {
                ...state,
                toggleModal: !state.toggleModal
            }
        default:
            return state;
    }
}

export default bulkReducer;