import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAxios, validateNumber } from '../../../hooks';
import { getLocalStorage } from '../../../hooks/useLocalStorage';
import { set_is_order_modal } from '../../../../redux/globals/actions';
import { set_is_new_order, set_is_walk_in_customer } from '../../../../redux/orders/action';
import LoadingButton from '../../Buttons/LoadingButton';
import toast from 'react-hot-toast';

export default function AddOrderModal() {

	const axios = useAxios();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const storeID = getLocalStorage("activeStore");
	const { isWalkInCustomer } = useSelector((state) => state.orders);
	const { isOrderModalOpen } = useSelector(state => state.global);
	const { register, handleSubmit, formState: { errors } } = useForm();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [getFormData, setFormData] = useState([]);
	const [dataSubmit, setIsSubmit] = useState(false);

	const handlePhoneNumber = (event) => {
		const restrictedKeys = /Enter|Backspace|Tab|ArrowRight|ArrowLeft|Ctrl|c|a|x|v|Delete|Home|Tab|End/;
		const isKeyRestricted = restrictedKeys.test(event.key);

		if (event.target.value.length >= 11 && !isKeyRestricted) {
			event.preventDefault();
		}
	}

	const onSubmit = async (data) => {
		const mobile = data?.phone;
		if (!isWalkInCustomer) {
			const validatePhone = validateNumber(mobile);
			if (validatePhone) {
				setIsSubmit(true);
				setFormData({ mobile: mobile });
			} else {
				setError("Invalid phone number");
			}
		}
		setIsSubmit(true);
	}

	useEffect(() => {

		/**
		 * if user not exits then crate customer
		 * @param {int} storeID
		 * @param {int} mobile
		 * @returns
		 */
		const createCustomer = async () => {
			const response = await axios.post(`/customers/add`, { store_id: storeID, mobile: getFormData.mobile });
			const data = await response.data;

			if (data.status) {
				setLoading(false);
				createOrder();
			}

			if (data.status === false) {
				toast.error(data?.data?.message);
			}
			setLoading(false);
		}

		/**
		 * Create order function
		 */
		const createOrder = async () => {
			setLoading(true);
			const response = await axios.post(`/orders/add`, {
				store_id: storeID,
				mobile: getFormData.mobile,
				walk_in_customer: isWalkInCustomer
			});
			const data = await response.data;
			setIsSubmit(false);

			if (data.status === false && data?.data?.is_customer === undefined) {
				toast.error(data?.data?.message);
				setLoading(false);
			}

			if (data.status === false && data?.data?.is_customer === false) {
				createCustomer();
				return;
			}

			if (data.status === true) {
				setLoading(false);
				dispatch(set_is_order_modal(false));
				dispatch(set_is_new_order(true));
				dispatch(set_is_walk_in_customer(false));
				navigate(`/order/${data?.data?.id}`);
			}

		}

		if (dataSubmit) {
			createOrder();
		}
		//  eslint-disable-next-line
	}, [dataSubmit])


	// After clicking the ESC button the modal will be closed
	useEffect(() => {
		const handleEsc = (event) => {
			if (event.key === 'Escape') {
				dispatch(set_is_order_modal(false));
			}
		};
		document.addEventListener('keydown', handleEsc);
		return () => {
			document.removeEventListener('keydown', handleEsc);
		};
	}, [dispatch]);

	return (
		<div className={`modal-wrapper  ${isOrderModalOpen ? 'pr20 animate-fade-in' : "!hidden"}`} data-controller="modal" aria-modal="true">
			<div className="modal-inner modal-align-responsive">
				<div className="modal-overlay" aria-hidden="true" data-action="click->modal#close"></div>
				<span className="modal-center-trick" aria-hidden="true">​</span>
				<turbo-frame data-modal-target="turboFrame" id="modal" target="_top" src="https://app.dokaani.com" complete="">
					<div className="modal w-responsive">
						<button className="close" type="button" onClick={() => dispatch(set_is_order_modal(false))} data-action="click->modal#close">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" role="img" className="icon h-6 w-6">
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
							</svg>
						</button>

						<div className="modal-header border-b-1">
							<h3>Add Order</h3>
						</div>

						<div className="modal-body">
							<form onSubmit={handleSubmit(onSubmit)}>
								<div className="form-group mb-3">
									<label htmlFor="phone">Customer Phone Number</label>
									<input autoComplete="off" disabled={isWalkInCustomer} onKeyDown={isWalkInCustomer ? null : handlePhoneNumber} placeholder="01XXXXXXXXX" {...register("phone", { required: !isWalkInCustomer, maxLength: 11 })} maxLength={11} className="form-control" type="number" name="phone" id="phone" />
									{errors.phone && <span className='form-error'>Enter a valid phone number.</span>}
									{error && <span className='form-error'>{error}</span>}
								</div>
								<div className="flex items-centers">
									<input autoComplete="off" type="checkbox" checked={isWalkInCustomer} onChange={() => dispatch(set_is_walk_in_customer(!isWalkInCustomer))} name="walk_in_customer" id="walk_in_customer" className='w-4 h-4 text-primary-900 bg-gray-100 rounded border-gray-300 focus:ring-primary-900' />
									<label htmlFor="walk_in_customer" className='ml-2 text-sm font-medium text-gray-900'>Walk in customer</label>
								</div>

								<div className="modal-f mt-6">
									<div className="secondary-action">
										{loading ? <LoadingButton title={"Creating"} /> : <button type="submit" className='btn btn-primary btn-md'>Create Order</button>}
									</div>
								</div>
							</form>
						</div>

					</div>
				</turbo-frame>
			</div>
		</div>
	)
}
