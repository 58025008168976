import React from 'react'
import { Helmet } from 'react-helmet'

export default function UseHelmet({ title }) {
    return (
        <Helmet>
            <title>{title + " - Dokaani"}</title>
        </Helmet>
    )
}
