import {
	ADDED_ADDRESS_ID,
	ADDED_ADDRESS_NAME,
	ADDED_ALL_PRODUCTS,
	ADDED_DISCOUNT_IN_ORDER,
	ADDED_DUE_AMOUNT,
	ADDED_FULL_ADDRESS,
	ADDED_ORDERED_TOTAL_AMOUNT,
	ADDED_ORDER_PRODUCT_ITEM,
	ADDED_ORDER_STATUS,
	ADDED_PAYMENTS,
	ADDED_PRODUCT_DETAILS,
	ADDED_TOTAL_PAYMENT,
	CHANGE_FILTERING_STATUS,
	IS_LOADING_ORDER,
	LOADED_ORDER,
	NEW_ORDER_ITEM,
	REMOVE_ORDER_ITEM,
	SEARCH_ORDERS,
	SET_ADDED_ORDER_PRODUCT_LOADING,
	SET_DELETE_ORDER_LOADING,
	SET_DELETE_ORDER_PAYMENT_LOADING,
	SET_DELETE_ORDER_PRODUCT_LOADING,
	SET_EDIT_ORDER_LOADING,
	SET_IS_NEW_ORDER,
	SET_IS_WALK_IN_CUSTOMER,
	SET_TABLE_OF_ORDERS,
	SINGLE_ORDERED,
} from "./actionType";
import { ordersInitialValues } from "./ordersInitialValues";

/**
 * Reducer function for handling order-related actions.
 *
 * @param {Object} state - The current state of the order reducer.
 * @param {Object} action - The action object containing the type and payload.
 * @return {Object} The updated state after applying the action.
 */
export const orderReducer = (state = ordersInitialValues, action) => {
	switch (action.type) {
		case CHANGE_FILTERING_STATUS:
			return {
				...state,
				showFilter: action.payload,
			};
		case SET_EDIT_ORDER_LOADING:
			return {
				...state,
				editOrderLoading: action.payload,
			};
		case SET_IS_NEW_ORDER:
			return {
				...state,
				isNewOrder: action.payload,
			};
		case SET_IS_WALK_IN_CUSTOMER:
			return {
				...state,
				isWalkInCustomer: action.payload,
			};
		case LOADED_ORDER:
			return {
				...state,
				allOrder: action.payload,
			};
		case IS_LOADING_ORDER:
			return {
				...state,
				isLoading: action.payload,
			};
		case REMOVE_ORDER_ITEM:
			return {
				...state,
				allOrder: state.allOrder.filter((p) => p.id !== action.payload),
			};
		case SET_TABLE_OF_ORDERS:
			return {
				...state,
				tableOfOrders: action.payload,
			};
		case SEARCH_ORDERS:
			return {
				...state,
				searchOrders: action.payload,
			};
		case SINGLE_ORDERED:
			return {
				...state,
				orderData: action.payload,
			};
		case ADDED_PRODUCT_DETAILS:
			return {
				...state,
				productDetails: action.payload,
			};
		case ADDED_ORDERED_TOTAL_AMOUNT:
			return {
				...state,
				totalOrderAmount: action.payload,
			};
		case ADDED_DISCOUNT_IN_ORDER:
			return {
				...state,
				discountInOrder: action.payload,
			};
		case ADDED_PAYMENTS:
			return {
				...state,
				orderedPayment: action.payload,
			};
		case ADDED_TOTAL_PAYMENT:
			return {
				...state,
				totalPaymentAmount: action.payload,
			};
		case ADDED_DUE_AMOUNT:
			return {
				...state,
				dueAmount: action.payload,
			};
		case ADDED_ADDRESS_NAME:
			return {
				...state,
				addressName: action.payload,
			};
		case ADDED_FULL_ADDRESS:
			return {
				...state,
				fullAddress: action.payload,
			};
		case ADDED_ADDRESS_ID:
			return {
				...state,
				addressID: action.payload,
			};
		case ADDED_ORDER_STATUS:
			return {
				...state,
				orderStatus: action.payload,
			};
		case ADDED_ALL_PRODUCTS:
			return {
				...state,
				allProducts: action.payload,
			};
		case ADDED_ORDER_PRODUCT_ITEM:
			return {
				...state,
				orderProductItem: action.payload,
			};
		case SET_ADDED_ORDER_PRODUCT_LOADING:
			return {
				...state,
				isAddOrderProductLoading: action.payload,
			};
		case SET_DELETE_ORDER_PRODUCT_LOADING:
			return {
				...state,
				isDeleteOrderProductLoading: action.payload,
			};
		case SET_DELETE_ORDER_PAYMENT_LOADING:
			return {
				...state,
				isDeleteOrderPaymentLoading: action.payload,
			};
		case NEW_ORDER_ITEM:
			return {
				...state,
				orderProductItem: [action.payload, ...state.orderProductItem],
			};
		case SET_DELETE_ORDER_LOADING:
			return {
				...state,
				deleteOrderLoading: action.payload,
			};
		default:
			return state;
	}
};
