import {
	ADDED_ADDRESS,
	ADDED_CUSTOMER,
	ADDED_HIGHLIGHT_ROW_ID,
	CUSTOMER_FORM_DATA,
	DELETE_ADDRESS_DATA,
	DELETE_CUSTOMER,
	GET_EDIT_ADDRESS,
	LOADED_CUSTOMER,
	SEARCH_CUSTOMER,
	SET_ADDRESS,
	SET_CUSTOMER_DELETE_LOADING,
	SET_LOADING,
	SET_TABLE_OF_CUSTOMER,
	UPDATE_CUSTOMER,
} from "../actionTypes/actionTypes";

/**
 * Load All Customers
 * @param {*} value
 * @returns object
 */
export const loaded_customers = (value) => {
	return {
		type: LOADED_CUSTOMER,
		payload: value,
	};
};

/**
 * Add new customer
 * @param {*} value customer data
 * @returns object
 */
export const added_customer = (value) => {
	return {
		type: ADDED_CUSTOMER,
		payload: value,
	};
};

/**
 * Add new address
 * @param {*} value Address data
 * @returns object
 */
export const added_address = (value) => {
	return {
		type: ADDED_ADDRESS,
		payload: value,
	};
};

export const set_highlighted_Row_ID = (id) => {
	return {
		type: ADDED_HIGHLIGHT_ROW_ID,
		payload: id,
	};
};

export const delete_address = (value) => {
	return {
		type: DELETE_ADDRESS_DATA,
		payload: value,
	};
};

/**
 * All customer addresses
 * @param {array} value customer address data
 * @returns object
 */
export const set_addresses = (value) => {
	return {
		type: SET_ADDRESS,
		payload: value,
	};
};

/**
 * Get edit customer Address data
 * @param {*} value customer data
 * @returns object
 */
export const get_edit_address = (value) => {
	return {
		type: GET_EDIT_ADDRESS,
		payload: value,
	};
};

/**
 * Update customer information
 * @param {*} value customer data
 * @returns object
 */

export const update_customer = (value) => {
	return {
		type: UPDATE_CUSTOMER,
		payload: value,
	};
};

/**
 * Delete customer data
 * @param {int} value customer id
 * @returns object
 */
export const delete_customer = (value) => {
	return {
		type: DELETE_CUSTOMER,
		payload: value,
	};
};

/**
 * Get new customer form data
 * @param {object} value new customer data
 * @returns Object
 */
export const customer_form_data = (value) => {
	return {
		type: CUSTOMER_FORM_DATA,
		payload: value,
	};
};

/**
 * Set table of customer
 * @param {array} value show customer by pagination
 * @returns Object
 */
export const set_table_of_customer = (value) => {
	return {
		type: SET_TABLE_OF_CUSTOMER,
		payload: value,
	};
};

/**
 * Search customer value
 * @param {array} item customer value
 * @returns Object
 */
export const search_customer_action = (item) => {
	return {
		type: SEARCH_CUSTOMER,
		payload: item,
	};
};

/**
 * Set loading or not
 * @param {Boolean} value loading value
 * @returns Object
 */
export const is_loading_customer = (value) => {
	return {
		type: SET_LOADING,
		payload: value,
	};
};

export const is_customer_delete_loading = (value) => {
	return {
		type: SET_CUSTOMER_DELETE_LOADING,
		payload: value,
	};
};
