export const ADDED_USER_TYPE = "user/ADDED_USER_TYPE";
export const ADDED_USER_INFO = "user/ADDED_USER_INFO";
export const ADDED_FULL_NAME = "user/ADDED_FULL_NAME";
export const ADDED_AVAILABLE_ORDER_STATUS = "user/ADDED_AVAILABLE_ORDER_STATUS";
export const ADDED_LOADING = "user/ADDED_LOADING";
export const ADDED_ERROR = "user/ADDED_ERROR";
export const ADDED_IS_AUTH = "user/ADDED_IS_AUTH";
export const ADDED_IS_STORE = "user/ADDED_IS_STORE";
export const ADDED_ALL_STORES = "user/ADDED_ALL_STORES";
export const ADDED_RESELLERS = "user/ADDED_RESELLERS";
