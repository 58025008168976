export const formatDates = (inputValue) => {
	/**
	 * Formats the input date value into a human-readable format.
	 *
	 * @param {string} inputValue - The input date value to be formatted in the format 'YYYY-MM-DD'.
	 * @return {string} The formatted date in the format 'DDth Month YYYY'.
	 */
	const parts = inputValue.split("-");
	const day = parseInt(parts[2]);
	const month = parseInt(parts[1]);
	const year = parseInt(parts[0]);

	const date = new Date(year, month - 1, day);

	const formattedDay = `${day}${getOrdinalSuffix(day)}`;
	const formattedMonth = date.toLocaleString("default", { month: "long" });
	const formattedYear = year;

	return `${formattedDay} ${formattedMonth} ${formattedYear}`;
};

/**
 * Returns the ordinal suffix for a given day.
 *
 * @param {number} day - The day for which to get the ordinal suffix.
 * @return {string} - The ordinal suffix for the given day.
 */
function getOrdinalSuffix(day) {
	if (day >= 11 && day <= 13) {
		return "th";
	}

	const lastDigit = day % 10;
	switch (lastDigit) {
		case 1:
			return "st";
		case 2:
			return "nd";
		case 3:
			return "rd";
		default:
			return "th";
	}
}
