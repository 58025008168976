import { validateNumber } from '../../app/hooks/validateNumber';
import useAxios from '../../app/hooks/useAxios';
import { set_is_new_customer_modal } from '../../redux/globals/actions';
import { set_is_new_customer } from '../../redux/customers/actions/customerEditActions';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';

export default function useCustomerFunc() {
    const axios = useAxios();
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const onCustomerSubmit = async (data, activeStore, setError, setLoading) => {
        setLoading(true);
        const mobile = data?.phone;
        const validatePhone = validateNumber(mobile);
        if (validatePhone) {
            const saveCustomer = await axios.post("/customers/add", { mobile, store_id: activeStore });
            const { status, data } = saveCustomer.data;
            // if limit exceeds customer then show error message
            if (status === false) {
                toast.error(saveCustomer.data.data.message);
            }

            if (status) {
                dispatch(set_is_new_customer_modal(false));
                navigate("/customer/" + data.id);
                dispatch(set_is_new_customer(true));
                setLoading(false);
            }
            setLoading(false);
        } else {
            setError("Invalid phone number");
        }
        setLoading(false);
    }

    return { onCustomerSubmit }
}
