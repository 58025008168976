import React, { useLayoutEffect } from 'react';
import { getLocalStorage } from '../hooks/useLocalStorage';
import { useDispatch } from 'react-redux';
import { get_loaded_products } from '../../redux/thunk/products/productThunk';
import { set_is_new_product_modal } from '../../redux/globals/actions';
import { UseHelmet } from '../hooks';
import { ModalPlusIcon } from '../helpers/icons/Icons';
import ProductsTable from '../components/products/Tables/ProductsTable';

export default function Products() {
    const dispatch = useDispatch();
    const activeStore = getLocalStorage("activeStore");

    // load the all product
    useLayoutEffect(() => {
        dispatch(get_loaded_products(activeStore));
    }, [dispatch, activeStore])

    return (
        <>
            <UseHelmet title="Products " />
            <main className={`flex-1 z-0 overflow-y-auto bg-backgroundGray p-8`}>
                <div className="max-w-7xl mx-auto">
                    <div className="section-heading pb-0  border-b-none flex items-center justify-center">
                        <div className="title">
                            <h2>
                                <span>All Products</span>
                            </h2>
                        </div>
                        <div className="actions">
                            <button type="button" onClick={() => dispatch(set_is_new_product_modal(true))} className="btn btn-transparent btn-sm" data-turbo-frame="modal" data-controller="link">
                                <ModalPlusIcon />
                                <span>New Product</span>
                            </button>
                        </div>
                    </div>
                    <ProductsTable />
                </div>
            </main>
        </>
    )
}
