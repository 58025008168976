import {
  DELETE_ORDER_HISTORY,
  DELETE_SOCIAL_LINK,
  GET_EDIT_CUSTOMER,
  SET_IS_NEW_CUSTOMER,
  SET_LOADING,
  SET_ORDER_HISTORY,
  SET_SOCIAL_LINK,
  SET_TAB_ACTIVATED,
} from "../actionTypes/customerEditActionTypes";

export const set_tab_activated = (value) => {
  return {
    type: SET_TAB_ACTIVATED,
    payload: value,
  };
};

export const set_loading = (value) => {
  return {
    type: SET_LOADING,
    payload: value,
  };
};

export const set_is_new_customer = (status) => {
  return {
    type: SET_IS_NEW_CUSTOMER,
    payload: status,
  };
};

export const edit_customer_action = (value) => {
  return {
    type: GET_EDIT_CUSTOMER,
    payload: value,
  };
};

export const set_social_link = (value) => {
  return {
    type: SET_SOCIAL_LINK,
    payload: value,
  };
};

export const delete_social_link = (value) => {
  return {
    type: DELETE_SOCIAL_LINK,
    payload: value,
  };
};

export const set_order_history = (value) => {
  return {
    type: SET_ORDER_HISTORY,
    payload: value,
  };
};

export const delete_order_history = (value) => {
  return {
    type: DELETE_ORDER_HISTORY,
    payload: value,
  };
};
