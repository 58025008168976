import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { bulkActionCreator } from '../../../../redux/actionCreators/bulkAction';
import NoDataTable from '../../../helpers/NoDataTable';
import { formatDate } from '../../../../util/formateDate';
import { change_filtering_status, remove_order_item, set_delete_order_loading } from '../../../../redux/orders/action';
import { delete_single_order } from '../../../../redux/thunk/orders/orderThunk';
import { DokaaniTablePagination, UsePagination, formatDates, useAxios } from '../../../hooks';
import toast from 'react-hot-toast';
import TableSkeleton from '../../../components/skeletonLoader/TableSkeleton';
import { PreLoaders } from '../../../helpers';
import Swal from 'sweetalert2';
import { getLocalStorage } from '../../../hooks/useLocalStorage';
import { parentContext } from '../../../App';

export default function OrdersTable() {

    const store_id = localStorage.getItem("activeStore");
    const axios = useAxios();
    const bulkReducer = useSelector((state) => state.bulkReducer);
    const ordersData = useSelector((state) => state.orders);
    const dispatch = useDispatch();
    const { bulkState } = bulkReducer;
    const { showFilter, allOrder, isLoading, deleteOrderLoading } = ordersData;
    const [orders, setOrders] = useState([]);
    const [stuff, setStuff] = useState([]);
    console.log(stuff)
    const [searchOrders, setSearchOrders] = useState([]);
    const [masterCheck, setMasterCheck] = useState(false);
    const [selectedList, setSelectedList] = useState([]);
    const [selectedId, setSelectedId] = useState([]);

    const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);
    const [deleteOrderId, setDeleteOrderId] = useState([]);
    const { setModalIndex } = useContext(parentContext);

    // get all orders
    useEffect(() => {
        setOrders(allOrder);
        // get all orders
    }, [allOrder])

    useEffect(() => {
        const stuffData = getLocalStorage("storeInfo");
        setStuff(stuffData.store_stuff || [])
    }, [])

    // select / unselect table row
    useEffect(() => {
        if (selectedList.length > 0) {
            const getAllID = selectedList.map(p => p.id);
            setSelectedId(getAllID);
        }
        //get the all of selectedList id
    }, [selectedList])

    //pagination
    const { currentPage, totalPages, paginatedData, handlePageChange } = UsePagination(allOrder, 10);

    /**
     * Updates the selected status of all orders and sets the master check state.
     * @param {Event} e - The event object that triggered the function.
     */
    const onMasterCheck = (e) => {
        const updatedOrders = [...paginatedData];
        updatedOrders.forEach(order => order.selected = e.target.checked);
        setMasterCheck(e.target.checked);
        setOrders(paginatedData);
        setSelectedList(updatedOrders.filter(order => order.selected));
    }

    /**
    * Updates the orders state and selected list checkbox state.
    * @param {Event} e - The event object
    */
    const oneItemChange = (e, item) => {
        let tempList = paginatedData;
        tempList.map(p => {
            if (p.id === item.id) {
                p.selected = e.target.checked;
            }
            return p;
        })
        // to control master checkbox state
        const totalProduct = paginatedData.length;
        const totalCheckItems = tempList.filter(p => p.selected).length;
        // update state
        setMasterCheck(totalCheckItems === totalProduct);
        setOrders(tempList);
        setSelectedList(paginatedData.filter(p => p.selected));
    }

    /**
     * Handles the filter date event.
     * @param {Event} e - the event object containing the filter date value.
     */
    const handleFilterDate = (e) => {
        const formattedDate = formatDates(e.target.value);
        if (formattedDate) {
            const filteredOrders = orders.filter(p => formatDate(p.added_on) === formattedDate);
            if (filteredOrders.length > 0) {
                setSearchOrders(filteredOrders);
            } else {
                setSearchOrders(false);
            }

        }
    }

    // TODO : filtering by date order data status
    const handleFilterStatus = e => {
        if (e.target.value === '') {
            setSearchOrders(orders);
            return;
        }
        const filteringOrder = orders.filter(p => p.order_status.toLowerCase() === e.target.value);
        if (filteringOrder.length > 0) {
            setSearchOrders(filteringOrder);
        } else {
            setSearchOrders(false);
        }
    }

    /**
     * Resets the filter and sets the search orders to the original orders.
     *
     * @return {void} 
     */
    const handleResetFilter = () => {
        setSearchOrders(orders);
    }

    /**
     * Search for a value in the orders array based on a keyword.
     *
     * @param {Object} e - The event object.
     * @return {void} This function does not return a value.
     */
    const searchValue = (e) => {
        const keyword = e.target.value;

        if (keyword !== '') {
            const results = allOrder.filter((o) => {
                return o.customer.mobile.includes(keyword);
            });

            if (results.length > 0) {
                setSearchOrders(results);
            }

            if (results.length < 1) {
                setSearchOrders(false);
            }

        } else if (keyword === '') {
            setSearchOrders([]);
        } else {
            // If the text field is empty, show all users
            setSearchOrders([]);
        }
    };

    /**
    * Deletes an order with the given ID from the list of orders.
    *
    * @param {number} deleteId - The ID of the order to delete.
    */
    const handleDeleteOrder = async (deleteId) => {
        dispatch(set_delete_order_loading(true));
        const multipleDelete = async (orderId, storeIds) => await axios.post("/orders/delete", { order_id: orderId, store_id: storeIds });

        // if deleteId is array then multiple delete else single delete
        if (Array.isArray(deleteId)) {
            deleteId.forEach((id) => {
                multipleDelete(id, store_id);
                dispatch(remove_order_item(id));
            });
            dispatch(set_delete_order_loading(false));
        } else {
            await dispatch(delete_single_order(deleteId, store_id));
            dispatch(set_delete_order_loading(false));
        }
        // update state
        setSelectedList([]);
        setMasterCheck(false);
        toast.success("Order deleted.")
    };

    // const handleDeleteConfirmation = (id) => {
    //     Swal.fire({
    //         title: 'Are you sure?',
    //         text: "Want to delete this order!",
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonColor: '#3085d6',
    //         cancelButtonColor: '#d33',
    //         confirmButtonText: 'Yes, delete it!',
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             handleDeleteOrder(id);
    //         }
    //     });
    // };

    const handleProductDeleteConfirmation = (id) => {
        setIsDeleteConfirmationModalOpen(true);
        setDeleteOrderId(id);
    };

    const handleDeleteingOders = () => {
        if (deleteOrderId) {
            handleDeleteOrder(deleteOrderId);
            setIsDeleteConfirmationModalOpen(false);
        }
    };
    // isDeleteConfirmationModalOpen for stop the sidebar 
    useEffect(() => {
        setModalIndex(isDeleteConfirmationModalOpen);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDeleteConfirmationModalOpen])


    /**
     * Extra function 
     * Handles the delete bulk action.
     **/
    const handleDeleteBulkAction = () => window.confirm(selectedList.length > 0 ? "Are you sure delete this order?" : "Please select at least one order.") && (selectedList.length > 0 && handleDeleteOrder(selectedId));
    const handleShowFilterAction = () => dispatch(change_filtering_status(!showFilter));

    return (
        <div>
            <form className="sm:flex block item-center flex-between gap-4">
                {/* filtering and bulk action area */}
                <div className="flex gap-3">
                    {
                        /* filtering area */
                        orders.length > 0 && <div className="form-group relative text-left max-w-[120px]">
                            <button onClick={handleShowFilterAction} type="button" className="inline-flex w-[115px] items-center justify-between rounded-md border border-gray-300 bg-white px-4 py75 text-sm font-semibold text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-0 focus:ring-offset-0">
                                <span className="btn-texts">Filter</span>
                                <svg
                                    fill="currentColor"
                                    viewBox="0 0 16 16"
                                    height="1.2rem"
                                    width="1.5rem">
                                    <path d="M6 10.5a.5.5 0 01.5-.5h3a.5.5 0 010 1h-3a.5.5 0 01-.5-.5zm-2-3a.5.5 0 01.5-.5h7a.5.5 0 010 1h-7a.5.5 0 01-.5-.5zm-2-3a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5z" />
                                </svg>

                            </button>

                            <div className={`${showFilter ? 'opacity-100' : 'opacity-0 pointer-events-none'} transition-opacity duration-300 absolute left-0 z-10 font-medium p-4 mt-2-i md:w-[480px] w-[430px] border shadow-custom border-gray-300 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-opacity-5 focus:outline-none focus:ring-primary-300 focus:border-primary-400`}>
                                <div className="grid grid-cols-2 lg:grid-cols-3">
                                    <input
                                        type="date"
                                        onChange={e => handleFilterDate(e)}
                                        name="date"
                                        id='date'
                                        placeholder='Select a Date'
                                        className="my-3 py-2 w-40 border border-gray-300 rounded-md"
                                    />

                                    <select name="status" defaultValue={''} onChange={e => handleFilterStatus(e)} id="status" className='my-3 py-2 w-40 border border-gray-300 rounded-md focus:outline-none focus:ring-primary-300 focus:border-primary-400'>
                                        <option value="" selected>Status</option>
                                        <option value="pending">Pending</option>
                                        <option value="processing">Processing</option>
                                        <option value="shipped">Shipped</option>
                                        <option value="delivered">Delivered</option>
                                    </select>

                                    <select name="stuff" defaultValue={''} onChange={e => handleFilterStatus(e)} id="stuff" className='my-3 py-2 w-40 border border-gray-300 rounded-md focus:outline-none focus:ring-primary-300 focus:border-primary-400'>
                                        <option value="" selected>Stuff</option>

                                        {
                                            stuff && stuff.map((item, index) => <option key={index} value={item?.stuff_name}>{item?.stuff_name}</option>)
                                        }
                                    </select>
                                </div>
                                <button
                                    onClick={handleResetFilter}
                                    type='reset'
                                    className="px-4 py-2 text-sm font-medium text-white bg-gray-500 rounded-md focus:outline-none focus:bg-gray-600"
                                >
                                    Reset
                                </button>
                            </div>
                        </div>
                    }

                    {
                        /* bulk action button area */
                        (selectedList.length || masterCheck.length) > 0 &&
                        <div className="form-group relative text-left max-w-[120px]">
                            <button onClick={() => { !bulkState && dispatch(bulkActionCreator(true)); bulkState && dispatch(bulkActionCreator(bulkState)) }} type="button" className="inline-flex w-[115px] items-center justify-center rounded-md border border-gray-300 bg-white px-4 py75 text-sm font-semibold text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-0 focus:ring-offset-0"> Bulk Actions <svg data-v-c5e129fe="" className="-mr-1 ml-2" width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path data-v-c5e129fe="" d="M8.23332 0.741797L4.99998 3.97513L1.76665 0.741797C1.44165 0.416797 0.91665 0.416797 0.59165 0.741797C0.26665 1.0668 0.26665 1.5918 0.59165 1.9168L4.41665 5.7418C4.74165 6.0668 5.26665 6.0668 5.59165 5.7418L9.41665 1.9168C9.74165 1.5918 9.74165 1.0668 9.41665 0.741797C9.09165 0.42513 8.55832 0.416797 8.23332 0.741797Z" fill="#9CA3AF"></path></svg></button>
                            <div className={`${bulkState ? 'opacity-100' : 'opacity-0 pointer-events-none'} transition-opacity duration-300 absolute left-0 z-10 mt-2-i w-56 border shadow-lg border-gray-300 origin-top-right rounded-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}>
                                <div className="py-1 hover-children font-medium">
                                    <li className="flex items-center px-4 py-2 text-sxm text-red-800" onClick={handleDeleteBulkAction}>
                                        <svg
                                            viewBox="0 0 1024 1024"
                                            fill="currentColor"
                                            height="1.3rem"
                                            width="1.3rem"
                                            className='mr-3 text-red'>
                                            <path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z" />
                                        </svg>
                                        Delete
                                    </li>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                {/* search input field */}
                <div className="form-group w-full sm:w-auto">
                    <input type="text" name="search" id="search" onChange={e => searchValue(e)} className="form-control" placeholder="Search" />
                </div>
            </form>

            {/* orders table area */}
            <div data-controller="table" onClick={() => { showFilter === true && dispatch(change_filtering_status(false)) }}>
                <div className="table-wrapper" data-table-target="tableWrapper">
                    <div className="table">
                        <div className="table-header with-chevron-padding">
                            <div className="table-row">
                                <div className="table-cell w-xsm">
                                    <label className="inline-flex items-center">
                                        <input onChange={e => onMasterCheck(e)} checked={masterCheck} type="checkbox" className="form-checkbox rounded text-primary-300 h-5 w-5" />
                                        <span className="ml-2 text-gray-700">Checkbox Label</span>
                                    </label>
                                </div>
                                <div className="table-cell flex-1 sm:w-[8rem]">Phone</div>
                                <div className="table-cell w-32 md:flex-1">Amount</div>
                                <div className="table-cell flex-1 hidden md:block">Status</div>
                                <div className="table-cell flex-1 hidden md:block">Date</div>
                                <div className="table-cell w-32">Actions</div>
                            </div>
                        </div>
                        {deleteOrderLoading ? <PreLoaders /> : ""}
                        <TableSkeleton isLoading={isLoading}>
                            {
                                // show table body data
                                searchOrders && (searchOrders.length > 0 ? searchOrders : paginatedData)?.map((order, index) => <div key={index} className="table-body">
                                    <div className="table-row with-chevron" data-turbo-frame="modal">
                                        <div className="table-cell w-xsm">
                                            <input type="checkbox" value={order?.id || ''} onChange={e => oneItemChange(e, order)} checked={order?.selected || false} id={String(order?.id)} className="form-checkbox h-5 w-5 rounded text-primary-300" />
                                        </div>
                                        <Link to={`/order/${order?.id} `} className="table-cell flex-1 sm:w-[8rem]">
                                            {order?.customer?.mobile}
                                        </Link>

                                        <Link to={`/order/${order?.id} `} className="table-cell w-32 md:flex-1 rubik-400    ">
                                            ৳ {order?.ordered_total}
                                        </Link>

                                        <Link to={`/order/${order?.id} `} className="table-cell flex-1 capitalize hidden md:block">
                                            {order?.order_status}
                                        </Link>
                                        <Link to={`/order/${order?.id} `} className="table-cell flex-1 hidden md:block">
                                            {formatDate(order?.added_on)}
                                        </Link>
                                        <div className="table-cell w-32">
                                            <button className="text-red-500 hover:bg-text-600 font-normal px-1.5 pb-[0.1rem] rounded-[4px]  text-[13px]" onClick={() => handleProductDeleteConfirmation(order?.id)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                )
                            }
                        </TableSkeleton>
                    </div>
                    {
                        // when no data found then show no data found
                        !isLoading && (allOrder.length < 1 || !searchOrders) && <NoDataTable />
                    }
                </div>

                {
                    // show pagination
                    (allOrder.length > 10 || searchOrders?.length > 10) && <DokaaniTablePagination currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />
                }
                {/* Delete Confirmation Modal */}
                {isDeleteConfirmationModalOpen && (
                    <div className={`modal-wrapper  ${isDeleteConfirmationModalOpen ? 'pr20 animate-fade-in' : "!hidden"}`} data-controller="modal" aria-modal="true">
                        <div className="modal-inner modal-align-responsive">
                            <div className="modal-overlay" aria-hidden="true" data-action="click->modal#close"></div>
                            <span className="modal-center-trick" aria-hidden="true">​</span>
                            <turbo-frame data-modal-target="turboFrame" id="modal" target="_top" src="https://app.dokaani.com" complete="">
                                <div className="modal w-responsive">
                                    <div className="bg-white p-6 rounded shadow-lg">
                                        <h2 className="text-xl font-semibold mb-4">Are you sure?</h2>
                                        <p className="mb-6">Want to delete this Order!</p>
                                        <div className="flex justify-end">
                                            <button
                                                className="bg-gray-200 text-gray-800 px-4 py-2 rounded mr-2"
                                                onClick={() => setIsDeleteConfirmationModalOpen(false)}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                className="bg-red-500 text-white px-4 py-2 rounded"
                                                onClick={handleDeleteingOders}
                                            >
                                                Yes, delete it!
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </turbo-frame>
                        </div>
                    </div>
                )}

            </div>
        </div>
    )
}
