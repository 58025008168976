import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { bulkActionCreator } from '../../../../redux/actionCreators/bulkAction';
import NoDataTable from '../../../helpers/NoDataTable';
import { getLocalStorage } from '../../../hooks/useLocalStorage';
import PreLoaders from '../../../helpers/PreLoaders';
import { DokaaniTablePagination, UsePagination } from '../../../hooks';
import useProductFunc from '../../../../util/products/useProductFunc';
import TableSkeleton from '../../../components/skeletonLoader/TableSkeleton';
import Swal from 'sweetalert2';
import { parentContext } from '../../../App';

export default function ProductsTable() {
    const { productSearchValue, handleProductDelete, singleItemCheck } = useProductFunc();
    const activeStore = getLocalStorage("activeStore");
    const dispatch = useDispatch();
    const bulkReducer = useSelector(state => state.bulkReducer);
    const product = useSelector(state => state.product);
    const { bulkState } = bulkReducer;
    const { isLoading, products, searchProducts, ifSearchFalse } = product;

    const [loading, setLoading] = useState(false);
    const [masterCheck, setMasterCheck] = useState(false);
    const [selectedList, setSelectedList] = useState([]);
    const [selectedId, setSelectedId] = useState([]);
    const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);
    const [deleteProductId, setDeleteProductId] = useState(null);

    const { currentPage, totalPages, paginatedData, handlePageChange } = UsePagination(products, 10);

    const { setModalIndex } = useContext(parentContext);

    useEffect(() => {
        if (selectedList.length > 0) {
            const getAllID = selectedList.map(p => p.id);
            setSelectedId(getAllID);
        }
    }, [selectedList]);

    const onMasterCheck = (e) => {
        let tempList = paginatedData;
        tempList.map(p => (p.selected = e.target.checked));

        setMasterCheck(e.target.checked);
        setSelectedList(paginatedData.filter(p => p.selected));
    };

    const calculateTotalStock = (product) => {
        let totalStock = 0;

        if (product.type === "variable") {
            for (const variation of product.variations) {
                totalStock += variation.stock;
            }
        }

        return totalStock;
    };



    const handleProductDeleteConfirmation = (id) => {
        setIsDeleteConfirmationModalOpen(true);
        setDeleteProductId(id);
    };

    const handleDeleteProduct = () => {
        if (deleteProductId) {
            handleProductDelete(deleteProductId, activeStore, setLoading, setSelectedList, setMasterCheck);
            setIsDeleteConfirmationModalOpen(false);
        }
    };

    // isDeleteConfirmationModalOpen for stop the sidebar 
    useEffect(() => {
        setModalIndex(isDeleteConfirmationModalOpen);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDeleteConfirmationModalOpen])

    return (
        <div>
            <form className="flex item-center lg:justify-between sm:justify-start gap-4">
                <div className="form-group relative inline-block text-left">
                    <button onClick={() => { !bulkState && dispatch(bulkActionCreator(true)); bulkState && dispatch(bulkActionCreator(bulkState)) }} type="button" className="inline-flex w-[115px] items-center justify-center rounded-md border border-gray-300 bg-white px-4 py75 text-sm font-semibold text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-0 focus:ring-offset-0"> Bulk Actions <svg data-v-c5e129fe="" className="-mr-1 ml-2" width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path data-v-c5e129fe="" d="M8.23332 0.741797L4.99998 3.97513L1.76665 0.741797C1.44165 0.416797 0.91665 0.416797 0.59165 0.741797C0.26665 1.0668 0.26665 1.5918 0.59165 1.9168L4.41665 5.7418C4.74165 6.0668 5.26665 6.0668 5.59165 5.7418L9.41665 1.9168C9.74165 1.5918 9.74165 1.0668 9.41665 0.741797C9.09165 0.42513 8.55832 0.416797 8.23332 0.741797Z" fill="#9CA3AF"></path></svg></button>

                    <div className={`${bulkState ? 'opacity-100' : 'opacity-0 pointer-events-none'} transition-opacity duration-300 absolute left-0 z-10 mt-2-i w-56 border shadow-custom shadow-gray-300 border-gray-300 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}>
                        <div className="py-1 hover-children font-medium">
                            <li onClick={() => { window.confirm(selectedList.length > 0 ? "Are you sure delete this product?" : "Please select at least one product.") && (selectedList.length > 0 && handleProductDelete(selectedId, activeStore, setLoading, setSelectedList, setMasterCheck)) }} className="flex items-center px-4 py-2 text-sxm text-red-800">  <svg
                                viewBox="0 0 1024 1024"
                                fill="currentColor"
                                height="1.3rem"
                                width="1.3rem"
                                className='mr-3 text-red'
                            >
                                <path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z" />
                            </svg> Delete </li>
                        </div>
                    </div>
                </div>
                <div className="form-group sm:w-auto w-full">
                    <input autoComplete='off' type="text" name="search" id="search" onChange={e => productSearchValue(e, products)} className="form-control" placeholder="Search" />
                </div>
            </form>

            <div data-controller="table">
                <div className="scrollbar-hidden table-wrapper ">
                    <div className="table">
                        <div className="table-header">
                            <div className="table-row">
                                <div className="table-cell w-xsm">
                                    <label className="inline-flex items-center">
                                        <input onChange={e => onMasterCheck(e)} checked={masterCheck} type="checkbox" className="form-checkbox rounded text-primary-300 h-5 w-5" />
                                        <span className="ml-2 text-gray-700">Checkbox Label</span>
                                    </label>
                                </div>
                                <div className="table-cell flex-1">Name</div>
                                <div className="table-cell flex-1 hidden md:block">Stocks</div>
                                <div className="table-cell flex-1 hidden md:block">Description</div>
                                <div className="table-cell flex-1">Actions</div>
                            </div>
                        </div>
                        {loading ? <PreLoaders /> : ''}
                        <TableSkeleton isLoading={isLoading}>
                            {
                                ((!searchProducts || searchProducts?.length > 0) ? (searchProducts === false ? ifSearchFalse : searchProducts) : paginatedData)?.map((item, index) => {
                                    return (
                                        <div key={index} className="table-body">
                                            <div className="table-row " data-turbo-frame="modal">
                                                <div className="table-cell w-xsm">
                                                    <input type="checkbox" onChange={e => singleItemCheck(e, item, paginatedData, setMasterCheck, setSelectedList)} checked={item?.selected || false} id={String(item?.id)} className="form-checkbox h-5 w-5 rounded text-primary-300" />
                                                </div>
                                                <Link to={`/product/${item?.id}`} className="table-cell flex-1">
                                                    {item?.name.charAt(0).toUpperCase() + item?.name.slice(1)}
                                                </Link>
                                                <Link to={`/product/${item?.id}`} className="table-cell flex-1 hidden md:block">
                                                    {(item?.type === "simple" && item?.stock) || calculateTotalStock(item)}
                                                </Link>
                                                <Link to={`/product/${item?.id}`} className="table-cell flex-1 hidden md:block">
                                                    {item?.description}
                                                </Link>
                                                <div className="table-cell flex-1">
                                                    <button className="text-red-500 hover:bg-text-600 font-normal px-1.5 pb-[0.1rem] rounded-[4px]  text-[13px]" onClick={() => { handleProductDeleteConfirmation(item?.id) }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                )
                            }
                        </TableSkeleton>
                    </div>
                    {!isLoading && (products && (products?.length < 1 || searchProducts === false) ? <NoDataTable /> : "")}
                </div>
            </div>
            {
                (products && products?.length > 10) && <DokaaniTablePagination currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />
            }

            {/* Delete Confirmation Modal */}
            {isDeleteConfirmationModalOpen && (
                <div className={`modal-wrapper  ${isDeleteConfirmationModalOpen ? 'pr20 animate-fade-in' : "!hidden"}`} data-controller="modal" aria-modal="true">
                    <div className="modal-inner modal-align-responsive">
                        <div className="modal-overlay" aria-hidden="true" data-action="click->modal#close"></div>
                        <span className="modal-center-trick" aria-hidden="true">​</span>
                        <turbo-frame data-modal-target="turboFrame" id="modal" target="_top" src="https://app.dokaani.com" complete="">
                            <div className="modal w-responsive">
                                <div className="bg-white p-6 rounded shadow-lg">
                                    <h2 className="text-xl font-semibold mb-4">Are you sure?</h2>
                                    <p className="mb-6">Want to delete this product!</p>
                                    <div className="flex justify-end">
                                        <button
                                            className="bg-gray-200 text-gray-800 px-4 py-2 rounded mr-2"
                                            onClick={() => setIsDeleteConfirmationModalOpen(false)}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className="bg-red-500 text-white px-4 py-2 rounded"
                                            onClick={handleDeleteProduct}
                                        >
                                            Yes, delete it!
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </turbo-frame>
                    </div>
                </div>
            )}
        </div>
    );
}
