import React, { useEffect } from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAxios from '../hooks/useAxios';
import { added_full_name } from '../../redux/user/actions';
import toast from 'react-hot-toast';
import { restrictedKeys } from '../hooks/restrictedKeys';
import { EyeCloseIcon, EyeOpenIcon } from '../helpers/icons/Icons';

export default function UserProfile({ profileShow, setProfileShow }) {

    const axios = useAxios();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.userInfo);
    const [dataUnsaved, setDataChange] = useState(false);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPin, setShowNewPin] = useState(false);
    const [getPhone, setGetPhone] = useState('');
    const [getUserFullName, setUserFullName] = useState('');
    const [oldPin, setOldPin] = useState('');
    const [newPin, setNewPin] = useState('');

    /**
     * Get user mobile number and full name
     */
    useEffect(() => {
        setGetPhone(user?.mobile);
        setUserFullName(user?.full_name);
        // eslint-disable-next-line
    }, [])

    const handlePhoneNumber = e => {
        setDataChange(true);
        setGetPhone(e.target.value)
    }

    // change the user name 
    const handleUserName = e => {
        setDataChange(true);
        setUserFullName(e.target.value);
    }

    /**
    * Toggles the visibility of the password.
    *
    * @return {void} 
    */
    const handleTogglePin = () => {
        setShowOldPassword(!showOldPassword);
    }

    const handleToggleNewPin = () => {
        setShowNewPin(!showNewPin);
    }

    const handleOldPinChange = (e) => {
        setDataChange(true);
        setOldPin(e.target.value);
    }

    const handlePinChange = (e) => {
        setDataChange(true);
        setNewPin(e.target.value);
    }

    /**
  * Handle pin number event.
  *
  * @param {Event} event - The event object.
  */
    const handlePinNumber = (event) => {
        //allow only numbers
        const isKeyRestricted = restrictedKeys.test(event.key);
        if (!isKeyRestricted && isNaN(Number(event.key))) {
            event.preventDefault();
        }
    }

    /**
     * Handles the form submission event.
     *
     * @param {Event} e - The form submission event.
     * @return {Promise<void>} - A promise that resolves after the form submission is handled.
     */
    const handleSubmit = async (e) => {

        e.preventDefault();
        const demoAccount = "01605818600";
        if (getPhone === demoAccount) {
            toast.error("Demo account can't be updated");
            return;
        }
        if (dataUnsaved) {
            setDataChange(false)
            const response = await axios.post("/users/update", {
                full_name: getUserFullName,
                mobile: getPhone,
                pin: oldPin,
                new_pin: newPin
            })
            const { status } = response.data;

            if (status === true) {
                dispatch(added_full_name(getUserFullName));
                toast.success("Profile Updated");
            }

            if (!status) {
                toast.error("Invalid Old Pin.")
            }

            e.target.reset();
            setProfileShow(false);
        }
    }

    return (
        <div className={`modal-wrapper ${profileShow ? "!hidden" : "pr20"}`} data-controller="modal" aria-modal="true">
            <div className="modal-inner">
                <div className="modal-overlay" aria-hidden="true" data-action="click->modal#close"></div>
                <span className="modal-center-trick" aria-hidden="true">​</span>
                <turbo-frame data-modal-target="turboFrame" id="modal" target="_top" src="https://app.dokaani.com/" complete="">
                    <form onSubmit={handleSubmit}>
                        {
                            <div className="modal pane w-responsive">
                                <div className="flex justify-between items-center border-b-1">
                                    <div className="modal-header">
                                        <h3>Edit Profile</h3>
                                    </div>
                                    <button className="profile-button" type='button' onClick={() => setProfileShow(!profileShow)}>
                                        <svg
                                            viewBox="0 0 512 512"
                                            fill="currentColor"
                                            width={"30px"}
                                            height={"30px"}
                                        >
                                            <path d="M289.94 256l95-95A24 24 0 00351 127l-95 95-95-95a24 24 0 00-34 34l95 95-95 95a24 24 0 1034 34l95-95 95 95a24 24 0 0034-34z" />
                                        </svg>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label htmlFor="full_name">Full Name</label>
                                        <input autoComplete="off" placeholder="Your full name" onChange={handleUserName} className="form-control" type="text" value={getUserFullName} name="name" id="full_name" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="user_phone">Phone</label>
                                        <input autoComplete="off" placeholder="Phone Number" onChange={handlePhoneNumber} className="form-control" disabled type="number" value={getPhone} name="mobile" id="user_phone" />

                                    </div>
                                    <div className="form-heading">
                                        <h3>Change PIN</h3><p>To set a new PIN, you need to enter your current PIN first.</p>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="user_current_pin">Current PIN</label>
                                        <div className="relative">
                                            <input autoComplete="off" placeholder="Current PIN" className="form-control" onKeyDown={handlePinNumber} onChange={handleOldPinChange} type={showOldPassword ? "text" : "password"} name="pin" id="user_current_pin" />
                                            {
                                                !showOldPassword ? <EyeCloseIcon handleToggleIcon={handleTogglePin} /> : <EyeOpenIcon handleToggleIcon={handleTogglePin} />
                                            }
                                        </div>
                                        {(oldPin.length > 0 && oldPin.length < 5) && <span className='form-error'>Enter your current PIN.</span>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="new_pin">New PIN</label>
                                        <div className="relative">
                                            <input autoComplete="off" placeholder="New PIN" className="form-control" onKeyDown={handlePinNumber} onChange={handlePinChange} type={showNewPin ? "text" : "password"} name="new_pin" id="new_pin" />
                                            {
                                                !showNewPin ? <EyeCloseIcon handleToggleIcon={handleToggleNewPin} /> : <EyeOpenIcon handleToggleIcon={handleToggleNewPin} />
                                            }
                                        </div>
                                        {newPin.length > 0 && newPin.length < 5 && <span className='form-error'>PIN must be more than 5 character.</span>}
                                    </div>
                                </div>

                                <div className="modal-footer custom-button">
                                    <div className="secondary-actions custom-button delete-custom-btn">
                                        <a href='/' className="underline disabled-link text-gray-500">Delete Account</a>
                                    </div>
                                    {((newPin.length > 0 && oldPin.length > 0) && (newPin.length < 5 && oldPin.length < 5)) ? <button type="submit" className="btn btn-primary btn-md disabled">Save</button> : <button type="submit" className="btn btn-primary btn-md">Save</button>}
                                </div>
                            </div>
                        }
                    </form>

                </turbo-frame>
            </div>
        </div>
    )
}
