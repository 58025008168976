import React from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
const data = [
	{ name: 'Jan', register: 4000 },
	{ name: 'Feb', register: 3000 },
	{ name: 'Mar', register: 2000 },
	{ name: 'Apr', register: 2780 },
	{ name: 'May', register: 1890 },
	{ name: 'Jun', register: 2390 },
	{ name: 'Jul', register: 3490 },
];
export default function Dashboard() {
	return (
		<div className='flex-1 z-0 overflow-y-auto bg-backgroundGray p-8'>
			<div className="rubik-400 mb-10">
				<div className="grid gap-4 lg:gap-8 md:grid-cols-3">
					<div className="relative p-6 rounded-md bg-white shadow">
						<div className="space-y-2">
							<div
								className="flex items-center space-x-2 rtl:space-x-reverse text-sm font-medium text-gray-500 dark:text-gray-400">
								<span>Earn</span>
							</div>

							<div className="text-3xl">
								৳  1920
							</div>

							<div className="flex items-center space-x-1 rtl:space-x-reverse text-sm font-medium text-green-600">

								<span>৳  300 increase</span>

								<svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
									aria-hidden="true">
									<path fillRule="evenodd"
										d="M12 7a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0V8.414l-4.293 4.293a1 1 0 01-1.414 0L8 10.414l-4.293 4.293a1 1 0 01-1.414-1.414l5-5a1 1 0 011.414 0L11 10.586 14.586 7H12z"
										clipRule="evenodd"></path>
								</svg>
							</div>
						</div>
					</div>

					<div className="relative p-6 rounded-md bg-white shadow">
						<div className="space-y-2">
							<div
								className="flex items-center space-x-2 rtl:space-x-reverse text-sm font-medium text-gray-500 dark:text-gray-400">

								<span>New Subscribers</span>
							</div>

							<div className="text-3xl dark:text-gray-100">
								13
							</div>

							<div className="flex items-center space-x-1 rtl:space-x-reverse text-sm font-medium text-green-600">

								<span>7% increase</span>

								<svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
									aria-hidden="true">
									<path fillRule="evenodd"
										d="M12 7a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0V8.414l-4.293 4.293a1 1 0 01-1.414 0L8 10.414l-4.293 4.293a1 1 0 01-1.414-1.414l5-5a1 1 0 011.414 0L11 10.586 14.586 7H12z"
										clipRule="evenodd"></path>
								</svg>
							</div>
						</div>
					</div>

					<div className="relative p-6 rounded-md bg-white shadow">
						<div className="space-y-2">
							<div
								className="flex items-center space-x-2 rtl:space-x-reverse text-sm font-medium text-gray-500 dark:text-gray-400">
								<span>All Subscribers</span>
							</div>

							<div className="text-3xl dark:text-gray-100">
								340
							</div>

							<div className="flex items-center space-x-1 rtl:space-x-reverse text-sm font-medium text-red-600">

								<span>3% decrease</span>

								<svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
									aria-hidden="true">
									<path fillRule="evenodd"
										d="M12 13a1 1 0 100 2h5a1 1 0 001-1V9a1 1 0 10-2 0v2.586l-4.293-4.293a1 1 0 00-1.414 0L8 9.586 3.707 5.293a1 1 0 00-1.414 1.414l5 5a1 1 0 001.414 0L11 9.414 14.586 13H12z"
										clipRule="evenodd"></path>
								</svg>
							</div>
						</div>

					</div>
				</div>
			</div>

			<h3 className='text-lg mb-5'>Subscriber Stats</h3>
			<ResponsiveContainer width="100%" height={200}>
				<LineChart
					width={500}
					height={200}
					data={data}
					margin={{
						top: 10,
						right: 30,
						left: 0,
						bottom: 0,
					}}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="name" />
					<YAxis />
					<Tooltip />
					<Line connectNulls type="monotone" dataKey="register" stroke="#004c3f" fill="#004c3f" />
				</LineChart>
			</ResponsiveContainer>
		</div>
	)
}
