import {
	ADDED_ADDRESS,
	ADDED_CUSTOMER,
	ADDED_HIGHLIGHT_ROW_ID,
	CUSTOMER_FORM_DATA,
	DELETE_ADDRESS_DATA,
	DELETE_CUSTOMER,
	GET_EDIT_ADDRESS,
	LOADED_CUSTOMER,
	SEARCH_CUSTOMER,
	SET_ADDRESS,
	SET_CUSTOMER_DELETE_LOADING,
	SET_LOADING,
	SET_TABLE_OF_CUSTOMER,
	UPDATE_CUSTOMER,
} from "./actionTypes/actionTypes";
import { initialValues } from "./initialValues";

/**
 * Customer Reducer
 * @param {*} state all state
 * @param {*} action all actions
 * @returns object
 */
export const customerReducer = (state = initialValues, action) => {
	/**
	 * @LOADED_CUSTOMER - loaded all customer and set `customers` state
	 * @ADDED_CUSTOMER - add new customer and set the `customers` state
	 * @SET_ADDRESS -  set single customer address
	 * @ADDED_HIGHLIGHT_ROW_ID - set highlight row
	 * @GET_EDIT_ADDRESS - get edit customer address
	 * @DELETE_ADDRESS_DATA - delete customer address
	 * @ADDED_ADDRESS - add customer new address
	 * @UPDATE_CUSTOMER - update customer
	 * @DELETE_CUSTOMER - delete customer
	 * @CUSTOMER_FORM_DATA - customer form data
	 * @SEARCH_CUSTOMER - search customer
	 * @SET_LOADING - set loading
	 * @SET_TABLE_OF_CUSTOMER - set show table of customer
	 */

	switch (action.type) {
		case LOADED_CUSTOMER:
			return {
				...state,
				customers: action.payload,
			};
		case ADDED_CUSTOMER:
			return {
				...state,
				customers: [...state.customers, action.payload],
			};
		case SET_ADDRESS:
			return {
				...state,
				addresses: action.payload,
			};
		case GET_EDIT_ADDRESS:
			return {
				...state,
				getAddressData: action.payload,
			};
		case ADDED_ADDRESS:
			return {
				...state,
				addresses: [action.payload, ...state.addresses],
			};
		case ADDED_HIGHLIGHT_ROW_ID:
			return {
				...state,
				highlightedRowID: action.payload,
			};
		case DELETE_ADDRESS_DATA:
			return {
				...state,
				addresses: state.addresses.filter(
					(address) => address.id !== action.payload
				),
			};
		case UPDATE_CUSTOMER:
			return {
				...state,
				customers: state.customers.map((customer) =>
					customer.id === action.payload ? action.payload : customer
				),
			};
		case DELETE_CUSTOMER:
			return {
				...state,
				tableOfCustomers: state.tableOfCustomers.filter(
					(customer) => customer.id !== action.payload
				),
			};
		case CUSTOMER_FORM_DATA:
			return {
				...state,
				customerFormData: action.payload,
			};
		case SEARCH_CUSTOMER:
			return {
				...state,
				searchCustomers: action.payload,
			};
		case SET_TABLE_OF_CUSTOMER:
			return {
				...state,
				tableOfCustomers: action.payload,
			};
		case SET_LOADING:
			return {
				...state,
				isLoading: action.payload,
			};
		case SET_CUSTOMER_DELETE_LOADING:
			return {
				...state,
				isDeleteLoading: action.payload,
			};
		default:
			return state;
	}
};
