export const getLocalStorage = (key) => {
    const getItem = localStorage.getItem(key);
    try {
        return JSON.parse(getItem);
    } catch (error) {
        console.log();
    }

}

export const setLocalStorage = (key, value) => {
    const saveLocally = JSON.stringify(value);
    return localStorage.setItem(key, saveLocally)
}



