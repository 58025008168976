import { NextIcon, PrevIcon } from "../helpers/icons/Icons";

const DokaaniTablePagination = ({ currentPage, totalPages, handlePageChange }) => {
    const pageNumbers = [];
    const maxDisplayedPages = 5; // Maximum number of page links to display at once

    // Calculate the range of page numbers to display
    let startPage, endPage;
    if (totalPages <= maxDisplayedPages) {
        // If total pages is less than or equal to the maximum displayed pages, display all page numbers
        startPage = 1;
        endPage = totalPages;
    } else {
        // Otherwise, calculate the start and end page numbers
        const middlePage = Math.floor(maxDisplayedPages / 2);
        if (currentPage <= middlePage) {
            // If current page is close to the beginning, display the first maxDisplayedPages pages
            startPage = 1;
            endPage = maxDisplayedPages - 1;
        } else if (currentPage >= totalPages - middlePage) {
            // If current page is close to the end, display the last maxDisplayedPages pages
            startPage = totalPages - maxDisplayedPages + 2;
            endPage = totalPages;
        } else {
            // Otherwise, display the current page in the middle
            startPage = currentPage - Math.floor(maxDisplayedPages / 2) + 1;
            endPage = currentPage + Math.floor(maxDisplayedPages / 2) - 1;
        }
    }

    // Generate the page numbers array
    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    return (
        <nav>
            <ul className="pagination">
                {currentPage > 1 && (
                    <li
                        key="previous-page"
                        onClick={() => handlePageChange(currentPage - 1)}
                        className="page-item cursor-pointer flex items-center"
                    >
                        <button type="button" className="text-black rounded-l-md border-r border-gray-100 page-link py-1">
                            <div className="flex flex-row align-middle">
                                <PrevIcon />
                                <p className="">Prev</p>
                            </div>
                        </button>
                    </li>
                )}

                {startPage > 1 && (
                    <li
                        key="start-page"
                        onClick={() => handlePageChange(1)}
                        className="page-item cursor-pointer px-3 py-1 hover:bg-green-400 rounded-full"
                    >
                        <span className="page-link">{1}</span>
                    </li>
                )}

                {startPage > 2 && (
                    <li key="start-ellipsis" className="page-item">
                        <span className="page-link">...</span>
                    </li>
                )}

                {pageNumbers.map((number) => (
                    <li
                        key={number}
                        onClick={() => handlePageChange(number)}
                        className={`page-item cursor-pointer ${currentPage === number ? 'bg-green-500 px-3 py-1 rounded-full' : 'px-3 py-1 hover:bg-green-400 rounded-full'
                            }`}
                    >
                        <button className="page-link">{number}</button>
                    </li>
                ))}

                {endPage < totalPages - 1 && (
                    <li key="end-ellipsis" className="page-item">
                        <span className="page-link">...</span>
                    </li>
                )}

                {endPage < totalPages && (
                    <li
                        key="end-page"
                        onClick={() => handlePageChange(totalPages)}
                        className="page-item cursor-pointer px-3 py-1 hover:bg-green-400 rounded-full"
                    >
                        <span className="page-link">{totalPages}</span>
                    </li>
                )}

                {currentPage < totalPages && (
                    <li
                        key="next-page"
                        onClick={() => handlePageChange(currentPage + 1)}
                        className="page-item cursor-pointer"
                    >
                        <button type="button" className="text-black rounded-l-md border-r border-gray-100 page-link py-1">
                            <div className="flex flex-row align-middle">
                                <p className="ml-2">Next</p>
                                <NextIcon />
                            </div>
                        </button>
                    </li>
                )}
            </ul>
        </nav>
    );
};
export default DokaaniTablePagination;