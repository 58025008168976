import React from 'react'
import CheckMarkIcon from '../../../helpers/CheckMarkIcon'
import CrossMarkIcon from '../../../helpers/CrossMarkIcon'

export default function PricingCard({ index, data }) {
    return (
        <div className=' grid lg:grid-cols-3 sm:grid-cols-1 gap-4' key={index}>
            {
                data?.packages && data?.packages.map((pkg, indx) => (
                    <div className="bg-white p-6 rounded-lg border border-gray-200 shadow-sm" key={indx}>

                        <h3 className="text-2xl font-semibold text-gray-800">{pkg?.title}</h3>
                        <div className="mt-4 flex items-center gap-3">
                            <p className='text-red-600 text-medium'><del>{pkg?.price_regular} টাকা</del></p> <h1 className='text-2xl'>{pkg?.price_sale} টাকা / {data?.label}</h1>
                        </div>
                        <p className='py-2'>{data?.short_desc}</p>

                        <div className="pricing-details py-5">
                            {pkg?.features && pkg?.features.map((feature, idx) => (
                                <div className="items flex gap-2 items-center py-2">
                                    {feature?.status ? <CheckMarkIcon /> : <CrossMarkIcon />}
                                    <p className='leading-normal text-black text-md' key={idx}>{feature?.name}</p>
                                </div>
                            ))}
                        </div>

                        <div className="pt-5 mt-auto">
                            <button className="bg-green-600 text-white px-4 py-2 rounded">Get Purchased</button>
                        </div>

                    </div>
                ))
            }



        </div>
    )
}
