export default function FormatePhoneNumber(phone) {
    //After 5 digit phone number set (-)hyphen sign
    // const number = phone.replace(/\D[^\.]/g, "");
    if (phone?.length > 10) {
        const numberSlice = phone.slice(0, 5) + "-" + phone.slice(5, 11);
        return numberSlice;
    } else {
        return 0;
    }


}