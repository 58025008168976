import React, { useContext, useEffect, useState } from 'react'
import LoadingTextButton from '../Buttons/LoadingTextButton';
import { useAxios } from '../../hooks';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';
import { parentContext } from '../../App';

export default function Website({ storeData }) {

	const axios = useAxios();
	const { environment, setModalIndex } = useContext(parentContext);
	const [connectWebsite, setConnectWebsite] = useState(false);
	const [isCreateSubDomain, setIsCreateSubDomain] = useState(false);
	const [domainCreatingMsg, setDomainCreatingMsg] = useState("Creating sub domain");
	const websiteDomain = storeData?.name.replace(/\s/g, "").toLowerCase() + ".dokaani.xyz";
	const domain = storeData?.name.toLowerCase().replace(/\s/g, "");

	const installWebsite = async () => {
		const installResponse = await axios.post("/websites/install", { store_id: storeData?.id, admin_email: "admin@dokaani.com" });
		const { status } = await installResponse.data;
		return status;
	}

	const installTools = async () => {
		const installToolsResponse = await axios.post("/websites/install-tools", { store_id: storeData?.id });
		const { status } = await installToolsResponse.data;
		return status;
	}

	const configureWebsite = async () => {
		const configureResponse = await axios.post("/websites/configure", { store_id: storeData?.id });
		const { status } = await configureResponse.data;
		return status;
	}

	const finalizeWebsite = async () => {
		const finalizeResponse = await axios.post("/websites/finalize", { store_id: storeData?.id });
		const { status } = await finalizeResponse.data;
		return status;
	}

	const connectSite = async () => {
		setIsCreateSubDomain(true);
		setDomainCreatingMsg("Creating your domain");
		const response = await axios.post("/websites/add-subdomain", { store_id: storeData?.id, sub_domain_name: domain, override: true });
		const { status, data } = await response.data;

		if (status) {
			// install website
			setDomainCreatingMsg("Installing your website");
			const installWebsiteResponse = await installWebsite();

			if (installWebsiteResponse) {
				// install website tools
				setDomainCreatingMsg("Installing website tools");
				// const installToolsResponse = await installTools();

				if (true) {
					// configure website
					setDomainCreatingMsg("Configuring your website");
					// const configureResponse = await configureWebsite();
					// settimeout for testing only
					setTimeout(() => {
						setDomainCreatingMsg("Finalizing website creation");
					}, 5000);
					if (true) {
						// finalize website
						setDomainCreatingMsg("Finalizing website creation");
						// const finalizeResponse = await finalizeWebsite();

						if (true) {
							setConnectWebsite(false);
							setIsCreateSubDomain(false);
						}
					}
				}

				setConnectWebsite(false);
				setIsCreateSubDomain(false);
				Swal.fire({
					title: 'Successfully Installed Website',
					text: "Your website is ready to use. Please check your website and make it live. Thank you.",
					icon: 'success',
					confirmButtonColor: '#3085d6',
					confirmButtonText: 'OK',
				})
			} else {
				toast.error(data?.message)
				setConnectWebsite(false);
				setIsCreateSubDomain(false);
			}

		} else {
			Swal.fire({
				title: "Website creation failed!",
				text: data?.message + "\nPlease try other stores.",
				icon: 'warning',
				confirmButtonColor: '#3085d6',
				confirmButtonText: 'OK',
			})
			setConnectWebsite(false);
		}
	}

	useEffect(() => {
		if (connectWebsite) {
			connectSite()
		}
	}, [connectWebsite])


	useEffect(() => {
		setModalIndex(connectWebsite);
		// eslint-disable-next-line
	}, [connectWebsite])

	return (
		<div className='py-6 px-6 form-section-wrapper rounded-none border-none'>
			{
				axios.environment === 'stage' || axios.environment === 'local' ?
					<div className="form-group mb-3">
						<label htmlFor="phone">Website Domain</label>
						<div className="flex gap-3">
							<input disabled value={websiteDomain} className="form-control w-full" type="text" />
							<button onClick={() => setConnectWebsite(true)} type='button' className="btn hover:bg-primary hover:text-white transition-all duration-200 w-32 rounded-md flex justify-center">Add website</button>
						</div>
					</div>
					:
					<p>This website feature is coming soon.</p>
			}
			{
				connectWebsite ?
					<div className={`modal-wrapper  ${connectWebsite ? 'pr20 animate-fade-in' : "!hidden"}`} data-controller="modal" aria-modal="true">
						<div className="modal-inner modal-align-responsive">
							<div className="modal-overlay" aria-hidden="true" data-action="click->modal#close"></div>
							<span className="modal-center-trick" aria-hidden="true">​</span>
							<turbo-frame data-modal-target="turboFrame" id="modal" target="_top" src="https://app.dokaani.com" complete="">
								{/* modal header content */}
								<div className="modal w-responsive">
									<div className="modal-header flex justify-center">
										<img className='w-24 rounded-md' src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhLR66FoZk0otUJ1eYFXDxPAlpxteRATmwWakVmThIvQ&s' alt='connecting site logo' />
									</div>
									<div className="modal-body flex flex-col gap-2">
										<p className="title">Connecting {domain}</p>
										<p className="desc">Please wait while we connect your website. This may take a few minutes.</p>
									</div>
									<div className="modal-footer !justify-center">
										<LoadingTextButton title={isCreateSubDomain ? domainCreatingMsg : "Connecting website"} />
									</div>
								</div>
							</turbo-frame>
						</div>
					</div> : ""
			}
		</div>
	)
}
