import useAxios from "../../../app/hooks/useAxios";
import {
	add_total_payment_amount,
	added_address_id,
	added_all_product,
	added_discount_in_order,
	added_due_amount,
	added_order_product_item,
	added_order_status,
	added_ordered_total_amount,
	added_payments,
	added_product_details,
	delete_order_payment_loading,
	delete_order_product_loading,
	is_loading_order,
	set_all_orders,
	set_edit_order_loading,
	single_order,
} from "../../orders/action";
import { get_addresses } from "../customers/customerThunk";

/**
 * Retrieves loaded orders from the server for a specific store.
 *
 * @param {number} store_id - The ID of the store to retrieve orders for.
 * @return {Promise} A promise that resolves with the loaded orders data.
 */
export const get_loaded_orders = (store_id) => {
	return async (dispatch) => {
		const axios = useAxios();
		dispatch(is_loading_order(true));
		const response = await axios.post("/orders/all", { store_id: store_id });
		const { data } = await response.data;
		dispatch(set_all_orders(data));
		dispatch(is_loading_order(false));
	};
};

export const get_single_order = (id, store_id) => {
	return async (dispatch, getState) => {
		const axios = useAxios();
		dispatch(set_edit_order_loading(true));
		const response = await axios.get("/orders", { params: { id: id } });
		const { data } = await response.data;
		const {
			ordered_total,
			payments,
			order_status,
			discount,
			ordered_items,
			address,
			customer,
		} = data;

		if (data === false) {
			dispatch(set_edit_order_loading(false));
			window.location.href = "/orders";
			return;
		}

		// check payments is empty or not
		if (payments?.length > 0) {
			let totalAmount = 0;

			payments.forEach((payment) => {
				totalAmount += Number(payment.paid_amount);
			});
			// calculate the payment total amount
			if (ordered_total > 0) {
				dispatch(added_due_amount(ordered_total - totalAmount));
				dispatch(add_total_payment_amount(totalAmount));
			} else {
				dispatch(added_due_amount(0));
				dispatch(add_total_payment_amount(0));
			}
		} else {
			dispatch(added_due_amount(ordered_total));
			dispatch(add_total_payment_amount(0));
		}

		dispatch(single_order(data));
		dispatch(added_ordered_total_amount(ordered_total));
		dispatch(added_order_status(order_status));
		dispatch(added_order_product_item(ordered_items));
		dispatch(added_discount_in_order(discount));
		dispatch(added_payments(payments));
		dispatch(get_all_products(store_id));
		dispatch(set_edit_order_loading(false));

		// get the addresses, set the address name and full address
		if (address?.id) {
			dispatch(added_address_id(address?.id));
			await dispatch(get_addresses(store_id, customer?.id, true, true));
			return;
		} else {
			// get the addresses, set the address name and full address
			await dispatch(get_addresses(store_id, customer?.id, true));
		}
	};
};

export const get_all_products = (store_id) => {
	return async (dispatch, getState) => {
		const axios = useAxios();
		const response = await axios.get("/products/all?store_id=" + store_id);
		const { data, status } = await response.data;

		// if user unauthorize
		if (status === 400) {
			localStorage.clear();
			window.location.href = "/";
			return;
		}

		dispatch(added_all_product(data));
	};
};

export const get_the_product_details = (storeID, id) => {
	return async (dispatch) => {
		const axios = useAxios();
		const response = await axios.get(
			"/product?store_id=" + storeID + "&id=" + id
		);
		const { data, status } = await response.data;

		// if data and status false then navigate to products page
		if (data === false || status === false) {
			window.location.href = "/products?status=false&msg=invalid_product";
			return;
		}

		dispatch(added_product_details(data));
	};
};

export const delete_single_order = (order_id, store_id) => {
	return async (dispatch, getState) => {
		const axios = useAxios();
		const { allOrder } = getState().orders;

		const response = await axios.post("/orders/delete", {
			order_id: order_id,
			store_id,
		});

		const { data } = await response.data;
		if (data) {
			await dispatch(set_all_orders(allOrder.filter((p) => p.id !== order_id)));
		}

		if (data.status === false) {
			window.location.href = "/orders";
			return;
		}
	};
};

export const delete_multiple_orders = (order_ids) => {
	return async (dispatch, getState) => {
		const { allOrder } = getState().orders;
		await dispatch(
			set_all_orders(allOrder.filter((p) => !order_ids.includes(p.id)))
		);
	};
};

export const delete_order_product = (item_id, order_id, store_id) => {
	return async (dispatch, getState) => {
		dispatch(delete_order_product_loading(20));
		const axios = useAxios();
		const { orderProductItem, totalOrderAmount, dueAmount } = getState().orders;

		const { subtotal: deletedSubtotal } = orderProductItem?.find(
			(p) => p.id === item_id
		);

		const response = await axios.post("/orders/product/delete", {
			order_id,
			item_id,
			store_id,
		});
		const { data } = await response.data;
		dispatch(delete_order_product_loading(50));
		if (data) {
			const draftItems = orderProductItem.filter((p) => p.id !== item_id);
			await dispatch(added_order_product_item(draftItems));
			await dispatch(
				added_ordered_total_amount(totalOrderAmount - deletedSubtotal)
			);
			await dispatch(added_due_amount(dueAmount - deletedSubtotal));
			dispatch(delete_order_product_loading(100));
		} else {
			dispatch(delete_order_product_loading(100));
		}
	};
};

export const delete_order_payment = (payment_id, order_id) => {
	return async (dispatch, getState) => {
		const axios = useAxios();
		const { totalPaymentAmount, orderedPayment, dueAmount } = getState().orders;
		const deleteData = orderedPayment?.find((p) => p.id === payment_id);

		await dispatch(delete_order_payment_loading(20));
		try {
			const response = await axios.post("/orders/payments/delete", {
				payment_id,
				order_id,
			});

			const { data } = await response.data;
			dispatch(delete_order_payment_loading(50));

			if (data) {
				const draftPayments = orderedPayment.filter((p) => p.id !== payment_id);
				await dispatch(added_payments(draftPayments));
				await dispatch(
					add_total_payment_amount(totalPaymentAmount - deleteData?.paid_amount)
				);
				await dispatch(
					added_due_amount(dueAmount + Number(deleteData?.paid_amount))
				);

				dispatch(delete_order_payment_loading(100));
			} else {
				dispatch(delete_order_payment_loading(100));
			}
		} catch (error) {
			console.log(error);
			dispatch(delete_order_payment_loading(100));
		}
	};
};

export const change_order_status = (id, status) => {};
