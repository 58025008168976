import React, { memo, Suspense, useCallback, useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import OrderSkeleton from "../../components/skeletonLoader/OrderSkeleton";
import { useDispatch, useSelector } from "react-redux";
import { get_single_order } from "../../../redux/thunk/orders/orderThunk";
import { set_active_tab } from "../../../redux/basicActions/actions";
import { get_active_tab } from "../../../redux/thunk/basicAction/basicActionThunk";
import {
    added_due_amount,
    added_ordered_total_amount,
    set_is_new_order,
} from "../../../redux/orders/action";
import toast from "react-hot-toast";
import { UseHelmet, useAxios } from "../../hooks";
import { cn } from "../../../util/cn";
import AddOrderDiscount from "../../components/portals/orders/AddOrderDiscount";
import { BackIcon, GeneralInvoiceIcon, PosInvoiceIcon, QuickActionIcon, SMSIcon } from "../../helpers/icons/Icons";

const GeneralTab = React.lazy(() => import("../../components/orders/OrderDetailsTab/GeneralTab"));
const PaymentTab = React.lazy(() => import("../../components/orders/OrderDetailsTab/PaymentTab"));
const ProductsTab = React.lazy(() => import("../../components/orders/OrderDetailsTab/ProductsTab"));
const QuickActions = React.lazy(() =>
    import("../../components/orders/OrderDetailsTab/QuickActions")
);
const ShippingAddressTab = React.lazy(() =>
    import("../../components/orders/OrderDetailsTab/ShippingAddressTab")
);

const OrderDetails = memo(() => {
    const { id } = useParams();
    const axios = useAxios();
    const navigate = useNavigate();
    const activeStore = localStorage.getItem("activeStore");

    const dispatch = useDispatch();
    const basicActions = useSelector((state) => state.basicActions);
    const orders = useSelector((state) => state.orders);
    const { activeTab } = basicActions;
    const {
        isNewOrder,
        orderData,
        totalPaymentAmount,
        dueAmount,
        totalOrderAmount,
        editOrderLoading,
    } = orders;
    const { ordered_total, invoice_hash, discount } = orderData;
    const [showActionBar, setShowActionBar] = useState(false);
    const [discountLoading, setDiscountLoading] = useState(false);
    const [discounts, setDiscounts] = useState({ type: "", value: 0 });
    const [totalPayment, setTotalPayment] = useState(0);
    const [due, setDueAmount] = useState(0);

    /**
     * Get single order data by id
     * @param {int}   id
     * @param {int}   activeStore
     * @returns
     */
    useEffect(() => {
        dispatch(get_single_order(id, activeStore));
        // eslint-disable-next-line
    }, [id, activeStore]);

    // set discount data in `discounts` state
    useEffect(() => {
        setDiscounts({ type: discount?.type, value: discount?.value });
    }, [discount]);

    /**
     * Displays a toast message when the order is created
     */
    useEffect(() => {
        if (isNewOrder) {
            toast.success("Order created");
            dispatch(set_is_new_order(false));
        }
        // eslint-disable-next-line
    }, []);

    // set order total amount and due amount
    useEffect(() => {
        setTotalPayment(totalPaymentAmount);
        setDueAmount(dueAmount);
    }, [id, totalPaymentAmount, dueAmount, ordered_total, totalOrderAmount]);

    /**
     * Set the active tab
     * @param {int}   activeTab value
     * @returns void
     */
    useEffect(() => {
        dispatch(get_active_tab("general-tab"));
    }, [dispatch]);

    /**
     * Handle tab change
     * @param {*} event get the id
     * @returns void
     */
    const tabChange = useCallback((e) => {
        dispatch(set_active_tab(e.target?.id));
    }, [dispatch]);

    const handleChangeDiscount = (e) => {
        setDiscounts({ ...discounts, value: e.target.value });
    };

    const handleSaveDiscount = useCallback(() => {
        setDiscountLoading(true);
        // check discount number is positive or not
        if (discounts?.discount <= 0) {
            toast.error("Invalid discount");
            return;
        }
        // save to the server
        try {
            (async () => {
                const response = await axios.post(`/orders/discount`, {
                    order_id: id,
                    ...discounts,
                });
                const { status, data } = response.data;
                if (status) {
                    setDueAmount(data?.due_amount);
                    await dispatch(added_ordered_total_amount(data?.ordered_total));
                    await dispatch(added_due_amount(data?.due_amount));
                    setDiscountLoading(false);
                    toast.success(data?.message);
                } else {
                    toast.error("Discount apply failed!");
                    setDiscountLoading(false);
                }
            })();
        } catch (error) {
            toast.error("Discount apply failed!");
        }
    }, [id, discounts, dispatch, axios]);

    const handleSmsSend = () => {
        try {
            (async () => {
                const response = await axios.post(`/orders/notify`, {
                    order_id: id,
                });
                if (response.data.status) {
                    toast.success("SMS Sent");
                } else {
                    toast.error("SMS sent failed!");
                }
            })();
        } catch (error) {
            toast.error("SMS send failed!");
        }
    };

    /**
     * Handle invoice Routes
     * when click the invoice button navigate to invoice
     * @returns void
     */
    const handlePosInvoice = useCallback(() => {
        navigate("/pos-invoice/" + invoice_hash);
    }, [navigate, invoice_hash]);

    const handleGeneralInvoice = useCallback(() => {
        navigate("/general-invoice/" + invoice_hash);
    }, [navigate, invoice_hash]);

    const handleQuickAction = () => {
        setShowActionBar(!showActionBar);
    };

    return (
        <main
            className="flex-1 z-0 overflow-y-auto bg-backgroundGray p-8"
            onClick={() => showActionBar && handleQuickAction()}
        >
            <UseHelmet title={`Order #${id}`} />
            <OrderSkeleton loading={editOrderLoading}>
                <div className="max-w-7xl mx-auto">
                    <div className="section-heading borderless ">
                        {/* section hading back button */}
                        <div className="flex">
                            <Link
                                className="back-link btn btn-white btn-xs"
                                data-controller="link"
                                to="/orders"
                            >
                                <BackIcon />
                            </Link>
                            {/* order number */}
                            <div className="title">
                                <h2>
                                    <span className="text-2xl">
                                        #{id} <span className="mx-10"></span>
                                    </span>
                                </h2>
                            </div>
                        </div>
                    </div>
                    {/* all tabs and tab related components */}
                    <div className="sm-relative">
                        <div className="table-wrapper relative">
                            <div className="overflow-hidden border border-gray-100 bg-gray-50 p-1 py-2 btw-0">
                                <ul className="flex items-center gap-5 text-md font-medium pl-4">
                                    <li className="list-none">
                                        <p
                                            onClick={tabChange}
                                            id="general-tab"
                                            className={cn(
                                                { "order-active-tab": activeTab === "general-tab" },
                                                "order-tabs"
                                            )}
                                        >
                                            General
                                        </p>
                                    </li>
                                    <li className="list-none">
                                        <p
                                            onClick={tabChange}
                                            id="shipping-tab"
                                            className={cn(
                                                { "order-active-tab": activeTab === "shipping-tab" },
                                                "order-tabs"
                                            )}
                                        >
                                            Shipping
                                        </p>
                                    </li>
                                </ul>
                                <div
                                    className="absolute top-4 right-5 cursor-pointer"
                                    onClick={handleQuickAction}
                                >
                                    <QuickActionIcon />
                                </div>
                                <div
                                    className={`quick-action absolute right-8 z-40 mt-[-3px] rounded-md shadow-md w-48 origin-top-right ${showActionBar ? "" : "d-none "
                                        } `}
                                >
                                    <div className="rounded-md ring-1 ring-black ring-opacity-5 py-1 bg-white border ">
                                        <div className="py-1 hover-children font-medium">
                                            <li
                                                onClick={handleSmsSend}
                                                className="order-quick-action-btn"
                                            >
                                                <SMSIcon />
                                                Send SMS
                                            </li>
                                            <li
                                                onClick={handleGeneralInvoice}
                                                className="order-quick-action-btn"
                                            >
                                                <GeneralInvoiceIcon />
                                                General Invoice
                                            </li>
                                            <li
                                                onClick={handlePosInvoice}
                                                className="order-quick-action-btn"
                                            >
                                                <PosInvoiceIcon />
                                                Pos Invoice
                                            </li>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* when active tab then show the components */}
                            <div className="px-6 my-8">
                                <Suspense fallback={<OrderSkeleton />}>
                                    {activeTab === "general-tab" && (
                                        <>
                                            <GeneralTab
                                                totalPayment={totalPayment}
                                                due={due}
                                                id={id}
                                            />
                                            <ProductsTab />
                                            <AddOrderDiscount
                                                discountLoading={discountLoading}
                                                discounts={discounts}
                                                setDiscounts={setDiscounts}
                                                handleSaveDiscount={handleSaveDiscount}
                                                handleChangeDiscount={handleChangeDiscount}
                                            />
                                            <PaymentTab />
                                            <QuickActions />
                                        </>
                                    )}
                                    {activeTab === "shipping-tab" && <ShippingAddressTab />}
                                </Suspense>
                            </div>
                        </div>
                    </div>
                </div>
            </OrderSkeleton>
        </main>
    );
})
export default OrderDetails;