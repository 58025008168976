import React, { useContext, useEffect, useState } from 'react';
import useAxios from '../../hooks/useAxios';
import { parentContext } from '../../App';
import { Link, useNavigate } from 'react-router-dom';
import { setLocalStorage } from '../../hooks/useLocalStorage';
import UseHelmet from '../../hooks/UseHelmet';
import { useForm } from 'react-hook-form';
import { validateNumber } from '../../hooks/validateNumber';
import { useSelector } from 'react-redux';
import { SetCookie } from '../../hooks/Cookies';
import toast from 'react-hot-toast';
import LoadingButton from '../../components/Buttons/LoadingButton';

export default function NewStore() {

    const axios = useAxios();
    const navigate = useNavigate();
    const userInfo = useSelector((state) => state.userInfo);
    const { isStore } = userInfo;
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const { setProgress } = useContext(parentContext);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [phoneError, setPhoneError] = useState(null);
    const [storeData, SetStoreData] = useState([]);
    const [dataSubmit, setDataSubmit] = useState(false);

    /**
     * when is true to submit information then store to database
     * when submit form then store to database
     * Save the store in database and navigate to dashboard
     * Save store data in local storage
     * @return {void}
    * */
    useEffect(() => {
        const newStore = async () => {
            try {
                setLoading(true);
                setProgress(25);
                // add store data
                const newStores = await axios.post('/stores/add', {
                    name: storeData.name,
                    phone: storeData.phone,
                    post_code: storeData.post_code,
                    address: storeData.address,
                });
                // error msg 
                const statusCode = newStores.data.status;
                const { message } = newStores.data.data;
                // if store not created then show error msg
                if (statusCode === 404) {
                    setError(message);
                    toast.error(message);
                    setProgress(100);
                    setLoading(false);
                }

                if (statusCode === 400) {
                    localStorage.clear();
                    SetCookie('ask', '', -1);
                    setProgress(100);
                    setLoading(false);
                    navigate('/');
                }

                // when status code is true then set local storage and navigate to dashboard
                if (statusCode === true) {
                    const { data } = newStores?.data;
                    setLocalStorage("activeStore", data?.id);
                    setLocalStorage("allStores", data);
                    setLocalStorage("storeInfo", storeData);
                    window.location.replace("/dashboard");
                    setProgress(100);
                }

            } catch (e) {
                setProgress(100);
                setLoading(false)
            }
        }

        // when data submit then store created
        if (dataSubmit) {
            !phoneError && newStore();
            setDataSubmit(false);
        }
        // eslint-disable-next-line
    }, [dataSubmit])

    // phone number strick validation 
    const handlePhoneNumber = (event, length) => {
        const restrictedKeys = /Enter|Backspace|Tab|ArrowRight|ArrowLeft|a|Delete|Home|Tab|End/;
        const isKeyRestricted = restrictedKeys.test(event.key);

        if (event.target.value.length >= length && !isKeyRestricted) {
            event.preventDefault();
        }
    }

    // realtime mobile number validation
    useEffect(() => {
        if (watch().phone?.length > 0 && !validateNumber(watch().phone)) {
            setPhoneError("Invalid phone number.");
        } else {
            setPhoneError(null);
        }
        // eslint-disable-next-line
    }, [watch().phone])

    const onSubmit = data => {
        SetStoreData(data);
        setDataSubmit(true);
    }

    return (
        <section className="flex h-screen py-10 items-center justify-center">
            <UseHelmet title="New Store" />
            <main className="w-m-xl m-auto pb-10">
                <img src="/assets/images/login/dokaani-en.svg" className="m-auto mb-8 h-10 w-auto" style={{ width: "160px" }} alt="Dokaani logo" />
                <div className="max-2xl mx-auto">
                    <div className="basic-heading space-y-1 mb-4 px-4">
                        <h2 className="text-xl text-center font-semibold">
                            Create a New Store
                        </h2>
                        <p className="text-base text-center">
                            Enter a few details to create your new store.
                        </p>
                        {
                            error && <div className='flex justify-center items-center'>
                                <p className="form-error text-xl">{error}</p>
                            </div>
                        }
                    </div>
                    {/* new store form */}
                    <div className="form-section-wrapper ">
                        <div className="form-section">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group">
                                    <label htmlFor="name">Store Name</label>
                                    <input placeholder="Enter your store name" autoComplete='off' {...register("name", { required: true, minLength: 3, maxLength: 100 })} maxLength={100} className="form-control" type="text" name="name" id="name" />
                                    {errors.name && <span className='form-error'>Enter a valid store name.</span>}
                                </div>

                                <div className="form-group">
                                    <label htmlFor="phone">Store Phone Number</label>
                                    <input placeholder="01XXXXXXXXX" autoComplete='off' onKeyDown={(e) => handlePhoneNumber(e, 11)} {...register("phone", { required: true, maxLength: 11 })} maxLength={11} className="form-control" type="number" name="phone" id="phone" />
                                    {phoneError && <span className='form-error'>{phoneError}</span>}
                                </div>

                                <div className="form-group">
                                    <label htmlFor="post_code">Store Post Code</label>
                                    <input placeholder="Enter your store post code" autoComplete='off' onKeyDown={(e) => handlePhoneNumber(e, 4)} {...register("post_code", { required: true, minLength: 4, maxLength: 4 })} maxLength={4} className="form-control" type="number" name="post_code" id="post_code" />
                                    {errors.post_code && <span className='form-error'>Enter a valid post code.</span>}
                                </div>

                                <div className="form-group">
                                    <label htmlFor="address">Store Street Address</label>
                                    <textarea cols="6" rows="3" placeholder="Enter your store street address" {...register("address", { required: true, minLength: 5, maxLength: 100 })} maxLength={100} className="form-control" type="text" name="address" id="address" />
                                    {errors.address && <span className='form-error'>Enter a valid address.</span>}
                                </div>

                                <div className="modal-f mt-7">
                                    <div className="secondary-action">
                                        {isStore && <Link to="/dashboard" className=' mr-4'>Cancel</Link>}
                                        {loading ? <LoadingButton title="Creating" /> : <button type="submit" className='btn btn-primary btn-md'>Create Store</button>}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </main>
        </section>
    )
}
