import React from 'react'

export default function NotMatch() {

    return (
        <div className="h-screen flex overflow-hidden">
            <main className="flex-1 relative w-full m-auto z-0 p-8 overflow-y-auto focus:outline-none">
                <div className="flex flex-col items-center space-y-4">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" role="img" className="text-primaryGray icon h-20 w-20">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
                    </svg>

                    <h3 className="text-3xl font-semibold">Page Not Found</h3>
                    <p>Sorry, we couldn't find the page you're looking for.</p>
                    <a href="/">Back</a>
                </div>
            </main>
        </div>
    )
}
