import React from 'react'

export default function LoadingTextButton({ title }) {
	return (
		<button type="button" className='cursor-default flex items-center gap-2'>
			<span className="text-saving-loader"></span>
			{title}...
		</button>
	)
}
