import React, { useCallback, useContext, useEffect } from 'react'
import UseHelmet from '../hooks/UseHelmet';
import { getLocalStorage } from '../hooks/useLocalStorage';
import { useDispatch, useSelector } from 'react-redux';
import { get_loaded_orders } from '../../redux/thunk/orders/orderThunk';
import { useLayoutEffect } from 'react';
import { parentContext } from '../App';
import { set_is_order_modal } from '../../redux/globals/actions';
import { ModalPlusIcon } from '../helpers/icons/Icons';
import OrdersTable from '../components/orders/Table/OrdersTable';

export default function Orders() {

    const { setModalIndex } = useContext(parentContext);
    const dispatch = useDispatch();
    const storeID = getLocalStorage("activeStore");
    const { isOrderModalOpen } = useSelector(state => state.global);

    // loaded orders
    const memoizedDispatch = useCallback(() => {
        dispatch(get_loaded_orders(storeID));
    }, [storeID, dispatch]);

    useLayoutEffect(() => {
        memoizedDispatch();
        // eslint-disable-next-line
    }, [storeID, memoizedDispatch]);

    useEffect(() => {
        setModalIndex(isOrderModalOpen);
        // eslint-disable-next-line
    }, [isOrderModalOpen]);


    return (
        <>
            <UseHelmet title="Orders" />
            <main className={`flex-1 z-0 overflow-y-auto bg-backgroundGray p-8`}>
                <div className="max-w-7xl mx-auto">
                    <div className="section-heading pb-0  border-b-none flex items-center justify-center">
                        <div className="title">
                            <h2>
                                <span>All Orders</span>
                            </h2>
                        </div>
                        <div className="actions">
                            <button type="button" onClick={() => dispatch(set_is_order_modal(true))} className="btn btn-transparent btn-sm" data-turbo-frame="modal" data-controller="link">
                                <ModalPlusIcon />
                                <span>Add Order</span>
                            </button>
                        </div>
                    </div>
                    <OrdersTable />
                </div>
            </main>
        </>
    )
}
