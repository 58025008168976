import React from 'react'
import { useState } from 'react';
import ProductSkeleton from '../../components/skeletonLoader/ProductSkeleton';
import UseHelmet from '../../hooks/UseHelmet';
import Feed from './tabs/Feed';
import Chat from './tabs/Chat';
import UseTabs from '../../helpers/UseTabs';

export default function Support() {
  const [activeTab, setActiveTab] = useState("chat")
  const tabHeader = [{ id: "chat", name: "Live Chat" }, { id: "feed", name: "Facebook Feed" }];
  // change the active tab
  const tabChange = e => {
    setActiveTab(e.target?.id);
  }

  return (
    <ProductSkeleton>
      <main className="flex-1 z-0 overflow-y-auto bg-backgroundGray p-8">
        <UseHelmet title="Support" />
        <div className="max-w-7xl mx-auto">
          <div className="section-heading borderless ">
            <div className="title">
              <h2>Supports</h2>
            </div>
            <div className="actions">

            </div>
          </div>
          <div data-controller="table">
            <div className="table-wrapper">
              <UseTabs activeTab={activeTab} tabChange={tabChange} allTabs={tabHeader} />

              <div className="px-6 my-8" style={{ minHeight: "300px" }}>
                {activeTab === "chat" && <Chat />}
                {activeTab === "feed" && <Feed />}
              </div>
            </div>
          </div>
        </div>
      </main>
    </ProductSkeleton>
  )
}
