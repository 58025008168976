import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import useAuth from '../contexts/useAuth';
import UserProfile from './UserProfile';
import FormatePhoneNumber from '../helpers/FormatePhoneNumber';
import { useDispatch, useSelector } from 'react-redux';
import { added_all_stores, added_available_order_status, added_full_name, added_is_store, added_user_info } from '../../redux/user/actions';

export default function UserSidebarBottom({ userSidebarToggle, setUserSidebarToggle }) {

    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.userInfo);
    const { full_name, mobile } = user;
    const [profileShow, setProfileShow] = useState(true);
    const { logout } = useAuth();

    /**
     * Handles the logout functionality.
     * @return {void}
     */
    const handleLogout = () => {
        logout();
        dispatch(added_all_stores(null));
        dispatch(added_is_store(false));
        dispatch(added_user_info(null));
        dispatch(added_full_name(null));
        dispatch(added_available_order_status(null));
    }

    return (
        <>
            <div className="sidebar-user">
                <div className="dropdown flex-1 w-full" data-controller="dropdown">
                    <button onClick={() => setUserSidebarToggle(!userSidebarToggle)} type="button" className="btn btn-transparent w-full" aria-expanded="true" aria-haspopup="true" data-dropdown-target="button" data-action="dropdown#toggleMenu">
                        <span className="avatar h-10 w-10"><span className="text-sm font-medium leading-none text-primaryGray">
                            {
                                user?.is_premium && user?.plan_type === 'premium_plan' && user?.plan_status === 'premium' ? <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22px"
                                    height="22px"
                                    fill="#DC143C"
                                    version="1.1"
                                    viewBox="0 0 370.04 370.04"
                                    xmlSpace="preserve"
                                >
                                    <g>
                                        <path d="M341.668 314.412l-48.438-83.248c8.382-2.557 17.311-4.815 21.021-11.221 6.183-10.674-4.823-28.184-1.933-39.625 2.977-11.775 20.551-21.964 20.551-33.933 0-11.661-18.169-25.284-21.148-36.99-2.91-11.439 8.063-28.968 1.86-39.629-6.203-10.662-26.864-9.786-35.369-17.97-8.751-8.422-8.724-29.028-19.279-34.672-10.598-5.665-27.822 5.784-39.589 3.072C207.711 17.515 197.318 0 185.167 0c-12.331 0-31.944 19.868-35.02 20.583-11.761 2.734-29.007-8.687-39.594-2.998-10.545 5.663-10.48 26.271-19.215 34.707-8.491 8.199-29.153 7.361-35.337 18.035-6.183 10.672 4.823 28.178 1.934 39.625-2.897 11.476-21.083 23.104-21.083 36.376 0 11.97 17.618 22.127 20.613 33.896 2.911 11.439-8.062 28.966-1.859 39.631 3.377 5.805 11.039 8.188 18.691 10.479.893.267 2.582 1.266 1.438 2.933l-47.37 81.755c-3.352 5.784-.63 10.742 6.047 11.023l32.683 1.363c6.677.281 15.053 5.133 18.617 10.786l17.44 27.674c3.564 5.653 9.219 5.547 12.57-.236 0 0 48.797-84.246 48.817-84.27.979-1.144 1.963-.909 2.434-.509 5.339 4.546 12.782 9.081 18.994 9.081 6.092 0 11.733-4.269 17.313-9.03.454-.387 1.559-1.18 2.367.466.013.026 48.756 83.811 48.756 83.811 3.36 5.776 9.016 5.874 12.569.214l17.391-27.707c3.554-5.657 11.921-10.528 18.598-10.819l32.68-1.424c6.674-.293 9.387-5.258 6.027-11.033zM239.18 238.631c-36.136 21.023-79.511 18.77-112.641-2.127-48.545-31.095-64.518-95.419-35.335-145.788 29.516-50.95 94.399-68.928 145.808-40.929.27.147.537.299.805.449.381.211.761.425 1.14.641 15.86 9.144 29.613 22.415 39.461 39.342 30.098 51.736 12.497 118.314-39.238 148.412z"></path>
                                        <path d="M230.916 66.103c-.15-.087-.302-.168-.452-.254-27.462-15.894-62.464-17.056-91.799.011-43.532 25.326-58.345 81.345-33.019 124.876 7.728 13.284 18.318 23.888 30.536 31.498a93.462 93.462 0 003.164 1.927c43.579 25.247 99.568 10.333 124.814-33.244 25.245-43.579 10.335-99.567-33.244-124.814zm10.902 71.241l-15.259 14.873c-4.726 4.606-7.68 13.698-6.563 20.203l3.602 21.001c1.116 6.505-2.75 9.314-8.592 6.243l-18.861-9.916c-5.842-3.071-15.401-3.071-21.243 0l-18.86 9.916c-5.842 3.071-9.709.262-8.593-6.243l3.602-21.001c1.116-6.505-1.838-15.597-6.564-20.203l-15.258-14.873c-4.727-4.606-3.249-9.152 3.282-10.102l21.086-3.064c6.531-.949 14.265-6.568 17.186-12.486l9.43-19.107c2.921-5.918 7.701-5.918 10.621 0l9.431 19.107c2.921 5.918 10.654 11.537 17.186 12.486l21.086 3.064c6.53.95 8.007 5.496 3.281 10.102z"></path>
                                    </g>
                                </svg>
                                    :
                                    full_name.toUpperCase().slice(0, 1)
                            }
                        </span>
                        </span>
                        <div className="btn-content">
                            <span className='text-sm'>{full_name}</span>
                            <span>{FormatePhoneNumber(mobile)}</span>
                        </div>

                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" role="img" className="icon h-4 w-5">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                                <g transform="translate(1.000000, 1.000000)" stroke="currentColor" strokeWidth="2">
                                    <polyline id="Path" transform="translate(7.000000, 3.500000) rotate(180.000000) translate(-7.000000, -3.500000) " points="14 0 7 7 0 0"></polyline>
                                    <polyline id="Path" points="14 15 7 22 0 15"></polyline>
                                </g>
                            </g>
                        </svg>
                    </button>
                    <div className={`absolute z-10 mt-2 flex flex-col rounded-md bg-primary p-4 gap-3 shadow-lg lg:w-full sm:w-52 origin-bottom-right left-full ml-2 bottom-3  responsive-bottom-toggle-box ${userSidebarToggle ? "!hidden" : ""}`} role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1" data-dropdown-target="menu">
                        <span className="uppercase">My Profile</span>
                        <p onClick={() => setProfileShow(!profileShow)} className="flex items-center gap-2 cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" role="img" className="icon h-5 w-5">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path>
                            </svg>
                            <span className='text-black'>Edit Profile</span>
                        </p>
                        <Link onClick={handleLogout} className='flex items-center gap-2' to="/">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" role="img" className="icon h-6 w-6">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path>
                            </svg>
                            Logout
                        </Link>
                    </div>
                </div>
            </div>
            <UserProfile profileShow={profileShow} setProfileShow={setProfileShow} />
        </>
    )
}
