import { Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react'
import { parentContext } from '../App';
import { getLocalStorage, setLocalStorage } from '../hooks/useLocalStorage';
import useAxios from '../hooks/useAxios';
import UseHelmet from '../hooks/UseHelmet';
import { validateNumber } from '../hooks/validateNumber';
import StoreSkeleton from '../components/skeletonLoader/StoreSkeleton';
import * as Yup from "yup";
import UseTabs from '../helpers/UseTabs';
import toast from 'react-hot-toast';
import StuffTab from '../components/stores/StuffTab';
import Swal from 'sweetalert2';
import { PreLoaders } from '../helpers';
import { EyeCloseIcon, EyeOpenIcon } from '../helpers/icons/Icons';
import Website from '../components/stores/Website';
import RestaurantSettingTab from './RestaurantSettingsTab/RestaurantSettingTab';

const allTabs = [
    {
        id: "general",
        name: "General"
    },
    {
        id: "stuff",
        name: "Stuff"
    },
    // {
    //     id: "website",
    //     name: "Website"
    // },
    // {
    //     id: "sms",
    //     name: "SMS"
    // },
    {
        id: "store",
        name: "Store Settings"
    },
    {
        id: "restaurantSettings",
        name: "Restaurant Settings"
    },

]

export default function StoreEdit() {
    const axios = useAxios();
    const { setProgress } = useContext(parentContext);
    const [loading, setLoading] = useState(false)
    const [afterLoading, setAfterLoading] = useState(false);
    const [showToken, setShowToken] = useState(false);
    const [getPhone, setGetPhone] = useState('');
    const [getWalkPhone, setGetWalkPhone] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [walkPhoneError, setWalkPhoneError] = useState('');
    const [allStuff, setAllStuff] = useState([]);
    const [activeTab, setActiveTab] = useState("general");
    const active_store_id = localStorage.getItem("activeStore");
    const [storeData, setStoreData] = useState({});
    const data = getLocalStorage("storeInfo");

    /**
     * Load store information
     */
    useEffect(() => {
        if (data) {
            setStoreData({
                id: data?.id,
                name: data?.name,
                address: data?.address,
                phone: data?.phone,
                post_code: data?.post_code,
                walk_in_customer_name: data?.walk_in_customer_name,
                walk_in_customer_phone: data?.walk_in_customer_phone,
                wa_number: data?.wa_number,
                wa_access_token: data?.wa_access_token,
                store_type: data?.store_type,
            });
            setAllStuff(data?.store_stuff || [])
        }
        // eslint-disable-next-line
    }, [afterLoading, active_store_id])

    useEffect(() => {
        if (!data) {
            const loadStore = async () => {
                setProgress(30);
                const response = await axios("/stores/all");
                const data = response.data[0];
                setStoreData({ id: data?.id, name: data?.name, address: data?.address, phone: data?.phone, post_code: data?.post_code, walk_in_customer_name: data?.walk_in_customer_name, walk_in_customer_phone: data?.walk_in_customer_phone });
                setAllStuff(data?.store_stuff || [])
                setProgress(60);
                setProgress(100);
            }
            loadStore();
        }
        // eslint-disable-next-line
    }, []);

    /**
     * Handle tab change
     */
    const tabChange = e => {
        setActiveTab(e.target?.id);
    }

    const handleToggleToken = () => {
        setShowToken(!showToken);
    }

    /**
    * validate phone number
    */
    useEffect(() => {
        if (getPhone.length > 1 && !validateNumber(getPhone)) {
            setPhoneError("Invalid phone number");
        } else {
            setPhoneError('');
        }
    }, [getPhone]);

    useEffect(() => {
        if (getWalkPhone.length > 1 && !validateNumber(getWalkPhone)) {
            setWalkPhoneError("Invalid phone number");
        } else {
            setWalkPhoneError('');
        }
    }, [getWalkPhone])

    /**
    * validation schema for store fields
    */
    const validationSchema = Yup.object().shape(
        {
            name: Yup.string().required("Store name is required!"),
            address: Yup.string().min(6, "Enter a valid address!").max(50, "Enter a valid address!"),
            phone: Yup.number().required("Phone number is required!"),
            post_code: Yup.string().min(4, "Enter a valid post code!").max(4, "Enter a valid post code!"),
            walk_in_customer_name: Yup.string().min(2, "Enter a valid name!").max(50, "Enter a valid name!"),
            walk_in_customer_phone: Yup.number(),
        }
    );

    /**
     * Phone number handler
     */
    function handlePhoneValidate(event) {
        const restrictedKeys = /Enter|Backspace|Tab|ArrowRight|ArrowLeft|a|Delete|Home|Tab|End/;
        const isKeyRestricted = restrictedKeys.test(event.key);

        if (event.target.value.length >= 11 && !isKeyRestricted) {
            event.preventDefault();
        }
    }

    const handlePhoneNumber = e => {
        setGetPhone(e.target.value)
    }

    const handleWalkPhoneNumber = e => {
        setGetWalkPhone(e.target.value)
    }

    /**
     * Store and Stuff Delete Handler
     */
    const handleDelete = async (id) => {
        if (id === String(117)) {
            toast.error("Demo account can't be updated");
            return;
        }
        const response = await axios.post(`/stores/delete`, {
            id
        });

        const { status } = response.data;

        if (status) {
            toast.success("Deleted store");

            localStorage.removeItem("storeInfo");
            localStorage.removeItem("activeStore");
            const allStore = getLocalStorage("allStores");
            // if any store in local storage then save the first store in local storage
            if (allStore) {
                setLocalStorage("storeInfo", allStore[0]);
            } else {
                setLocalStorage("storeInfo", null);
            }
            window.location.reload();

        } else {
            toast.error("Failed to delete store.");
        }
    }

    const handleStuffDelete = async (id) => {
        const response = await axios.post(`/stores/stuff/delete`, {
            stuff_id: id,
            store_id: active_store_id
        });
        const { status } = response.data;
        if (status) {
            setAllStuff(allStuff.filter((item) => item.id !== id));
            toast.success("Deleted stuff");
        } else {
            toast.error("Failed to delete stuff.");
        }
    }

    const handleDeleteConfirmation = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Want to delete this store!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        }).then((result) => {
            if (result.isConfirmed) {
                handleDelete(id);
            }
        });
    };

    const initialStoreSettings = {
        products: {
            tags: {
                label: 'Tags',
                desc: 'Enabling this will hide the tags management from products section.',
                value: data.store_settings?.products_tags ?? false,
            },
            categories: {
                label: 'Categories',
                desc: '',
                value: data.store_settings?.products_categories ?? false,
            },
            product_images: {
                label: 'Product Images',
                desc: '',
                value: data.store_settings?.products_product_images ?? false,
            },
            production_cost: {
                label: 'Production Cost',
                desc: '',
                value: data.store_settings?.products_production_cost ?? false,
            },
            shipping_cost: {
                label: 'Shipping Cost',
                desc: '',
                value: data.store_settings?.products_shipping_cost ?? false,
            },
            manage_stocks: {
                label: 'Manage Stocks',
                desc: '',
                value: data.store_settings?.products_manage_stocks ?? false,
            },
            manage_charges: {
                label: 'Manage Charges',
                desc: '',
                value: data.store_settings?.products_manage_charges ?? false,
            },
        },
        orders: {
            manage_order_stuff: {
                label: 'Manage Order Stuff',
                desc: '',
                value: data.store_settings?.orders_manage_order_stuff ?? false,
            },
        },
        customer: {
            manage_social_links: {
                label: 'Manage Social Links',
                desc: '',
                value: data.store_settings?.customer_manage_social_links ?? false,
            },
        }
    }

    const [storeSettings, setStoreSettings] = useState(initialStoreSettings);

    const handleToggle = (settingSection, key) => {
        setStoreSettings(prevState => ({
            ...prevState,
            [settingSection]: {
                ...prevState[settingSection],
                [key]: {
                    ...prevState[settingSection][key],
                    value: !prevState[settingSection][key].value
                }
            }
        }));
    };

    const handleStoreTypeChange = (response) => {
        console.log({
            // response.target.name,
            // response.target.value
        });
    };

    // RestaurantSettings related 
    const RestaurantSettings = () => {
        const initialRestaurantSettings = {
            ManageQR: {
                manage_qr_ordering: {
                    label: 'Manage QR Ordering',
                    desc: '',
                    value: false,
                },
            }
        }
        const [restaurantSettings, setRestaurantSettings] = useState(initialRestaurantSettings);

        const handleToggle = (settingSection, key) => {
            setRestaurantSettings(prevState => ({
                ...prevState,
                [settingSection]: {
                    ...prevState[settingSection],
                    [key]: {
                        ...prevState[settingSection][key],
                        value: !prevState[settingSection][key].value
                    }
                }
            }));
        };
        const renderToggle = (settingSection, key, item) => (
            <div className="my-3" key={key}>
                <label className="flex gap-6 items-center cursor-pointer">
                    <span className="">{item.label}</span>
                    <span className="relative inline-flex items-center cursor-pointer">
                        <input
                            type="checkbox"
                            className="sr-only peer"
                            checked={item.value}
                            onChange={() => handleToggle(settingSection, key)}
                        />
                        <span className="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#2D986B] mr-10"></span>
                    </span>
                </label>
                <p className="text-sm text-primary-500/100">{item.desc}</p>
            </div>
        );
        return (
            <div>
                {/* <div className="grid grid-cols-2">
                    {Object.entries(restaurantSettings).map(([settingSection, settingItems]) => (
                        <div key={settingSection} className="mb-4 flex-1">
                            <h2 className="text-xl font-semibold capitalize mb-4">{settingSection}</h2>
                            {Object.entries(settingItems).map(([key, item]) => (
                                renderToggle(settingSection, key, item)
                            ))}
                        </div>
                    ))}
                </div> */}
                <div>
                    <RestaurantSettingTab setAllStuff={setAllStuff} allStuff={allStuff} handleDelete={handleStuffDelete}></RestaurantSettingTab>
                    {/* <StuffTab setAllStuff={setAllStuff} allStuff={allStuff} handleDelete={handleStuffDelete} /> */}
                </div>
            </div>
        );
    };

    const StoreTabSettings = () => {
        const renderToggle = (settingSection, key, item) => (
            <div className="my-6" key={key}>
                <label className="flex justify-between items-center cursor-pointer">
                    <span className="">{item.label}</span>
                    <span className="relative inline-flex items-center cursor-pointer">
                        <input
                            type="checkbox"
                            className="sr-only peer"
                            checked={item.value}
                            onChange={() => handleToggle(settingSection, key)}
                        />
                        <span className="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#2D986B] mr-10"></span>
                    </span>
                </label>
                <p className="text-sm text-primary-500/100">{item.desc}</p>
            </div>
        );

        return (
            <div className="p-2 grid grid-cols-2">
                {Object.entries(storeSettings).map(([settingSection, settingItems]) => (
                    <div key={settingSection} className="mb-4 flex-1">
                        <h2 className="text-xl font-semibold capitalize mb-4">{settingSection}</h2>
                        {Object.entries(settingItems).map(([key, item]) => (
                            renderToggle(settingSection, key, item)
                        ))}
                    </div>
                ))}
            </div>
        );
    };

    const transformStoreSettings = (settings) => {
        const transformedSettings = {};
        for (const [sectionKey, sectionValue] of Object.entries(settings)) {
            for (const [settingKey, settingObj] of Object.entries(sectionValue)) {
                const newKey = `${sectionKey}_${settingKey}`;
                transformedSettings[newKey] = settingObj.value;
            }
        }
        return transformedSettings;
    };

    /**
     * Update store information
     * @param {*} value
     * */
    const handleFormSubmit = async (value) => {
        setLoading(true);
        // check is demo store or not
        if (active_store_id === String(117)) {
            setLoading(false);
            toast.error("Demo account can't be updated");
            return;
        }
        // update the store information
        if (!value.phone.includes(0)) {
            setLoading(false);
            toast.error("Invalid phone number!");
        }

        if (value.phone.length === 11) {
            const response = await axios.post("/stores/update",
                {
                    id: active_store_id,
                    name: value.name,
                    address: value.address,
                    phone: value.phone,
                    post_code: value.post_code,
                    walk_in_customer_name: value.walk_in_customer_name,
                    walk_in_customer_phone: value.walk_in_customer_phone,
                    wa_number: data?.wa_number,
                    wa_access_token: data?.wa_access_token,
                    settings: transformStoreSettings(storeSettings),
                    store_type: value.store_type,
                },
            );

            // save the locally store information
            const { status } = response.data;
            if (status) {
                setLocalStorage("storeInfo", value);
                setLoading(false);
                toast.success("This store has been updated!");
            } else {
                setLoading(false);
                toast.success("Something went wrong");
            }
        }
    }

    return (
        <StoreSkeleton setAfterLoading={setAfterLoading}>
            <main className="flex-1 z-0 overflow-y-auto bg-backgroundGray p-8">
                {loading ? <PreLoaders /> : null}
                <UseHelmet title="Settings" />
                <Formik onSubmit={handleFormSubmit} validationSchema={validationSchema} initialValues={storeData}>
                    {({ values, touched, errors, handleBlur, handleChange, handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="max-w-7xl mx-auto">
                                <div className="flex justify-between items-center mb-[1.25rem]">
                                    <div className="title">
                                        <h2 className='text-[18px] flex items-center justify-center'>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" role="img" className="icon h-7 w-7 d-inline mr-1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z"></path>
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                                            </svg>
                                            <span>Settings</span>
                                        </h2>
                                    </div>
                                    <div className="actions">
                                        {phoneError?.includes("Invalid") ? <button type="button" disabled className="btn btn-primary btn-md">Save Changes</button> : <button type="submit" className="btn btn-primary btn-md">Save Changes</button>}
                                    </div>
                                </div>

                                <div className='sm-relative'>
                                    <div className="table-wrapper">
                                        <UseTabs activeTab={activeTab} tabChange={tabChange} allTabs={allTabs} />

                                        {
                                            activeTab === "general" &&
                                            <div className="form-section-wrapper rounded-none border-none">
                                                <div className="form-section remove-spacex-responsive">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Store Name</label>
                                                        <input autoComplete="off" placeholder="Store name" className="form-control" type="text" value={values?.name || ""} name="name" id='name' onBlur={handleBlur} onChange={handleChange} />
                                                        {touched.name && errors.name && <div className='form-error'>{errors.name}</div>}
                                                    </div>
                                                    <div className="grid grid-cols-2 space-x-4 remove-grid-responsive">
                                                        <div className="form-group">
                                                            <label htmlFor="store_id">Store ID</label>
                                                            <input autoComplete="off" placeholder="XXXX" id='store_id' className="form-control" disabled type="text" value={active_store_id || ""} name="id" />
                                                        </div>

                                                        <div className="form-group">
                                                            <label htmlFor="phone">Phone Number</label>
                                                            <input autoComplete="off" placeholder="01XXXXXXXXX" className="form-control" type="text" value={values.phone || ""} id='phone' name="phone" onBlur={handleBlur} onChange={(e) => { handleChange(e); handlePhoneNumber(e) }} onKeyDown={(e) => handlePhoneValidate(e)} />
                                                            {getPhone.length > 1 && phoneError && <div className='form-error'>{phoneError}</div>}
                                                        </div>
                                                    </div>

                                                    <div className="grid grid-cols-2 space-x-4 remove-grid-responsive">
                                                        <div className="form-group">
                                                            <label htmlFor="postcode">Post Code</label>
                                                            <input autoComplete="off" placeholder="Post code" id='postcode' className="form-control" type="number" value={values.post_code || ""} name="post_code" onBlur={handleBlur} onChange={handleChange} />
                                                            {touched.post_code && errors.post_code && <div className='form-error'>{errors.post_code}</div>}
                                                        </div>

                                                        <div className="form-group">
                                                            <label htmlFor="address">Store Address</label>
                                                            <input autoComplete="off" placeholder="Store address" id='address' className="form-control" type="text" value={values.address || ""} name="address" onBlur={handleBlur} onChange={handleChange} />
                                                            {touched.address && errors.address && <div className='form-error'>{errors.address}</div>}
                                                        </div>
                                                    </div>

                                                    <div className="grid grid-cols-2 space-x-4 remove-grid-responsive">
                                                        <div className="form-group">
                                                            <label htmlFor="store_type">Store Type</label>
                                                            <select onChange={(e) => handleStoreTypeChange(e)} defaultValue='general' className="form-control" data-controller="select" id='store_type' value={values.store_type} data-action="change->select#change">
                                                                <option value={'general'}>General</option>
                                                                <option value={'restaurant'}>Restaurant</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    {/* whatsapp settings */}
                                                    <div className="pb-0 border-b-none mt-8 ps-2 hidden">
                                                        <div className="title">
                                                            <h2 className='text-xl font-semibold'>
                                                                <span>Whatsapp Settings</span>
                                                            </h2>
                                                        </div>
                                                        <div className="grid grid-cols-2 space-x-4 remove-grid-responsive py-5">
                                                            <div className="form-group">
                                                                <label htmlFor="wa_number">Whatsapp Number</label>
                                                                <input autoComplete="off" placeholder="01XXXXXXXXX" className="form-control" type="text" value={values?.wa_number || ""} name="wa_number" id='wa_number' onBlur={handleBlur} onChange={handleChange} onKeyDown={(e) => handlePhoneValidate(e)} />
                                                                {touched.wa_number && errors.wa_number && <div className='form-error'>{errors.wa_number}</div>}
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="wa_access_token">Access Token</label>
                                                                <div className='relative'>
                                                                    <input autoComplete="off" placeholder="*************" className="form-control" type={showToken ? "text" : "password"} value={values?.wa_access_token || ""} name="wa_access_token" id='wa_access_token' onBlur={handleBlur} onChange={(e) => handleChange(e)} />
                                                                    {
                                                                        !showToken ? <EyeCloseIcon handleToggleIcon={handleToggleToken} /> : <EyeOpenIcon handleToggleIcon={handleToggleToken} />
                                                                    }

                                                                </div>
                                                                {touched.wa_access_token && errors.wa_access_token && <div className='form-error'>{errors.wa_access_token}</div>}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* walk in customer */}
                                                    <div className="pb-0 border-b-none mt-4 ps-2 hidden">
                                                        <div className="title">
                                                            <h2 className='text-xl font-semibold'>
                                                                <span>Walk in customer</span>
                                                            </h2>
                                                        </div>
                                                        <div className="grid grid-cols-2 space-x-4 remove-grid-responsive py-5">
                                                            <div className="form-group">
                                                                <label htmlFor="walk_name">Customer Name</label>
                                                                <input autoComplete="off" placeholder="Customer name" className="form-control" type="text" value={values?.walk_in_customer_name || ""} name="walk_in_customer_name" id='walk_name' onBlur={handleBlur} onChange={handleChange} />
                                                                {touched.walk_in_customer_name && errors.walk_in_customer_name && <div className='form-error'>{errors.walk_in_customer_name}</div>}
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="walk_phone">Phone Number</label>
                                                                <input autoComplete="off" placeholder="01XXXXXXXXX" className="form-control" type="text" value={values?.walk_in_customer_phone || ""} name="walk_in_customer_phone" id='walk_phone' onBlur={handleBlur} onChange={(e) => { handleChange(e); handleWalkPhoneNumber(e) }} onKeyDown={(e) => handlePhoneValidate(e)} />
                                                                {getWalkPhone.length > 1 && walkPhoneError && <div className='form-error'>{walkPhoneError}</div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {activeTab === "website" && <Website storeData={storeData} />}

                                        {/* {activeTab === "sms" && <div className='py-6 px-6 form-section-wrapper rounded-none border-none'>Coming soon...</div>} */}

                                        {/* store related tab (button group) */}
                                        {activeTab === "store" && <div className='py-6 px-6 form-section-wrapper rounded-none border-none'>
                                            <StoreTabSettings></StoreTabSettings>
                                        </div>}

                                        {/* store related tab (button group) */}
                                        {activeTab === "restaurantSettings" && <div className='py-6 px-6 form-section-wrapper rounded-none border-none'>
                                            <RestaurantSettings></RestaurantSettings>

                                        </div>}
                                    </div>
                                    {
                                        activeTab === "general" &&
                                        <>

                                            {/* delete store */}
                                            <div className="rounded-lg bg-white border mt-10">
                                                <div className="px-4 py-5 sm:p-6 flex flex-col">
                                                    <h3 className="text-lg font-semibold mb-1">Danger Zone</h3>
                                                    <p className="mb-3">
                                                        No longer using your store? Once you delete a store there is no going back. Please be certain.
                                                    </p>
                                                    <div className="flex">
                                                        <button type='button' className="!text-red-600 btn btn-white btn-md" onClick={() => handleDeleteConfirmation(active_store_id)}>Delete Store</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
                <div className="max-w-7xl mx-auto">
                    <div className='border-b border-r border-l border-t-0 sm-relative'>
                        {activeTab === "stuff" && <StuffTab setAllStuff={setAllStuff} allStuff={allStuff} handleDelete={handleStuffDelete} />}
                    </div>
                </div>
            </main>
        </StoreSkeleton>
    )
}