import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom';
import { parentContext } from '../App';
import { IsStore } from '../hooks/IsStore';
import { validateNumber } from '../hooks/validateNumber';
import { GetCookie, SetCookie } from '../hooks/Cookies';
import { useLayoutEffect } from 'react';
import { useAuthAxios } from './useAuthAxios';
import useAxios from '../hooks/useAxios';
import { getLocalStorage, setLocalStorage } from '../hooks/useLocalStorage';
import { UseHelmet } from '../hooks';
import { EyeCloseIcon, EyeOpenIcon } from '../helpers/icons/Icons';

export default function Login() {
    const axios = useAuthAxios();
    const loggedInAxios = useAxios();
    const navigate = useNavigate();
    const [error, setError] = useState("");
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const { setProgress } = useContext(parentContext);
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // phone number strick validation 
    function handlePhoneNumber(event) {
        const restrictedKeys = /Enter|Backspace|Tab|ArrowRight|ArrowLeft|Ctrl|c|a|x|v|Delete|Home|Tab|End/;
        const isKeyRestricted = restrictedKeys.test(event.key);

        if (event.target.value.length >= 11 && !isKeyRestricted) {
            event.preventDefault();
        }
    }

    /**
     * Handle pin number event.
     *
     * @param {Event} event - The event object.
     */
    const handlePinNumber = (event) => {
        //allow only numbers
        const restrictedKeys = /Enter|Backspace|Tab|ArrowRight|ArrowLeft|Ctrl|c|a|x|v|Delete|Home|Tab|End/;
        const isKeyRestricted = restrictedKeys.test(event.key);
        if (!isKeyRestricted && isNaN(Number(event.key))) {
            event.preventDefault();
        }
    }

    /**
     * Toggles the visibility of the password.
     *
     * @return {void} 
     */
    const handleTogglePin = () => {
        setShowPassword(!showPassword);
    }

    // realtime mobile number validation
    useEffect(() => {
        if (watch().phone?.length > 0 && !validateNumber(watch().phone)) {
            setError("Invalid phone number");
        } else {
            setError('');
        }
        // eslint-disable-next-line
    }, [watch().phone])

    /**  
     * check user logged in or not
     * if user logged in, navigate to dashboard
     * if user not logged in, navigate to login
    */
    useLayoutEffect(() => {
        // check auth true or not
        const checkCookie = atob(GetCookie("ask"));

        // if auth true then navigate to dashboard
        if (checkCookie) {
            setIsLoading(true);
            setProgress(40)
            // check user valid auth or not
            const checkUserAuth = async () => {
                const response = await loggedInAxios.post("/users");
                setProgress(60);
                if (response.data.status === true) {
                    setIsLoading(false);
                    setProgress(100);
                    const userType = getLocalStorage("utype") && atob(getLocalStorage("utype"));
                    if (userType === "reseller") {
                        navigate("/reseller/dashboard");
                    } else {
                        navigate("/dashboard");
                    }
                } else {
                    setIsLoading(false);
                    setProgress(100);
                    // clear all local storage
                    localStorage.clear();
                    // clear ask cookies
                    SetCookie('ask', '', -1);
                    setProgress(100);
                }
            }
            checkUserAuth();
            setProgress(100);
        }
        // eslint-disable-next-line
    }, [])

    /**
     * when submit form data and check user logged in or not
     * @param data
     * @returns
     */
    const onSubmit = async data => {
        setProgress(30);
        const mobile = data?.phone;
        const pin = data?.password;
        const validatePhone = validateNumber(data.phone);

        try {
            /**  
             * check phone number is valid or not 
             * if valid then login
             * */
            if (validatePhone) {
                // send to login request 
                const response = await axios.post(`/users/login`, { mobile, pin });
                setProgress(60);

                // if logged in user navigate to dashboard
                const { status, data } = response.data;
                const { type, hash, error } = response.data?.data;

                // check login error 
                if (error) {
                    setProgress(100);
                    setError("User not found");
                }

                /** when status 
                  * TRUE then navigate to dashboard
                */
                if (status === true) {
                    localStorage.setItem("authentication", true);
                    // Call the cookie function to set the cookie that expires in 7 days
                    SetCookie('ask', btoa(hash), 7);
                    const hashUserType = btoa(type);
                    setLocalStorage("utype", hashUserType)
                    if (type === "reseller") {
                        window.location.replace("/reseller/dashboard");
                        setProgress(100);
                    }

                    /** 
                     * check if store exists or not
                     * if store exists then navigate to dashboard
                     * if store not exists then navigate to new store
                     * */

                    const isStore = await IsStore();
                    if (type !== "reseller" && isStore) {
                        window.location.replace("/dashboard");
                        setProgress(100);

                    } else if (type !== "reseller" && !isStore) {
                        setProgress(100);
                        navigate("/store/new");
                    }
                    // when status is false then show the error message
                } else {
                    if (data.error === "sms_verification_pending") {
                        localStorage.setItem("mobile", mobile);
                        navigate("/verify_otp");
                    }
                    setError(data.message)
                    setProgress(100);
                }
            }
            // if phone number is not valid then show error msg
            else {
                setProgress(100);
                setError("Enter Bangladeshi phone number")
            }

        } catch (e) {
            console.log(e.message);
        }
    }

    const handleDemoLogin = async () => {

        // send to login request 
        const response = await axios.post(`/users/login`, { mobile: '01605818600', pin: '18600' });
        setProgress(30);

        // if logged in user navigate to dashboard
        const { status, data } = response.data;
        const { hash, error } = response.data?.data;

        // check login error 
        if (error) {
            setProgress(100);
            setError("User not found");
        }

        /** when status 
          * TRUE then navigate to dashboard
        */
        if (status === true) {
            localStorage.setItem("authentication", true);
            // Call the cookie function to set the cookie that expires in 7 days
            SetCookie('ask', btoa(hash), 7);

            /** 
             * check if store exists or not
             * if store exists then navigate to dashboard
             * if store not exists then navigate to new store
             * */
            const isStore = await IsStore();
            if (isStore) {
                setProgress(100);
                navigate("/dashboard");
                window.location.reload();
            } else {
                setProgress(100);
                navigate("/store/new");
            }
            // when status is false then show the error message
        } else {
            if (data.error === "sms_verification_pending") {
                localStorage.setItem("mobile", "01605818600");
                navigate("/verify_otp");
            }
            setError(data.message)
            setProgress(100);
        }

    }

    return (
        <>
            <UseHelmet title="Login" />
            {
                !isLoading ? <section className='h-screen flex'>
                    <main className="w-full p-6 max-w-sm m-auto">
                        <img src="/assets/images/login/dokaani-en.svg" className="m-auto mb-8 h-10 w-auto" style={{ width: "160px" }} alt="dokaani logo" />
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="basic-heading space-y-1 mb-4 px-4">
                                <h2 className="text-xl text-center font-semibold">
                                    Login now
                                </h2>
                                {
                                    error &&
                                    <> <p className="form-group-error text-xl font-semibold text-center text-red-600 pt-2">{error && (!error.includes("pending") && error)}</p>
                                        <p className="form-group-error text-xl font-semibold text-center text-red-600 pt-2">{error && (error.includes("pending") && error)}</p>
                                    </>
                                }
                            </div>

                            <div className="form-group">
                                <label htmlFor="phone">Phone Number</label>
                                <input autoComplete="off" placeholder="01XXXXXXXXX" onKeyDown={handlePhoneNumber} {...register("phone", { required: true, maxLength: 11 })} maxLength={11} className="form-control" type="number" name="phone" id="phone" />
                                {errors.phone && <span className='form-error'>Enter a valid phone number.</span>}
                            </div>

                            <div className="form-group">
                                <label htmlFor="password">Pin Number</label>
                                <div className='relative'>
                                    <input autoComplete="off" placeholder="******" onKeyDown={handlePinNumber} {...register("password", { required: true, minLength: 5 })} className="form-control" type={showPassword ? "text" : "password"} name="password" id="password" />
                                    {
                                        !showPassword ? <EyeCloseIcon handleToggleIcon={handleTogglePin} /> : <EyeOpenIcon handleToggleIcon={handleTogglePin} />
                                    }
                                </div>
                                {errors.password && <span className='form-error'>PIN more than 5 character.</span>}
                            </div>

                            <button type="submit" className="py-3 px-4 bg-primary-500 hover:bg-primary-400 w-full text-white font-semibold tracking-wider rounded-md" data-controller="form-submit"><span>Sign In</span></button>
                        </form>
                        <div className="basic-links flex flex-col items-center gap-3 justify-center mt-4">
                            <Link to="/pin_reset"><span>Forget your PIN? Reset your PIN.</span></Link>
                            <Link to="/registration"><span>Don't have an account? Sign Up</span></Link>
                        </div>

                        {/* <div className="flex justify-center mt-5">
                            <button onClick={handleDemoLogin} className="border border-primary-400 hover:bg-primary-400 hover:text-white font-semibold py-2 px-4 rounded">
                                Demo Login
                            </button>
                        </div> */}
                    </main>
                </section>
                    :
                    //  loader
                    <div className="loader-container">
                        <div className="loader"></div>
                    </div>
            }

        </>
    )
}
