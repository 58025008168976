import authRoutes from "./Auth/AuthRoutes";
import SideBar from "./components/SideBar";
import AuthGuard from "./contexts/AuthGuard";
import NotMatch from "./Pages/404";
import PageRoutes from "./Pages/PageRoutes";

const routes = [
    {
        element: (
            <AuthGuard>
                <SideBar />
            </AuthGuard>
        ),
        children: PageRoutes
    },
    ...authRoutes,
    { path: "*", element: <NotMatch /> },
]
export default routes;