import React, { useState } from 'react'
import { UseHelmet } from '../../hooks';
import { UseTabs } from '../../helpers';
import General from '../../components/stuff/tabs/General';
import Packages from '../../components/stuff/tabs/Packages';

const allTabs = [
	{
		id: "general",
		name: "General"
	},
	{
		id: "packages",
		name: "Packages"
	}
]
export default function Settings() {
	const [activeTab, setActiveTab] = useState("general");

	const tabChange = e => {
		setActiveTab(e.target?.id);
	}

	return (
		<main className="flex-1 z-0 overflow-y-auto bg-backgroundGray p-8">
			<UseHelmet title="Settings" />
			<div className="max-w-7xl mx-auto">
				<div className="flex justify-between items-center mb-[1.25rem]">
					<div className="title">
						<h2 className='text-[18px] flex items-center justify-center'>
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" role="img" className="icon h-7 w-7 d-inline mr-1">
								<path strokeLinecap="round" strokeLinejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z"></path>
								<path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
							</svg>
							<span>Settings</span>
						</h2>
					</div>
					<div className="actions">
						{/* <button type="submit" className="btn btn-primary">Save Changes</button> */}
					</div>
				</div>

				<div className='sm-relative'>
					{/* all tabs */}
					<div className="table-wrapper !rounded-b-none !border-b-0">
						<UseTabs activeTab={activeTab} tabChange={tabChange} allTabs={allTabs} />
						<div className='py-6 px-6 form-section-wrapper rounded-none border-none'>
							{activeTab === "general" && <General />}
							{activeTab === "packages" && <Packages />}
						</div>
					</div>
				</div>
			</div>
			{/* <div className="max-w-7xl mx-auto">
				<div className='border-b border-r border-l sm-relative'>
					{activeTab === "stuff" && <StuffTab setAllStuff={setAllStuff} allStuff={allStuff} handleDelete={handleStuffDelete} />}
				</div>
			</div> */}
		</main>
	)
}
