import {
	ADDED_ALL_STORES,
	ADDED_AVAILABLE_ORDER_STATUS,
	ADDED_ERROR,
	ADDED_FULL_NAME,
	ADDED_IS_AUTH,
	ADDED_IS_STORE,
	ADDED_LOADING,
	ADDED_RESELLERS,
	ADDED_USER_INFO,
	ADDED_USER_TYPE,
} from "./actionType";

const initialState = {
	user: [],
	userType: "",
	full_name: "",
	availableOrderStatus: [],
	loading: true,
	error: null,
	isAuth: false,
	isStore: false,
	allStores: [],
	resellers: [],
};

export const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case ADDED_USER_TYPE:
			return {
				...state,
				userType: action.payload,
			};
		case ADDED_USER_INFO:
			return {
				...state,
				user: action.payload,
			};
		case ADDED_FULL_NAME:
			return {
				...state,
				full_name: action.payload,
			};
		case ADDED_AVAILABLE_ORDER_STATUS:
			return {
				...state,
				availableOrderStatus: action.payload,
			};
		case ADDED_LOADING:
			return {
				...state,
				loading: action.payload,
			};
		case ADDED_ERROR:
			return {
				...state,
				error: action.payload,
			};
		case ADDED_IS_AUTH:
			return {
				...state,
				isAuth: action.payload,
			};
		case ADDED_IS_STORE:
			return {
				...state,
				isStore: action.payload,
			};
		case ADDED_ALL_STORES:
			return {
				...state,
				allStores: action.payload,
			};
		case ADDED_RESELLERS:
			return {
				...state,
				resellers: action.payload,
			};
		default:
			return state;
	}
};
