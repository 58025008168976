import GeneralInvoice from "../components/orders/utils/GeneralInvoice";
import PosOrderInvoice from "../components/orders/utils/PosOrderInvoice";
import CheckOTP from "./CheckOTP";
import ForgotPassword from "./ForgotPassword";
import Login from "./Login";
import Registration from "./Registration";


const authRoutes = [
    { path: "/", element: <Login /> },
    { path: "/registration", element: <Registration /> },
    { path: "/verify_otp", element: <CheckOTP /> },
    { path: "/pin_reset", element: <ForgotPassword /> },
    { path: "/pos-invoice/:invoiceHash", element: <PosOrderInvoice /> },
    { path: "/general-invoice/:invoiceHash", element: <GeneralInvoice /> },
]

export default authRoutes;