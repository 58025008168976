import React, { createContext, useEffect, useReducer } from "react";
import { SetCookie } from "../hooks/Cookies";

const initialState = {
	isAuthenticated: false,
	isInitialized: false,
	user: null,
};

const setSession = (accessToken) => {
	if (accessToken) {
		localStorage.setItem("accessToken", accessToken);
	} else {
		localStorage.removeItem("accessToken");
	}
};

const reducer = (state, action) => {
	switch (action.type) {
		case "INIT": {
			const { loggedInformation } = action.payload;

			return {
				...state,
				isAuthenticated: true,
				isInitialized: true,
				user: loggedInformation,
			};
		}
		case "LOGIN": {
			const { loggedInformation } = action.payload;

			return {
				...state,
				isAuthenticated: true,
				isInitialized: true,
				user: loggedInformation,
			};
		}
		case "LOGOUT": {
			return {
				...state,
				isAuthenticated: false,
				user: null,
			};
		}
		case "REGISTER": {
			const { loggedInformation } = action.payload;

			return {
				...state,
				isAuthenticated: true,
				isInitialized: true,
				user: loggedInformation,
			};
		}
		default: {
			return { ...state };
		}
	}
};

const AuthContext = createContext({
	...initialState,
	method: "JWT",
	logout: () => {},
	register: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	// register auth
	const register = (data) => {
		// if (data) {
		// 	setSession(data.hash);
		// 	dispatch({
		// 		type: "LOGIN",
		// 		payload: {
		// 			user: data.hash,
		// 		},
		// 	});
		// 	return true;
		// } else {
		// 	return false;
		// }
		console.log(data);
		if (data) {
			const hash = data?.hash;
			setSession(hash);
			dispatch({
				type: "REGISTER",
				payload: {
					hash,
				},
			});
			return true;
		} else {
			return false;
		}
	};

	const logout = () => {
		setSession(null);
		localStorage.clear();
		//remove cookie
		SetCookie("ask", "", -1);
		dispatch({ type: "LOGOUT" });
	};

	useEffect(() => {
		(async () => {
			try {
				const accessToken = window.localStorage.getItem("accessToken");
				if (accessToken) {
					setSession(accessToken);
					dispatch({
						type: "INIT",
						payload: {
							isAuthenticated: true,
						},
					});
				} else {
					dispatch({
						type: "INIT",
						payload: {
							isAuthenticated: false,
							user: null,
						},
					});
				}
			} catch (err) {
				dispatch({
					type: "INIT",
					payload: {
						isAuthenticated: false,
						user: null,
					},
				});
			}
		})();
	}, []);

	return (
		<AuthContext.Provider
			value={{
				...state,
				method: "JWT",
				logout,
				register,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthContext;
