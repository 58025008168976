import useAxios from "../../../app/hooks/useAxios";
import {
	added_address,
	delete_address,
	delete_customer,
	get_edit_address,
	is_loading_customer,
	loaded_customers,
	set_addresses,
	set_highlighted_Row_ID,
} from "../../customers/actions/actions";
import {
	edit_customer_action,
	set_loading,
	set_order_history,
	set_social_link,
} from "../../customers/actions/customerEditActions";
import {
	added_address_id,
	added_address_name,
	added_full_address,
} from "../../orders/action";

/**
 * Get all customers
 * @param {int} store_id
 * @returns void
 */
export const get_loaded_customers = (store_id) => {
	return async (dispatch) => {
		const axios = useAxios();
		dispatch(is_loading_customer(true));
		const response = await axios.post("/customers/all", { store_id });
		const { data, status } = await response.data;

		// if user unauthorize
		if (status === 400) {
			localStorage.clear();
			window.location.href = "/";
			return;
		}

		if (data?.message) {
			dispatch(added_address([]));
		}
		dispatch(loaded_customers(data));
		dispatch(is_loading_customer(false));
	};
};

export const added_social_link = (store_id, customer_id) => {
	return async (dispatch) => {
		const axios = useAxios();
		const response = await axios.post(`/customers/profiles/all`, {
			store_id,
			customer_id,
		});
		if (response.data.status === true) {
			dispatch(set_social_link(response.data.data));
		}
	};
};
/**
 * Get customer
 * @param {int} store id
 * @param {*} id customer ID
 * @returns void
 */
export const get_edit_customer = (store_id, id) => {
	return async (dispatch) => {
		const axios = useAxios();
		dispatch(set_loading(true));
		const response = await axios.get(
			`/customers?store_id=${store_id}&id=${id}`
		);
		const { data } = await response.data;
		if (data.status === false) {
			dispatch(set_loading(false));
			window.location.href = "/customers";
			return;
		}

		dispatch(edit_customer_action(data));
		dispatch(set_order_history(data?.orders || []));
		await dispatch(added_social_link(store_id, id));
		dispatch(set_loading(false));
	};
};

/**
 * Get all customer addresses
 * @param {int} store_id
 * @param {int} customer_id
 * @returns void
 */
export const get_addresses = (
	store_id,
	customer_id,
	add_order_address = false,
	already_set_id = false
) => {
	return async (dispatch, getState) => {
		const axios = useAxios();
		const response = await axios.post(`/addresses/all`, {
			store_id,
			customer_id,
		});
		const { data } = await response.data;
		dispatch(set_addresses(data));

		if (already_set_id) {
			const addressID = getState().orders.addressID;
			if (data?.length > 0) {
				const getData = data.find((item) => item.id === addressID);
				const {
					label,
					street_address,
					sub_district,
					district,
					division,
					post_code,
				} = getData;
				dispatch(added_address_name(label));
				dispatch(
					added_full_address(
						street_address +
							", " +
							sub_district +
							", " +
							district +
							", " +
							division +
							", " +
							post_code
					)
				);
			}
		}

		if (add_order_address && !already_set_id) {
			if (data?.length > 0) {
				const {
					id,
					label,
					street_address,
					sub_district,
					district,
					division,
					post_code,
				} = data[0];
				dispatch(added_address_name(label));
				dispatch(added_address_id(id));
				dispatch(
					added_full_address(
						street_address +
							", " +
							sub_district +
							", " +
							district +
							", " +
							division +
							", " +
							post_code
					)
				);
			} else {
				dispatch(added_address_id(0));
				dispatch(added_full_address(""));
			}
		}
	};
};

export const delete_single_customer = (store_id, id) => {
	return async (dispatch) => {
		const axios = useAxios();
		const response = await axios.post(`/customers/delete`, { store_id, id });
		const { status } = await response.data;
		if (status) {
			dispatch(delete_customer(id));
			await dispatch(get_loaded_customers(store_id));
		}
	};
};

/**
 * Add new customer address
 * @param {int} store_id
 * @param {object} newAddressData
 * @param {int}   customer_id
 * @returns void
 */

export const add_new_address = (
	store_id,
	customer_id,
	newAddressData,
	add_order_address = false,
	order_id = null
) => {
	return async (dispatch) => {
		const axios = useAxios();
		const response = await axios.post(`/addresses/add`, {
			...newAddressData,
			store_id,
			customer_id,
		});
		const { data } = await response.data;
		dispatch(added_address(data));
		dispatch(added_address_id(data?.id));
		dispatch(set_highlighted_Row_ID(data?.id));
		// if add_order_address is true
		if (add_order_address) {
			const udpateAddress = await axios.post(`/orders/update`, {
				id: order_id,
				address_id: data?.id,
			});
		}
	};
};

/**
 * Get edit customer address
 * @param {*} address_id get address id
 * @returns void
 */
export const get_edited_address = (address_id) => {
	return async (dispatch) => {
		const axios = useAxios();
		const response = await axios.post(`/addresses/details`, { address_id });
		const { data } = await response.data;
		dispatch(get_edit_address(data[0]));
	};
};

/**
 * Saved Edit address
 * @param {*} address_id Address id
 * @param {*} edit_address Edit data Object
 * @returns Object
 */
export const set_edited_address = (address_id, edit_address) => {
	return async (dispatch) => {
		const axios = useAxios();
		await axios.post(`/addresses/update`, { ...edit_address, address_id });
	};
};
/**
 * Delete customer address
 * @param {int} address_id Address id
 * @returns Object
 */
export const delete_address_data = (address_id) => {
	return async (dispatch) => {
		const axios = useAxios();
		await axios.post(`/addresses/delete`, { address_id });
		dispatch(delete_address(address_id));
	};
};
