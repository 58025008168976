import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';

const UseOnlineStatus = () => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {
        const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
        if (connection && connection.downlink) {
            if (connection.downlink < 0.5) { // Adjust the threshold as needed (e.g., 0.5 Mbps)
                toast.error("Slow internet connection");
            }
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const handleOnline = () => {
            setIsOnline(true);
            toast.success("Restore connection.");
        };

        const handleOffline = () => {
            setIsOnline(false);
            toast.error("No internet connection!");
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
        // eslint-disable-next-line
    }, []);

    return isOnline;
};

export default UseOnlineStatus;
