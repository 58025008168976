import { BULK_ACTION, TOGGLE_MODAL } from "../actions/BulkAction";


export const bulkActionCreator = (data) => {
    return {
        type: BULK_ACTION,
        payload: data
    }
}

export const toggleModalCreator = (data) => {
    return {
        type: TOGGLE_MODAL,
        payload: data
    }
}

