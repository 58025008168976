import { combineReducers } from "redux";
import bulkReducer from "./reducer/bulkReducer";
import { productReducer } from "./products/productReducer";
import { customerReducer } from "./customers/customersReducer";
import { customerEditReducer } from "./customers/customerEditReducer";
import { orderReducer } from "./orders/orderReducer";
import { basicActionReducer } from "./basicActions/basicActionReducer";
import { userReducer } from "./user/userReducer";
import { globalReducer } from "./globals/globalReducer";

export default combineReducers({
  basicActions: basicActionReducer,
  bulkReducer,
  product: productReducer,
  customer: customerReducer,
  editCustomer: customerEditReducer,
  orders: orderReducer,
  userInfo: userReducer,
  global: globalReducer,
});
