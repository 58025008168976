import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useAuthAxios } from '../../../Auth/useAuthAxios';
import { formatDate } from '../../../../util/formateDate';
import { getLocalStorage } from '../../../hooks/useLocalStorage';
import Spinner from '../../../helpers/Spinner';

export default function PosOrderInvoice() {

    const axios = useAuthAxios();
    const { invoiceHash } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true)
    const [isInvoice, setIsInvoice] = useState(false);
    const [invoiceData, setInvoiceData] = useState([]);
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [totalPayment, setTotalPayment] = useState(0);
    const [paymentMethod, setPaymentMethod] = useState("");
    const storeInfo = getLocalStorage("storeInfo");

    useEffect(() => {
        try {
            const getInvoice = async () => {
                setLoading(true)
                const res = await axios.post(`/orders/invoice`, {
                    invoice_hash: invoiceHash
                });
                if (res.status) {
                    setIsInvoice(true);
                    setInvoiceData(res.data.data);
                    //calculate ordered item all quantity
                    const { ordered_items
                        , payments, ordered_total } = res?.data?.data;
                    setTotalQuantity(ordered_items
                        ?.reduce((acc, item) => acc + item.quantity, 0));

                    // calculate total payment
                    if (payments?.length > 0) {
                        //save last payment method
                        setPaymentMethod(payments[0]?.payment_method);
                        let totalAmount = 0;
                        payments?.forEach((payment) => {
                            totalAmount += Number(payment.paid_amount);
                        });
                        if (ordered_total > 0) {
                            setTotalPayment(totalAmount);
                        }

                    }
                    setLoading(false)
                }

                if (!res.data.status) {
                    setIsInvoice(false);
                    console.log(res.data.data.message)
                }
            }
            getInvoice();
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
        // eslint-disable-next-line
    }, [invoiceHash])

    return (
        <>
            <div className="print-only pos-invoice invoice-body">
                {loading ? <Spinner /> :

                    isInvoice ?
                        <div className="invoice">
                            <h2>{invoiceData?.store?.name}</h2>
                            <p className="address">{invoiceData?.store?.address}<br />{invoiceData?.store?.phone}</p>
                            <hr />

                            <h3>Order Receipt</h3>
                            <div className="customer-details">
                                <p>
                                    <span>Order ID</span>
                                    <span>#{invoiceData?.id}</span>
                                </p>
                                <p>
                                    <span>Date</span>
                                    <span>{formatDate(invoiceData?.added_on)}</span>
                                </p>
                                <p>
                                    <span>Customer</span>
                                    <span>{invoiceData?.customer?.name}</span>
                                </p>
                                <p>
                                    <span>Phone Number</span>
                                    <span>{invoiceData?.customer?.mobile}</span>
                                </p>
                            </div>

                            <table className="invoice-items text-[16px]">
                                {/* Header */}
                                <thead>
                                    <tr>
                                        <th className="invoice-data">Item</th>
                                        <th className="invoice-data">Qty</th>
                                        <th className="invoice-data">Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        // ordered items
                                        invoiceData?.ordered_items?.map((item, index) => (
                                            <tr key={index}>
                                                <td className="invoice-data">{item?.name}</td>
                                                <td className="invoice-data">{item?.quantity}</td>
                                                <td className="invoice-data rubik-400">৳  {item?.subtotal}</td>

                                            </tr>
                                        ))
                                    }

                                    {/* Adjustments */}
                                    <tr className="row-discount">
                                        <td className="invoice-data" colSpan="2">Discount {invoiceData?.discount?.type === "percentage" ? `(${invoiceData?.discount?.value}%)` : "(৳)"}</td>
                                        <td className="invoice-data">{invoiceData?.discount?.type === "percentage" ? `৳ ${(invoiceData?.discount?.value / 100) * invoiceData?.ordered_total}` : "৳ " + invoiceData?.discount?.value}</td>
                                    </tr>
                                    <tr className="row-tax">
                                        <td className="invoice-data" colSpan="2">Tax</td>
                                        <td className="invoice-data">0</td>
                                    </tr>
                                    <tr className="row-total">
                                        <td className="invoice-data" colSpan="2">Total (After Tax)</td>
                                        <td className="invoice-data rubik-400">৳  {invoiceData?.ordered_total}</td>
                                    </tr>

                                    {/* Final */}
                                    <tr>
                                        <th className="invoice-data">Order Total</th>
                                        <th className="invoice-data">{totalQuantity}</th>
                                        <th className="invoice-data rubik-400">৳  {invoiceData?.ordered_total}</th>
                                    </tr>

                                    <tr>
                                        <th className="invoice-data" colSpan="3" style={{ padding: '8px 3px' }}>Payments</th>
                                    </tr>

                                    {/* Payments */}
                                    <tr className="row-paid">
                                        <td className="invoice-data" colSpan="2">Paid</td>
                                        <td className="invoice-data rubik-400">৳ {totalPayment}</td></tr>
                                    <tr className="row-due">
                                        <th className="invoice-data" colSpan="2">Due</th>
                                        <th className="invoice-data rubik-400">৳ {invoiceData?.ordered_total - totalPayment}</th>
                                    </tr>
                                </tbody>
                            </table>

                            <div className="payment-details">
                                <p>
                                    <span>Payment Method</span>
                                    <span>{paymentMethod || 'N/A'}</span>
                                </p>
                            </div>

                            <hr />
                            <p className="thanks">Thank you for your purchase!</p>
                            <p className="footer">www.dokaani.com</p>
                            {/* print button area */}
                            <div className="print-button d-flex items-center justify-center ">
                                {storeInfo ? <span className="text-green-900 mr-3 underline cursor-pointer print:hidden" onClick={() => navigate(-1)}>Back</span> : ''}
                                <button onClick={() => window.print()} className='px-3 py-1 hover:text-white hover:bg-primary-500 border-primary-500 border rounded-md print:hidden'>Print</button>
                            </div>
                        </div>
                        :
                        <div className='h-full m-5'>
                            <h2 className="text-center text-red-600">Invoice not found</h2>
                        </div>
                }
            </div>
        </>
    )
}
