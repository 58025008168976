import {
  ADD_ATTR_NAME,
  ADD_ATTR_VALUE,
  ADD_PRODUCT,
  BULK_ACTION_REMOVE_PRODUCT,
  DELETE_PRODUCT_COUNT,
  DELETE_PRODUCT_FAIL,
  LOADED_PRODUCT,
  REMOVE_PRODUCT,
  SEARCH_PRODUCT,
  SET_IS_NEW_PRODUCT,
  SET_LOADING,
  SET_SHOW_TABLE_PRODUCT,
  SINGLE_PRODUCT,
  UPDATE_PRODUCT,
} from "./actionTypes/productActionTypes";

const initialValue = {
  products: [],
  isNewProduct: false,
  getProduct: [],
  attrName: [],
  attrValues: [],
  tableOfProduct: [],
  searchProducts: [],
  ifSearchFalse: [],
  bulkActionId: [],
  deleteProduct: [],
  deleteFailMsg: "",
  isLoading: true,
};
export const productReducer = (state = initialValue, action) => {
  switch (action.type) {
    case LOADED_PRODUCT:
      return {
        ...state,
        products: action.payload,
      };
    case SET_IS_NEW_PRODUCT:
      return {
        ...state,
        isNewProduct: action.payload,
      };
    case SINGLE_PRODUCT:
      return {
        ...state,
        getProduct: [action.payload],
      };
    case DELETE_PRODUCT_COUNT:
      return {
        ...state,
        deleteProduct: action.payload,
      };
    case DELETE_PRODUCT_FAIL:
      return {
        ...state,
        deleteFailMsg: action.payload,
      };
    case ADD_ATTR_NAME:
      return {
        ...state,
        attrName: [...state.attrName, action.payload],
      };
    case ADD_ATTR_VALUE:
      return {
        ...state,
        attrValues: [...state.attrValues, action.payload],
      };
    case SET_SHOW_TABLE_PRODUCT:
      return {
        ...state,
        tableOfProduct: action.payload,
      };
    case SEARCH_PRODUCT:
      return {
        ...state,
        searchProducts: action.payload,
      };
    case ADD_PRODUCT:
      return {
        ...state,
        products: [...state.products, action.payload],
      };
    case REMOVE_PRODUCT:
      return {
        ...state,
        products: state.products.filter(
          (product) => product.id !== action.payload
        ),
      };
    case BULK_ACTION_REMOVE_PRODUCT:
      return {
        ...state,
        bulkActionId: action.payload,
      };
    case UPDATE_PRODUCT:
      return {
        ...state,
        products: state.products.map((product) =>
          product.id === action.payload ? action.payload : product
        ),
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
};
