import { restrictedKeys } from "../../app/hooks/restrictedKeys";

export default function useValidation() {
    /**
    * Handle Phone number event.
    * @param {Event} event - The event object.
    */
    const handlePhoneNumber = (event) => {
        const isKeyRestricted = restrictedKeys.test(event.key);
        if (event.target.value.length >= 11 && !isKeyRestricted) {
            event.preventDefault();
        }
    }

    /**
     * Handle pin number event.
     * @param {Event} event - The event object.
     */
    const handlePinNumber = (event) => {
        const isKeyRestricted = restrictedKeys.test(event.key);
        if (!isKeyRestricted && isNaN(Number(event.key))) {
            event.preventDefault();
        }
    }

    const productFieldsValidation = (
        fieldValues,
        fieldErrors,
        setFieldErrors
    ) => {
        let temp = { ...fieldErrors };
        if (fieldValues.name === "") {
            temp.name = "Name is required";
        } else if (fieldValues.name.length < 3) {
            temp.name = "Name must be at least 3 characters";
        } else {
            temp.name = "";
        }
        if (fieldValues.description && fieldValues.description.length < 3) {
            temp.description = "Description must be at least 3 characters";
        } else {
            temp.description = "";
        }
        if (!fieldValues.sale_price) {
            temp.sale_price = "Sale price is required";
        } else {
            temp.sale_price = "";
        }
        if (!fieldValues.regular_price) {
            temp.regular_price = "Regular price is required";
        } else {
            temp.regular_price = "";
        }
        setFieldErrors(temp);
        return temp;
    };

    return { handlePhoneNumber, handlePinNumber, productFieldsValidation }
}
