import React, { useEffect, useState } from 'react';
import { useAxios, UseHelmet } from '../../../hooks';
import { NoDataTable } from '../../../helpers';
import TableSkeleton from '../../../components/skeletonLoader/TableSkeleton';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function Subscribers() {
	const axios = useAxios();
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const reseller_id = useSelector((state) => state.userInfo.user.id);

	useEffect(() => {
		const getSubscribers = async () => {
			setLoading(true)
			const res = await axios.post("/resellers/subscribers", { reseller_id })
			const { data } = await res.data;
			setData(data)
			setLoading(false)
		}
		getSubscribers();
	}, [])

	return (
		<div>
			<UseHelmet title="Subscribers" />
			<main className={`flex-1 z-0 overflow-y-auto bg-backgroundGray p-8`}>
				<div className="max-w-7xl mx-auto">
					<div className="section-heading pb-0  border-b-none flex items-center justify-center">
						<div className="title">
							<h2>
								<span>All Subscribers</span>
							</h2>
						</div>
					</div>
					<div data-controller="table">
						<div className="scrollbar-hidden table-wrapper ">
							<div className="table">
								<div className="table-header">
									<div className="table-row">
										<div className="table-cell flex-1">Name</div>
										<div className="table-cell flex-1">Mobile</div>
										<div className="table-cell flex-1 hidden md:block">Coupon</div>
										<div className="table-cell flex-1 hidden md:block">Status</div>
									</div>
								</div>
								<TableSkeleton isLoading={loading}>
									{
										data?.map((item, index) => {
											return (<div key={index} className="table-body">
												<div className="table-row " data-turbo-frame="modal">
													<Link to={`/reseller/subscribers/${item?.id}`} className="table-cell flex-1">
														{item.full_name}
													</Link>
													<Link to={`/reseller/subscribers/${item?.id}`} className="table-cell flex-1">
														{item?.mobile}
													</Link>
													<Link to={`/reseller/subscribers/${item?.id}`} className="table-cell flex-1 hidden md:block">
														{item?.coupon}
													</Link>
													<Link to={`/reseller/subscribers/${item?.id}`} className="table-cell flex-1 hidden md:block">
														<span className={`${item?.status === "active" ? "text-primary-400" : "text-red-400"}`}>{item?.status?.toUpperCase()}</span>
													</Link>

												</div>
											</div>
											)
										}
										)
									}
								</TableSkeleton>
							</div>
							{!loading && data?.length < 1 ? <NoDataTable /> : ""}
						</div>
					</div>
				</div>
			</main>
		</div>
	)
}
