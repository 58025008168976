import React, { useState, useEffect, Suspense } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import UseHelmet from '../../hooks/UseHelmet';
import { useDispatch, useSelector } from 'react-redux';
import useAxios from '../../hooks/useAxios';
import { set_is_new_customer, set_tab_activated } from '../../../redux/customers/actions/customerEditActions';
import { get_addresses, get_edit_customer } from '../../../redux/thunk/customers/customerThunk';
import UseTabs from '../../helpers/UseTabs';
import EditCustomerSkeleton from '../../components/skeletonLoader/EditCustomerSkeleton';
import toast from 'react-hot-toast';
import { SimpleSpinner } from '../../helpers';
import { BackIcon } from '../../helpers/icons/Icons';

const GeneralTab = React.lazy(() => import('../../components/customers/Tabs/GeneralTab'))
const OrderHistoryTab = React.lazy(() => import('../../components/customers/Tabs/OrderHistoryTab'))
const SocialLinkTab = React.lazy(() => import('../../components/customers/Tabs/SocialLinkTab'))

const tabsHeading = [
    {
        id: "general",
        name: "General"
    },
    {
        id: "order-history",
        name: "Order History"
    },
    {
        id: "social-links",
        name: "Social Links"
    },
]
const EditCustomer = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const axios = useAxios();
    const activeStore = localStorage.getItem("activeStore");
    const [fieldValue, setFieldValue] = useState([]);
    const [dataUnsaved, setDataChange] = useState(false);
    const [editFormSubmit, setEditFormSubmit] = useState(false);
    const [phoneError, setPhoneError] = useState('');
    const customer = useSelector((state) => state.editCustomer);
    const dispatch = useDispatch();
    const { activeTab, editCustomer, orderHistory, socialLinks, loading, isNewCustomer } = customer;

    // get the customer     
    useEffect(() => {
        dispatch(get_edit_customer(activeStore, id));
        dispatch(get_addresses(activeStore, id));
        // eslint-disable-next-line
    }, [id, editFormSubmit]);

    useEffect(() => {
        if (isNewCustomer) {
            toast.success("Customer created");
            dispatch(set_is_new_customer(false));
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!editCustomer) {
            navigate("/customers");
        }
        setFieldValue(editCustomer);
        // eslint-disable-next-line
    }, [editCustomer])

    // change the active tab
    const tabChange = e => {
        dispatch(set_tab_activated(e.target?.id));
    }

    // handle field change 
    const handleFieldChange = e => {
        // initialValues[e.target.name] = [e.target.value]
        setDataChange(true);
        setFieldValue({ ...fieldValue, [e.target.name]: e.target.value });
    }

    // when data update then update the state and server
    const handleSubmit = async () => {
        if (dataUnsaved) {
            const allData = { ...fieldValue, store_id: activeStore, order_history: orderHistory, social_link: socialLinks };
            const updateData = await axios.post(`/customers/update`, allData);
            if (updateData.data.status) {
                toast.success("Customer updated.");
                setDataChange(false);
            }
        }
    }

    const beforeunload = (e) => {
        if (dataUnsaved) {
            e.preventDefault();
            e.returnValue = true;
        }
    };

    /**
     * if user forget to save
     */
    useEffect(() => {
        window.addEventListener("beforeunload", beforeunload);

        return () => {
            window.removeEventListener("beforeunload", beforeunload);
        };
        // eslint-disable-next-line
    }, [dataUnsaved]);

    return (
        <EditCustomerSkeleton loading={loading}>
            <main className="flex-1 z-0 overflow-y-auto bg-backgroundGray p-8">
                <UseHelmet title={editCustomer?.name} />
                <div className="max-w-7xl mx-auto ">
                    <div className="section-heading borderless ">
                        <Link className="back-link btn btn-white btn-xs" data-controller="link" to="/customers">
                            <BackIcon />
                        </Link>
                        <div className="title">
                            <p className='text-2xl font-bold'>
                                #{id} - {fieldValue?.name}
                            </p>
                        </div>
                        <div className="actions">
                            {
                                <button type="button" onClick={handleSubmit} className={`sm-hidden btn btn-primary btn-sm ${activeTab !== "general" ? 'disabled' : ''} ${phoneError ? 'disabled' : ''}`}>Save Changes</button>
                            }
                        </div>
                    </div>

                    <div className="lg:hidden block">
                        <div className='flex flex-end pb-4 '>
                            {
                                <button type="button" onClick={handleSubmit} className={`btn btn-primary btn-sm ${activeTab !== "general" ? 'disabled' : ''} ${phoneError ? 'disabled' : ''}`}>Save Changes</button>
                            }
                        </div>
                    </div>
                    <div className="sm-relative">
                        <div className="table-wrapper">
                            <UseTabs activeTab={activeTab} tabChange={tabChange} allTabs={tabsHeading} />
                            <Suspense fallback={<SimpleSpinner />}>
                                <div className="px-6 my-8">
                                    {activeTab === "general" && <GeneralTab handleFieldChange={handleFieldChange} fieldValue={fieldValue} editFormSubmit={editFormSubmit} setEditFormSubmit={setEditFormSubmit} phoneError={phoneError} setPhoneError={setPhoneError} />}
                                    {activeTab === "order-history" && <OrderHistoryTab />}
                                    {activeTab === "social-links" && <SocialLinkTab />}
                                </div>
                            </Suspense>
                        </div>
                    </div>
                </div>
            </main>
        </EditCustomerSkeleton >
    )
}

export default React.memo(EditCustomer);