export const ADD_PRODUCT = "product/ADD_PRODUCT";
export const SET_IS_NEW_PRODUCT = "product/SET_IS_NEW_PRODUCT";
export const REMOVE_PRODUCT = "product/REMOVE_PRODUCT";
export const DELETE_PRODUCT_COUNT = "product/DELETE_PRODUCT_COUNT";
export const DELETE_PRODUCT_FAIL = "product/DELETE_PRODUCT_FAIL";
export const SINGLE_PRODUCT = "product/SINGLE_PRODUCT";
export const ADD_ATTR_NAME = "product/ADD_ATTR_NAME";
export const ADD_ATTR_VALUE = "product/ADD_ATTR_VALUE";
export const BULK_ACTION_REMOVE_PRODUCT = "product/BULK_REMOVE_PRODUCT";
export const UPDATE_PRODUCT = "product/UPDATE_PRODUCT";
export const LOADED_PRODUCT = "product/loaded_product";
export const SET_SHOW_TABLE_PRODUCT = "product/SET_SHOW_TABLE_PRODUCT";
export const SEARCH_PRODUCT = "product/SEARCH_PRODUCT";
export const SET_LOADING = "product/SET_LOADING";
export const IS_SHOW_MODAL = "product/IS_SHOW_MODAL";
