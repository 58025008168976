import { SET_ACTIVE_TAB } from "./actionType";

const initialValues = {
    activeTab: ""
}

export const basicActionReducer = (state = initialValues, action) => {
    switch (action.type) {
        case SET_ACTIVE_TAB:
            return { activeTab: action.payload }
        default:
            return state;
    }
}