import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { parentContext } from '../App';
import { useForm } from 'react-hook-form';
import { validateNumber } from '../hooks/validateNumber';
import { useAuthAxios } from './useAuthAxios';
import { UseHelmet } from '../hooks';
import { restrictedKeys } from '../hooks/restrictedKeys';

export default function ForgotPassword() {

    const axios = useAuthAxios();
    const navigate = useNavigate();
    const [error, setError] = useState("");
    const { setProgress } = useContext(parentContext);
    const { register, handleSubmit, formState: { errors } } = useForm();

    /**  
     * check user logged in or not
     * if user logged in, navigate to dashboard
     * if user not logged in, navigate to login
    */
    useEffect(() => {
        const checkLogin = JSON.parse(localStorage.getItem("authentication"));
        // if auth true then navigate to dashboard
        if (checkLogin) {
            navigate("/dashboard");
        }
        // eslint-disable-next-line
    }, [])

    // phone number strick validation 
    function handlePhoneNumber(event) {
        const isKeyRestricted = restrictedKeys.test(event.key);

        if (event.target.value.length >= 11 && !isKeyRestricted) {
            event.preventDefault();
        }
    }

    /**
     * Submit form data 
     * @param {*} event
     * @return
     */

    const onSubmit = async data => {
        setProgress(30);
        const mobile = data?.phone;
        const validatePhone = validateNumber(data.phone);
        if (validatePhone) {
            const res = await axios.post(`/users/resend_otp`, { mobile });
            const { status, data } = res.data;
            if (!status) {
                setProgress(100);
                setError(data.message);
            }
            if (status) {
                setProgress(100);
                localStorage.setItem("mobile", mobile);
                localStorage.setItem("s_n_p", true);// set new pin true
                navigate("/verify_otp", { state: { mobile } });
            }
        } else {
            setProgress(100);
            setError("Invalid phone number");
        }
    }

    return (
        <section className='h-screen flex overflow-hidden'>
            <UseHelmet title="Forgot Password" />
            <main className="w-full p-6 max-w-sm m-auto">
                <img src="/assets/images/login/dokaani-en.svg" className="m-auto mb-8 h-10 w-auto" style={{ width: "160px" }} alt="dokaani logo" />

                <div className="basic-heading space-y-2 mb-4 px-4">
                    <h2 className="text-xl text-center font-semibold">
                        Reset your PIN
                    </h2>
                    <p className="text-base text-center">
                        Enter your phone number below, we'll send you OTP and reset your PIN.
                    </p>
                    {
                        error &&
                        <p className="form-group-error text-xl font-semibold text-center text-red-600 pt-2">{error && (error)}</p>
                    }
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                        <label htmlFor="phone">Phone Number</label>
                        <input autoComplete="off" placeholder="01XXXXXXXXX" autoFocus={true} onKeyDown={handlePhoneNumber} {...register("phone", { required: true, maxLength: 11 })} maxLength={11} className="form-control" type="number" name="phone" id="phone" />
                        {errors.phone && <span className='form-error'>Enter a valid phone number.</span>}
                    </div>
                    <button type="submit" className="btn btn-primary btn-lg btn-block" data-controller="form-submit"><span>Send Reset OTP</span></button>
                </form>
                <div className="basic-links flex space-x-4 justify-center mt-4">
                    <Link to="/"><span>Remember your PIN? Sign In</span></Link>
                </div>
            </main>
        </section>
    )
}
