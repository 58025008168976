import React from 'react'

export default function CheckMarkIcon() {
    return (
        <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="11.9999" r="11.56" fill="#20BF6B"></circle>
            <path d="M7.44 11.5202L10.86 14.7264C11.0893 14.9414 11.4513 14.9227 11.6572 14.6853L17.3169 8.16016" stroke="white" strokeWidth="1.66154" strokeLinecap="round"></path>
        </svg>
    )
}
