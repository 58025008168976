import React, { memo, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PricingTable from './PricingTable';

const CurrentPlan = memo(() => {
    const { user } = useSelector((state) => state.userInfo);
    const [store, setStore] = useState([]);

    useEffect(() => {
        setStore(user);
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div className="table-wrapper">
                <div className="table">
                    <div className="table-header">
                        <div className="table-row">
                            <div className="table-cell flex-1">User Name</div>
                            <div className="table-cell flex-1">Phone</div>
                            <div className="table-cell flex-1">Current Plan</div>
                            {/* <div className="table-cell flex-1">Actions</div> */}
                        </div>
                    </div>

                    <div className="table-body">
                        <div className="table-row">
                            <div className="table-cell flex-1">
                                <span className="font-semibold">{store?.full_name}</span>
                            </div>
                            <div className="table-cell flex-1">
                                <span className="font-semibold">{store?.mobile}</span>
                            </div>

                            <div className="table-cell flex-1">
                                <span className="font-semibold">Small Store (Monthly)</span>
                            </div>
                            {/* <div className="table-cell flex-1 !py-3">
                                <Link to="/subscription" className="btn btn-primary btn-xs">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" role="img" className="icon h-5 w-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z"></path>
                                    </svg>
                                    <span>Upgrade</span>
                                </Link>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-10">
                <h3 className="text-2xl font-semibold mb-8">Pricing Plans</h3>
                <PricingTable />
            </div>
        </>
    )
})

export default CurrentPlan;
