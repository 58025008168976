export const SET_IS_MODAL_OPEN = "globals/OPEN_MODAL";
export const SET_IS_MODAL_CLOSE = "globals/CLOSE_MODAL";
export const SET_IS_MOBILE_MENU_OPEN = "globals/SET_IS_MOBILE_MENU_OPEN";
export const SET_IS_NEW_PRODUCT_MODAL = "globals/SET_IS_NEW_PRODUCT_MODAL";
export const SET_IS_ORDER_MODAL = "globals/SET_IS_ORDER_MODAL";
export const SET_IS_NEW_CUSTOMER_MODAL = "globals/SET_IS_NEW_CUSTOMER_MODAL";
export const SET_IS_ADD_ORDER_PRODUCT = "globals/SET_IS_ADD_ORDER_PRODUCT";
export const SET_IS_SUBSCRIPTION_MODAL = "globals/SET_IS_SUBSCRIPTION_MODAL";

export const set_is_modal_open = (value) => {
	return {
		type: SET_IS_MODAL_OPEN,
		payload: value,
	};
};

export const set_is_modal_close = (value) => {
	return {
		type: SET_IS_MODAL_CLOSE,
		payload: value,
	};
};

export const set_is_mobile_menu_open = (value) => {
	return {
		type: SET_IS_MOBILE_MENU_OPEN,
		payload: value,
	};
};

export const set_is_new_product_modal = (value) => {
	return {
		type: SET_IS_NEW_PRODUCT_MODAL,
		payload: value,
	};
};

export const set_is_order_modal = (value) => {
	return {
		type: SET_IS_ORDER_MODAL,
		payload: value,
	};
};

export const set_is_add_order_product_modal = (value) => {
	return {
		type: SET_IS_ADD_ORDER_PRODUCT,
		payload: value,
	};
};

export const set_is_new_customer_modal = (value) => {
	return {
		type: SET_IS_NEW_CUSTOMER_MODAL,
		payload: value,
	};
};

export const set_is_subscription_modal = (value) => {
	return {
		type: SET_IS_SUBSCRIPTION_MODAL,
		payload: value,
	};
};
