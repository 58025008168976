import React, { useEffect } from 'react';
import UseHelmet from '../hooks/UseHelmet';
import { useDispatch } from 'react-redux';
import { get_loaded_customers } from '../../redux/thunk/customers/customerThunk';
import { set_is_new_customer_modal } from '../../redux/globals/actions';
import CustomersTable from '../components/customers/Table/CustomersTable';
import { ModalPlusIcon } from '../helpers/icons/Icons';

const Customers = () => {
    const activeStore = localStorage.getItem("activeStore");
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(get_loaded_customers(activeStore));
        // eslint-disable-next-line
    }, [activeStore])

    return (
        <>
            <UseHelmet title="Customers" />
            <main className={`flex-1 z-0 overflow-y-auto bg-backgroundGray p-8`}>
                <div className="max-w-7xl mx-auto">
                    <div className="section-heading pb-0  border-b-none flex items-center justify-center">
                        <div className="title">
                            <h2>
                                <span>All Customers</span>
                            </h2>
                        </div>
                        <div className="actions">
                            <button type="button" onClick={() => dispatch(set_is_new_customer_modal(true))} className="btn btn-transparent btn-sm" data-turbo-frame="modal" data-controller="link">
                                <ModalPlusIcon />
                                <span>Add Customer</span>
                            </button>
                        </div>
                    </div>
                    <CustomersTable />
                </div>
            </main>
        </>
    )
}
export default React.memo(Customers)