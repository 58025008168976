import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { useAuthAxios } from '../../../Auth/useAuthAxios';
import { formatDate } from '../../../../util/formateDate';
import Spinner from '../../../helpers/Spinner';
import { useNavigate } from 'react-router-dom';
import FormatePhoneNumber from '../../../helpers/FormatePhoneNumber';

export default function GeneralInvoice() {
    const axios = useAuthAxios();
    const { invoiceHash } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true)
    const [isInvoice, setIsInvoice] = useState(false);
    const [invoiceData, setInvoiceData] = useState([]);

    useEffect(() => {
        try {
            const getInvoice = async () => {
                setLoading(true)
                const res = await axios.post(`/orders/invoice`, {
                    invoice_hash: invoiceHash
                });
                if (res.status) {
                    setIsInvoice(true);
                    setInvoiceData(res.data.data);
                    setLoading(false)
                }

                if (!res.data.status) {
                    setIsInvoice(false);
                    setLoading(false);
                }
            }
            getInvoice();
        } catch (error) {
            setLoading(false)
        }
        // eslint-disable-next-line
    }, [invoiceHash])

    return (
        <>
            {
                loading ? <Spinner /> :
                    isInvoice ? <div className=' m-auto mx-10'>
                        <div className="pos-invoice bg-white rounded-lg p-10 my-10 print:hidden">
                            <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center gap-3">
                                <h2 className='text-2xl font-bold'>{invoiceData?.store?.name}</h2>
                                <h2 className="text-4xl uppercase font-extralight">Invoice</h2>
                            </div>

                            <div className="flex lg:justify-end sm:justify-start lg:text-right lg:mt-3 mt-5">
                                <div className="flex lg:flex-row flex-col gap-5">
                                    <p className='text-md'>Invoice No: <strong>#{invoiceData?.id}</strong></p>
                                    <p className='text-md'>Date: <strong>{formatDate(invoiceData?.added_on)}</strong></p>
                                </div>
                            </div>
                            {/* pay to  */}
                            <div className="flex lg:justify-between flex-col lg:flex-row my-10">
                                <div className="pay-to text-left">
                                    <h2 className=" font-bold">Pay to:</h2>
                                    <p>{invoiceData?.store?.name || 'N/A'}</p>
                                    <p>{invoiceData?.store?.address || 'N/A'}</p>
                                    <p>{FormatePhoneNumber(invoiceData?.store?.phone) || 'N/A'}</p>
                                </div>

                                <div className="bill-to text-left lg:text-right mt-4 sm:mt-0">
                                    <h2 className=" font-bold">Bill to:</h2>
                                    <p>{invoiceData?.customer?.name}</p>
                                    <p>{invoiceData?.address && (invoiceData?.address?.street_address || 'N/A')}</p>
                                    <p>{FormatePhoneNumber(invoiceData?.customer?.mobile)}</p>
                                </div>
                            </div>

                            <div className="invoice-items mt-10">
                                {/* ordered items tables */}
                                <div className="table-wrapper">
                                    <table className='min-w-full divide-y divide-gray-200'>
                                        <thead className='bg-[#f8fafc]'>
                                            <tr>

                                                <th scope="col" className="table-head-style">Item</th>
                                                <th scope="col" className="table-head-style">Price</th>
                                                <th scope="col" className="table-head-style">Quantity</th>
                                                <th scope="col" className="table-head-style w-32">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200">
                                            {
                                                invoiceData?.ordered_items?.map((item, index) => (
                                                    <tr key={index}>
                                                        <td className="table-body-style">{`${index + 1}. `}{item?.name}</td>
                                                        <td className="table-body-style">TK {item?.price}</td>
                                                        <td className="table-body-style">{item?.quantity}</td>
                                                        <td className="table-body-style w-32">TK {item?.subtotal}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>

                                {/* payment and subtotal */}
                                <div className="flex justify-between lg:flex-row flex-col mt-5">
                                    <div className="payment-details order-last lg:order-none mt-4 lg:mt-0">
                                        <h2 className="text-md font-bold">Payment Info</h2>
                                        <p className='pt-2'>
                                            {invoiceData.payments?.length > 0 && invoiceData?.payments.map((data, index) => {
                                                return <p className='py-1' index={index}>{data.payment_method + " : TK " + data.paid_amount}</p>
                                            })}
                                            {invoiceData.payments?.length === 0 && <p className='py-1'>N/A</p>}
                                        </p>
                                    </div>

                                    <div className="w-full lg:w-1/2 mr-10">
                                        <div className="flex justify-between pt-0 sm:pt-5">
                                            <h2 className="text-md font-bold">Subtotal</h2>
                                            <h2 className="text-md font-bold">TK {invoiceData?.ordered_total}</h2>
                                        </div>
                                        <div className="flex justify-between pt-2">
                                            <span>Tax (0%)</span>
                                            <span>00</span>
                                        </div>
                                        <div className="border-b my-2"></div>

                                        <div className="flex justify-between">
                                            <h2 className="text-xl font-bold">Grand Total</h2>
                                            <h2 className="text-md font-bold">TK {invoiceData?.ordered_total}</h2>
                                        </div>
                                        <div className="border-b my-2 lg:hidden"></div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        {/* printed invoice ui */}
                        <div className="pos-invoice bg-white rounded-lg p-10 my-10 hidden-print">
                            <div className="flex justify-between items-center gap-3">
                                <h2 className='text-2xl font-bold'>{invoiceData?.store?.name}</h2>
                                <h2 className="text-4xl uppercase font-extralight">Invoice</h2>
                            </div>

                            <div className="flex justify-end text-right mt-3">
                                <div className="flex gap-5">
                                    <p className='text-md'>Invoice No: <strong>#{invoiceData?.id}</strong></p>
                                    <p className='text-md'>Date: <strong>{formatDate(invoiceData?.added_on)}</strong></p>
                                </div>
                            </div>
                            {/* pay to  */}
                            <div className="flex justify-between my-10">
                                <div className="pay-to text-left">
                                    <h2 className=" font-bold">Pay to:</h2>
                                    <p>{invoiceData?.store?.name || 'N/A'}</p>
                                    <p>{invoiceData?.store?.address || 'N/A'}</p>
                                    <p>{FormatePhoneNumber(invoiceData?.store?.phone) || 'N/A'}</p>
                                </div>

                                <div className="bill-to text-right mt-4 sm:mt-0">
                                    <h2 className=" font-bold text-right">Bill to:</h2>
                                    <p>{invoiceData?.customer?.name}</p>
                                    <p>{invoiceData?.address && (invoiceData?.address?.street_address || 'N/A')}</p>
                                    <p>{FormatePhoneNumber(invoiceData?.customer?.mobile)}</p>
                                </div>
                            </div>

                            <div className="invoice-items mt-10">
                                {/* ordered items tables */}
                                <div className="table-wrapper">
                                    <table className='min-w-full divide-y divide-gray-200'>
                                        <thead className='bg-[#f8fafc]'>
                                            <tr>

                                                <th scope="col" className="table-head-style">Item</th>
                                                <th scope="col" className="table-head-style">Price</th>
                                                <th scope="col" className="table-head-style">Quantity</th>
                                                <th scope="col" className="table-head-style">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200">
                                            {
                                                invoiceData?.ordered_items?.map((item, index) => (
                                                    <tr key={index}>
                                                        <td className="table-body-style">{`${index + 1}. `}{item?.name}</td>
                                                        <td className="table-body-style">TK {item?.price}</td>
                                                        <td className="table-body-style">{item?.quantity}</td>
                                                        <td className="table-body-style w-32">TK {item?.subtotal}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>

                                {/* payment and subtotal */}
                                <div className="flex justify-between mt-5">
                                    <div className="payment-details">
                                        <h2 className="text-md font-bold">Payment Info</h2>
                                        <p className='pt-2'>
                                            {invoiceData.payments?.length > 0 && invoiceData?.payments.map((data, index) => <p className='py-1' key={index}>{data.payment_method + " : TK " + data.paid_amount}</p>)}
                                            {invoiceData.payments?.length === 0 && <p className='py-1'>N/A</p>}
                                        </p>
                                    </div>

                                    <div className="w-1/2 mr-10">
                                        <div className="flex justify-between pt-0 sm:pt-5">
                                            <h2 className="text-md font-bold">Subtotal</h2>
                                            <h2 className="text-md font-bold">TK {invoiceData?.ordered_total}</h2>
                                        </div>
                                        <div className="flex justify-between pt-2">
                                            <span>Tax (0%)</span>
                                            <span>00</span>
                                        </div>
                                        <div className="border-b my-2"></div>

                                        <div className="flex justify-between">
                                            <h2 className="text-xl font-bold">Grand Total</h2>
                                            <h2 className="text-md font-bold">TK {invoiceData?.ordered_total}</h2>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="flex justify-center items-center mb-5 gap-4">
                            <p className="rounded-md font-semibold underline cursor-pointer print:hidden" onClick={() => navigate(-1)}>Go Back</p>
                            <button className="border border-primary-500 hover:text-white hover:bg-primary-500 px-4 py-2 rounded-md font-semibold print:hidden" onClick={() => window.print()}>
                                <span>
                                    Print
                                </span>
                            </button>
                        </div>
                    </div > :
                        <p className='m-auto text-center text-2xl mt-10'>No invoice found</p>
            }

        </>
    )
}
