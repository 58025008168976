import React, { useRef, useState } from 'react'
import { toast } from 'react-hot-toast'
import QRCode from 'qrcode.react';
import html2canvas from 'html2canvas';

export default function General() {
	const [copied, setCopied] = useState(false);
	const qrCodeRef = useRef(null);

	const downloadQrCode = () => {
		html2canvas(qrCodeRef.current).then((canvas) => {
			const qrCodeURL = canvas.toDataURL('image/png');
			const link = document.createElement('a');
			link.href = qrCodeURL;
			link.download = 'qrcode.png';
			link.click();
		});
	};

	const handleCopy = () => {
		setCopied(true);
		navigator.clipboard.writeText("https://dokaani.com/aff=111");
		toast.success("Copied to clipboard")
		// set false after 3 seconds
		setTimeout(() => setCopied(false), 2000);
	}

	return (
		<div className='flex flex-col gap-5 w-[250px]'>
			<div ref={qrCodeRef} className='border border-gray-300 p-2'>
				{/* <img onClick={downloadQrCode} ref={qrCodeRef} src="https://cdn.britannica.com/17/155017-050-9AC96FC8/Example-QR-code.jpg" alt="qrcode" /> */}
				<QRCode value='https://dokaani.com/aff=111' size={235} level={"H"} includeMargin={false} />
			</div>
			<button onClick={downloadQrCode} className='w-full border border-gray-300 rounded-lg block p-2'>Download</button>
			<div className="w-full">
				<div className="relative">
					<label for="dokaani-copy-btn" className="sr-only">Label</label>
					<input id="dokaani-copy-btn" type="text" className="col-span-6 border border-gray-300 rounded-lg block w-full p-2" value="https://dokaani.com/aff=111" disabled readonly />
					<button type="button" className="absolute right-1 end-2 top-1/2 -translate-y-1/2 text-gray-500 rounded-lg p-2 inline-flex items-center justify-center">
						{!copied && <span onClick={handleCopy} id="default-icon">
							<svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
								<path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z" />
							</svg>
						</span>}
						{copied && <span id="success-icon" className="inline-flex items-center">
							<svg className="w-4 h-4 text-primary-900" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
								<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5.917 5.724 10.5 15 1.5" />
							</svg>
						</span>}
					</button>
					<div className="absolute  inline-block px-3 py-2 text-sm font-medium transition-opacity duration-300 bg-black p-3 rounded-lg shadow-sm opacity-0 tooltip">
						<span>Copy to clipboard</span>
						<span className="hidden">Copied!</span>
						<div className="tooltip-arrow" data-popper-arrow></div>
					</div>
				</div>
			</div>

			<div className='flex flex-col gap-3'>
				<h2 className='text-lg pt-4 font-semibold'>Coupon Code</h2>
				<input className='w-full border border-gray-300 focus:ring-primary-300 focus:border-primary-300 rounded-lg p-2' type="text" placeholder="Enter coupon code" />
				<button className='bg-primary-500 hover:bg-primary-400 text-white px-4 py-2 rounded'>Apply</button>
			</div>
		</div>

	)
}
