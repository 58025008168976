import useAxios from "./useAxios";

export const IsStore = async () => {
	const axios = useAxios();
	const response = await axios.get("/stores/all");
	const { data } = response.data;
	if (data.length > 0) {
		return true;
	}

	return false;
};
