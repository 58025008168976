import React from 'react'

export default function NoDataTable() {
    return (
        <div className="empty-table border-none">
            <h3>
                No Records exist
            </h3>
        </div>
    )
}
