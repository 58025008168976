import { useState } from "react";

export default function UsePagination(data, itemsPerPage) {
	const [currentPage, setCurrentPage] = useState(1);
	const totalPages = Math.ceil(data?.length / itemsPerPage);

	/**
	 * handle page change.
	 *
	 * @param {number} pageNumber - The new page number.
	 * @return {undefined} This function does not return a value.
	 */
	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;
	const paginatedData = data && data.slice?.(startIndex, endIndex);

	return {
		currentPage,
		totalPages,
		paginatedData,
		handlePageChange,
	};
}
