import {useContext, useEffect, useState} from "react";
import {parentContext} from "../../../App";
import {set_is_subscription_modal} from "../../../../redux/globals/actions";
import LoadingButton from "../../Buttons/LoadingButton";
import {useDispatch, useSelector} from "react-redux";
import useValidation from "../../../../util/validation/useValidation";
import {useForm} from "react-hook-form";
import {useAxios} from "../../../hooks";
import toast from "react-hot-toast";
import {Link} from "react-router-dom";

const packages = [
    {
        "label": "1 Month",
        "id": "onemonth",
        "title": "Premium Subscription",
        "price_regular": "1000",
        "price_sale": "700",
        "price_sale_website": "700 + 300 = 1000",
        "discount": "30% off",
		"pay_url_normal": 'https://cutme.io/paybms-1',
		"pay_url_with_web": 'https://cutme.io/paybms-1-web',
        "paid_amount_normal": "700",
        "paid_amount_web": "1000",
    }, 
	{
        "label": "3 Month",
        "id": "threemonth",
        "title": "Premium Subscription",
        "price_regular": "3000",
        "price_sale": "2000",
        "price_sale_website": "2000 + 900 = 2900",
        "discount": "30% off",
		"pay_url_normal": 'https://cutme.io/paybms-3',
		"pay_url_with_web": 'https://cutme.io/paybms-3-web',
        "paid_amount_normal": "2000",
        "paid_amount_web": "2900",
    }, 
	{
        "label": "6 Month",
        "id": "sixmonth",
        "title": "Premium Subscription",
        "price_regular": "6000",
        "price_sale": "3800",
        "price_sale_website": "3800 + 1800 = 5600",
        "discount": "36% off",
		"pay_url_normal": 'https://cutme.io/paybms-6',
		"pay_url_with_web": 'https://cutme.io/paybms-6-web',
        "paid_amount_normal": "3800",
        "paid_amount_web": "5600",
    }, 
	{
        "label": "1 Year",
        "id": "oneyear",
        "title": "Premium Subscription",
        "price_regular": "12000",
        "price_sale": "7500",
        "price_sale_website": "7500 + 3600 = 11100",
        "discount": "38% off",
		"pay_url_normal": 'https://cutme.io/paybms-12',
		"pay_url_with_web": 'https://cutme.io/paybms-12-web',
        "paid_amount_normal": "7500",
        "paid_amount_web": "1110",
    }
];

export default function SubscriptionsModal() {
    const axios = useAxios();
    const [loading, setLoading] = useState(false);
    const [isWebsiteFeatureChecked, setIsWebsiteFeatureChecked] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState(packages[0]);
    const [packagePrice, setPackagePrice] = useState(selectedPackage.price_sale);
    const {setModalIndex} = useContext(parentContext);
    const dispatch = useDispatch();
    const {isSubscriptionModalOpen} = useSelector(state => state.global);
    const {user} = useSelector(state => state.userInfo);
    const {register, handleSubmit, formState: { errors }} = useForm();
    const {handlePhoneNumber: validate} = useValidation();
    const activeStore = localStorage.getItem("activeStore");

    useEffect(() => {
        const newPrice = isWebsiteFeatureChecked
            ? selectedPackage.price_sale_website
            : selectedPackage.price_sale;
        setPackagePrice(newPrice);
    }, [isWebsiteFeatureChecked, selectedPackage]);

    useEffect(() => {
        setModalIndex(isSubscriptionModalOpen);
        // eslint-disable-next-line
    }, [isSubscriptionModalOpen]);

    const handleWebsiteCheck = (event) => {
        setIsWebsiteFeatureChecked(event.target.checked);
    };

    const handlePackageChange = (event) => {
        const selectedPackageId = event.target.value;
        const newSelectedPackage = packages.find(pkg => pkg.id === selectedPackageId);
        setSelectedPackage(newSelectedPackage);
    };

    const onSubmit = async(value) => {

        setLoading(true);
        const {payment_from, amount} = value;
        const paid_amount = isWebsiteFeatureChecked ? selectedPackage.paid_amount_web : selectedPackage.paid_amount_normal;
        const res = await axios.post("/users/payments", {
            payment_method: "bkash",
            payment_from,
            paid_amount,
            store_id: activeStore,
            user_id: user?.id
        });
        const {status, data} = res.data;
        if (status) {
            setLoading(false);
            toast.success(data.message);
            dispatch(set_is_subscription_modal(false));
        } else {
            setLoading(false);
            toast.error(data.message);
        }
        // await onCustomerSubmit(data, activeStore, setError, setLoading);
    };

    const [couponLoading,
        setCouponLoading] = useState(false);
    const [coupon,
        setCoupon] = useState("");
    const handleCoupon = (value) => {
        setCoupon(value);
    };
    const handleSubmitCoupon = async(value) => {
        setCouponLoading(true);
        setTimeout(async() => {
            setCouponLoading(false);
            toast.success("Redirecting to payment page");
        }, 1000);

		setTimeout(async() => {
            window.open( isWebsiteFeatureChecked ? selectedPackage.pay_url_with_web : selectedPackage.pay_url_normal , '_blank');
        }, 1500);
    };

    return (
        <div
            className={`modal-wrapper ${isSubscriptionModalOpen
            ? 'pr20 animate-fade-in'
            : "!hidden"}`}
            data-controller="modal"
            aria-modal="true">
            <div className="modal-inner modal-align-responsive">
                <div
                    className="modal-overlay"
                    aria-hidden="true"
                    data-action="click->modal#close"></div>
                <span className="modal-center-trick" aria-hidden="true">​</span>
                <turbo-frame
                    data-modal-target="turboFrame"
                    id="modal"
                    target="_top"
                    src="https://app.dokaani.com"
                    complete="">
                    <div className="modal w-responsive">
                        <button
                            className="close"
                            type="button"
                            onClick={() => dispatch(set_is_subscription_modal(false))}
                            data-action="click->modal#close">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                role="img"
                                className="icon h-6 w-6">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"></path>
                            </svg>
                        </button>

                        <div className="modal-header border-b-1">
                            <h3>Payment Details</h3>
                        </div>

                        <div className="modal-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group">
                                    <label htmlFor="package">Choose Package</label>
                                    <select
                                        {...register("package", { required: true })}
                                        onChange={handlePackageChange}
                                        className="form-control"
                                        name="package"
                                        id="package">
                                        {packages.map((value, index) => {
                                            return <option key={index} value={value.id}>{value.label}
                                                ({value.discount})</option>
                                        })
}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <span className="flex justify-between justify-items-center">
                                        <span className="label">Package Price</span>
                                        <label className="flex justify-items-center cursor-pointer" htmlFor="includeWebsite">
                                            <input
                                                onChange={handleWebsiteCheck}
                                                className="form-checkbox rounded text-primary-300 h-5 w-5 focus:outline-0 checked:outline-0 focus:shadow-none checked:shadow-none focus:ring-0 checked:ring-0"
                                                type="checkbox"
                                                name="include_web"
                                                id="includeWebsite"/>
                                            <span className="ml-2 align-middle">Include Website Feature</span>
                                        </label>
                                    </span>
									<div className="flex justify-between w-full">
										<span
											type="text"
											className="form-control !w-[335px]"
											name="packagePrice"
											id="packagePrice">{packagePrice}</span>
										{couponLoading ?
											<button disabled type="button" className="btn border-primary-900 text-primary-900 btn-md ml-3 w-[158px]">
												<span className="mr-2">Working...</span>
												<svg aria-hidden="true" role="status" className="inline w-5 h-5 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
													<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
												</svg>
											</button>
											:
											<button type="button" onClick={handleSubmitCoupon} className='btn border-primary-900 text-primary-900 btn-md ml-3'>Complete Payment</button>}
									</div>
                                </div>

								{/* <div className="form-group">
									<label htmlFor="coupon">Coupon</label>
									<div className="flex">
										<input placeholder="Happy24" {...register("coupon", { required: true, maxLength: 20 })} onChange={(e) => handleCoupon(e.target.value)} maxLength={20} className="form-control" type="text" name="coupon" id="coupon" />
										{couponLoading ?
											<button disabled type="button" className="btn bg-primary-300 text-white btn-md ml-3">
												<svg aria-hidden="true" role="status" className="inline w-5 h-5 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
													<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
												</svg>
											</button>
											:
											<button type="button" onClick={handleSubmitCoupon} className='btn bg-primary-300 text-white btn-md ml-3'>Verify</button>}
									</div>
								</div> */}

                                <div className="form-group">
                                    <label htmlFor="payment_from">Bkash Mobile Number</label>
                                    <input
                                        placeholder="01XXXXXXXXX"
                                        onKeyDown={validate}
										autoComplete="off"
                                        {...register("payment_from", { required: true, minLength: 11, maxLength: 11, pattern: !/^\+?(88)?0?1[3456789][0-9]{8}\b/ })}
                                        maxLength={11}
                                        className="form-control"
                                        type="number"
                                        name="payment_from"
                                        id="payment_from"/> {errors.mobile && <span className='form-error'>Bkash number is required</span>}
                                </div>

                                <div className="modal-f mt-7">
                                    <div className="flex justify-between">
                                        <Link
                                            className="underline"
                                            to="/billing"
                                            onClick={() => dispatch(set_is_subscription_modal(false))}>See Pricing Plans</Link>
                                        {loading
                                            ? <LoadingButton title={"Submitting"}/>
                                            : <button type="submit" className='btn btn-primary btn-md'>Submit</button>
}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </turbo-frame>
            </div>
        </div>
	);
}