import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import "./css/FormStyle.css";

export default function StoreSkeleton({ children, setAfterLoading }) {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        setAfterLoading(true);
        setTimeout(() => {
            setLoading(false);
            setAfterLoading(false);
        }, 500);
        // eslint-disable-next-line
    }, []);

    return (
        loading ?
            <div className="ui-layout flex-1 z-0 overflow-y-auto bg-backgroundGray p-8">
                <div className="product_ui_container">
                    <div className="product_ui-layout__sections">
                        <div className="ui-layout__section ui-layout__section--primary">
                            <div className="ui-layout__item">
                                <div className="d-flex flex-between gap20 tg">
                                    <div className="back-btn flex tg-cly1">
                                        <div className="loading store-top-back-button line-body"></div>
                                        <div className="loading store-top-back-button-title line-body"></div>
                                    </div>
                                    <div className="loading store-top-button line-body"></div>
                                </div>
                                <section className="ui-card reports-index-cardo tg">
                                    <div className="loading-wrapper tg-cly1">
                                        <div className="title-block">
                                            <div className="d-flex gap-10">
                                                <div className="loading product-form-top-title line-body"></div>
                                                <div className="loading product-form-top-title line-body"></div>
                                            </div>
                                            <div className="w-full">
                                                <div className="form-enter-input-half loading line-body"></div>
                                            </div>
                                            <div className="flex gap-10">
                                                <div className="form-enter-input-half loading line-body"></div>
                                                <div className="form-enter-input-half loading line-body"></div>
                                            </div>
                                            <div className="flex gap-10">
                                                <div className="form-enter-input-half loading line-body"></div>
                                                <div className="form-enter-input-half loading line-body"></div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : { ...children }
    )
}
