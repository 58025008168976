export const CHANGE_FILTERING_STATUS = "order/change_filtering_status";
export const SET_IS_NEW_ORDER = "order/set_is_new_order";
export const SET_IS_WALK_IN_CUSTOMER = "order/set_is_walk_in_customer";
export const SET_EDIT_ORDER_LOADING = "order/set_edit_order_loading";
export const LOADED_ORDER = "order/loaded_order";
export const IS_LOADING_ORDER = "order/is_loading_order";
export const REMOVE_ORDER_ITEM = "order/remove_order_item";
export const SET_TABLE_OF_ORDERS = "order/set_table_of_orders";
export const SEARCH_ORDERS = "order/search_orders";
export const ORDER_ADDED = "order/order_added";
export const SINGLE_ORDERED = "order/single_order";
export const ADDED_PRODUCT_DETAILS = "order/added_product_details";
export const ADDED_ORDERED_TOTAL_AMOUNT = "order/added_ordered_total_amount";
export const ADDED_PAYMENTS = "order/added_payments";
export const ADDED_DISCOUNT_IN_ORDER = "order/added_discount_in_order";
export const ADDED_TOTAL_PAYMENT = "order/added_total_payment";
export const ADDED_DUE_AMOUNT = "order/added_due_amount";
export const ADDED_ADDRESS_NAME = "order/added_address_name";
export const ADDED_FULL_ADDRESS = "order/added_full_address";
export const ADDED_ADDRESS_ID = "order/added_address_id";
export const ADDED_ORDER_STATUS = "order/added_order_status";
export const NEW_ORDER_ITEM = "order/new_order_item";
export const ADDED_ALL_PRODUCTS = "order/added_all_products";
export const ADDED_ORDER_PRODUCT_ITEM = "order/added_order_product_item";
export const SET_DELETE_ORDER_LOADING = "order/set_delete_order_loading";
export const SET_ADDED_ORDER_PRODUCT_LOADING =
	"order/set_added_order_product_loading";
export const SET_DELETE_ORDER_PRODUCT_LOADING =
	"order/set_delete_order_product_loading";
export const SET_DELETE_ORDER_PAYMENT_LOADING =
	"order/set_delete_order_payment_loading";
