import React, { useState, useRef, useEffect, useContext } from "react";
import { ModalWithTable } from '../../hooks';
import QRCode from "qrcode.react";
import { DownloadIcon, ModalCloseIcon, ModalPlusIcon } from "../../helpers/icons/Icons";
import { set_is_modal_close, set_is_modal_open } from "../../../redux/globals/actions";
import { useDispatch, useSelector } from "react-redux";
import { parentContext } from "../../App";
import { Formik } from "formik";
import { useSearchParams } from "react-router-dom";

const RestaurantSettingTab = ({ setAllStuff, allStuff, handleDelete }) => {
    const [qrCodeStatus, setqrCodeStatus] = useState([{ id: "active", value: "Active" }, { id: "inactive", value: "Inactive" }]);
    const { setModalIndex } = useContext(parentContext);
    const { isModalOpen } = useSelector(state => state.global);
    const [searchParams,] = useSearchParams();
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const twoColumn = true;

    useEffect(() => {
        if (searchParams.get('create') === "true") {
            setShowModal(true);
        }
        // eslint-disable-next-line
    }, []);

    // useEffect(() => {
    //     setShowModal(showHardModal);
    //     // eslint-disable-next-line
    // }, [showHardModal]);

    useEffect(() => {
        setModalIndex(isModalOpen);
        // eslint-disable-next-line
    }, [isModalOpen]);

    // useEffect(() => {
    //     if (isFormSubmit === true) {
    //         setShowModal(false);
    //     }
    // }, [isFormSubmit, isSubmit]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape' && isModalOpen) {
                dispatch(set_is_modal_close(false));
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isModalOpen, dispatch]);









    const [restaurantData, setRestaurantData] = useState([]);
    const [isSubmit, setIsSubmit] = useState(false);


    console.log("........................", allStuff);
    // const formData = {
    //     fields: [
    //         {
    //             name: "stuff_id",
    //             type: "select",
    //             label: "Select Stuff",
    //             required: true,
    //             placeholder: "Select Stuff",
    //             value: "",
    //             options: [
    //                 { "1012": "Stuff A" },
    //                 { "1013": "Stuff B" },
    //                 { "1014": "Stuff C" }
    //             ]
    //         },
    //         {
    //             name: "tableBumber",
    //             type: "text",
    //             label: "Table Number",
    //             required: true,
    //             placeholder: "",
    //             value: "",
    //         },
    //         {
    //             name: "qrstatus",
    //             type: "text",
    //             label: "QR Status",
    //             placeholder: "",
    //             required: true,
    //             value: "",
    //         }
    //     ]
    // }

    // {
    //     "id":"1",
    //     "user_id":"2",
    //     "store_id":"22",
    //     "qr_name":"Table 2",
    //     "qr_status":"active",
    //     "datetime":"2024-08-15 12:42:06"
    // }


    const formData2 = [
        {
            "staff_id": "12",
            "assignedStuff": "Shakib",
        },
        {
            "staff_id": "2",
            "assignedStuff": "Ahad Hossain",
        },
        {
            "staff_id": "9",
            "assignedStuff": "Ishtiak Ahmed",
        },
        {
            "staff_id": "6",
            "assignedStuff": "Shabbir Benn Tabib",
        },
        {
            "staff_id": "5",
            "assignedStuff": "Zihad",
        },
        {
            "staff_id": "8",
            "assignedStuff": "Jubair al sabid",
        },
        {
            "staff_id": "1",
            "assignedStuff": "Ibrahim Kabir",
        },
    ]



    const [selectedData, setSelectedData] = useState(null);
    const qrRef = useRef();

    const handleClick = (item) => {
        const currentDateTime = new Date().toLocaleString();
        const dataWithTimestamp = {
            ...item,
            generatedAt: currentDateTime,
        };
        setSelectedData(dataWithTimestamp);
        setTimeout(() => handleDownload(), 100);
    };

    const handleDownload = () => {
        const canvas = qrRef.current.querySelector("canvas");
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");

        const downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `qr-code-${selectedData?.staff_id}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };
    return (
        <div className='form-section-wrapper rounded-none border-none'>

            <main className={`flex-1 z-0 overflow-y-auto ${twoColumn ? "" : "bg-backgroundGray p-8"}`}>
                <div className="max-w-7xl mx-auto">
                    <div className="section-heading pb-0 border-b-none flex items-center justify-center">
                        <div className="title">
                            <h2>
                                <span>All QR Code</span>
                            </h2>
                        </div>
                        <div className="actions">
                            <button type="button" onClick={() => { setShowModal(!showModal); dispatch(set_is_modal_open(true)) }} className="btn btn-transparent btn-sm" data-turbo-frame="modal" data-controller="link">
                                <ModalPlusIcon />
                                <span>Add Scanner</span>
                            </button>
                        </div>
                    </div>
                    {/* {children} */}
                    <div className={`modal-wrapper ${isModalOpen ? 'pr20 animate-fade-in' : "!hidden"}`} data-controller="modal" aria-modal="true">
                        <div className="modal-inner modal-align-responsive">
                            <div className="modal-overlay" aria-hidden="true" data-action="click->modal#close"></div>
                            <span className="modal-center-trick" aria-hidden="true">​</span>
                            <turbo-frame data-modal-target="turboFrame" id="modal" target="_top" src="https://app.dokaani.com" complete="">
                                <div className="modal w-responsive">
                                    <button className="close" type="button" onClick={() => (dispatch(set_is_modal_close(false)))} data-action="click->modal#close">
                                        <ModalCloseIcon />
                                    </button>
                                    <div className="modal-header border-b-1">
                                        <h3>Add Scanner</h3>
                                    </div>
                                    {(isModalOpen) &&
                                        <div className="modal-body">
                                            <Formik validateOnBlur={false} >
                                                {({ values, touched, errors, handleBlur, handleChange, handleSubmit }) => (
                                                    <form onSubmit={handleSubmit}>
                                                        <div className="form-group">
                                                            <label>Table Name</label>
                                                            <input value="" type="text" name="tableName" onChange={handleChange} onBlur={handleBlur} className="form-control outline outline-transparent" placeholder="Enter Table Name" />
                                                            {touched.name && errors.name && <div className='form-error'>{errors.name}</div>}
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="staffDropdown">Select Staff:</label>
                                                            <select name="assignedStuff" className="form-control form-control">
                                                                <option value="" disabled>Select a staff member</option>
                                                                {Object.keys(allStuff).map((key) => (
                                                                    <option key={allStuff[key].id} value={allStuff[key].id}>
                                                                        {allStuff[key].stuff_name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>

                                                        {
                                                            "qrCodeStatus" && <div className="form-group">
                                                                <label htmlFor="qrCodeStatus">QR Status</label>
                                                                <select name="qrStatus" className="form-control form-control" onChange={(e) => { handleChange(e); }} data-controller="select" data-action="change->select#change" id="qrCodeStatus">
                                                                    <option>Select QR Status</option>
                                                                    {qrCodeStatus?.map((d, indx) => <option defaultValue={d.id} key={indx}>{d.value}</option>)}
                                                                </select>
                                                            </div>
                                                        }

                                                        {/* cancel and submit form area */}
                                                        <div className="modal-f mt-7">
                                                            <div className="secondary-action">
                                                                <button type="button" className='btn btn-primary btn-md cursor-default' disabled>Add Scanner</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                )}
                                            </Formik>
                                        </div>

                                    }
                                </div>

                            </turbo-frame>
                        </div>
                    </div>
                </div>
            </main>
















            <div className="table-wrapper">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className='bg-[#f8fafc]'>
                        <tr>
                            <th scope="col" className="table-head-style">
                                Table nummber
                            </th>
                            <th scope="col" className="table-head-style">
                                Assigned Staff
                            </th>
                            <th scope="col" className="table-head-style flex justify-center">
                                QR action
                            </th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        {
                            formData2?.map((item, index) =>
                                <tr>
                                    <td className="table-body-style">
                                        {item.staff_id}
                                    </td>
                                    <td className="table-body-style">
                                        {item.assignedStuff}
                                    </td>
                                    <td className="table-body-style flex justify-center">
                                        <button key={item.id}
                                            onClick={() => handleClick(item)} type="button" className="text-[#2D986B] hover:text-[#1a5c40] font-normal px-1.5 pb-[0.1rem] rounded-[4px] text-[13px] mr-4" >
                                            <DownloadIcon />
                                        </button>
                                    </td>
                                </tr>

                            )
                        }
                    </tbody>
                </table>
            </div>
            <div style={{ padding: "20px" }}>


                {selectedData && (
                    <div className="mt-[30px] flex justify-center items-center">
                        <div className="text-center">
                            <h2>QR Code for ID: {selectedData.staff_id}</h2>
                            <div ref={qrRef} className="flex justify-center">
                                <QRCode value={JSON.stringify(selectedData)} />
                            </div>
                            <p><strong>Table No:</strong> {selectedData.staff_id}</p>
                            <p><strong>Assigned Stuff:</strong> {selectedData.assignedStuff}</p>
                            <p><strong>Generated At:</strong> {selectedData.generatedAt}</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default RestaurantSettingTab;