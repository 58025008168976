import React from 'react'
import "./css/FormStyle.css";

export default function TableSkeleton({ children, isLoading }) {
    return (
        isLoading ? <main className="flex-1 z-0 overflow-y-auto" id="disabled">
            <div className="max-w-7xl mx-auto">
                <div className="" data-table-target="tableWrapper">
                    <div className="table">
                        <table className="tg">
                            <tbody>
                                <tr>
                                    <td className="tg-cly1">
                                        <div className="line"></div>
                                    </td>
                                    <td className="tg-cly1">
                                        <div className="line"></div>
                                    </td>
                                    <td className="tg-cly1">
                                        <div className="line"></div>
                                    </td>
                                    <td className="tg-cly1">
                                        <div className="line"></div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </main> : children
    )
}
