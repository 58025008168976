import {
	ADDED_ALL_STORES,
	ADDED_AVAILABLE_ORDER_STATUS,
	ADDED_ERROR,
	ADDED_FULL_NAME,
	ADDED_IS_AUTH,
	ADDED_IS_STORE,
	ADDED_LOADING,
	ADDED_RESELLERS,
	ADDED_USER_INFO,
	ADDED_USER_TYPE,
} from "./actionType";

export const added_user_type = (type) => {
	return {
		type: ADDED_USER_TYPE,
		payload: type,
	};
};

export const added_user_info = (user) => {
	return {
		type: ADDED_USER_INFO,
		payload: user,
	};
};

export const added_full_name = (fullName) => {
	return {
		type: ADDED_FULL_NAME,
		payload: fullName,
	};
};

export const added_available_order_status = (status) => {
	return {
		type: ADDED_AVAILABLE_ORDER_STATUS,
		payload: status,
	};
};

export const added_loading = (loading) => {
	return {
		type: ADDED_LOADING,
		payload: loading,
	};
};

export const added_error = (error) => {
	return {
		type: ADDED_ERROR,
		payload: error,
	};
};

export const added_is_auth = (isAuth) => {
	return {
		type: ADDED_IS_AUTH,
		payload: isAuth,
	};
};

export const added_is_store = (isStore) => {
	return {
		type: ADDED_IS_STORE,
		payload: isStore,
	};
};

export const added_all_stores = (stores) => {
	return {
		type: ADDED_ALL_STORES,
		payload: stores,
	};
};

export const added_reseller = (reseller) => {
	return {
		type: ADDED_RESELLERS,
		payload: reseller,
	};
};
