import React, { useEffect, useState } from 'react';
import UseHelmet from '../hooks/UseHelmet';
import { cn } from '../../util/cn';
import { useAxios } from '../hooks';
import toast from 'react-hot-toast';
import HorizontalLoader from '../helpers/HorizontalLoader';

const statsSummery = [
    {
        id: 1,
        name: "Today",
        value: "today",
    },
    {
        id: 2,
        name: "7 days",
        value: "last_7_days"
    },
    {
        id: 3,
        name: "30 days",
        value: "last_30_days"
    },
    {
        id: 4,
        name: "6 mo",
        value: "last_6_months"
    },
    {
        id: 5,
        name: "Last year",
        value: "last_year"
    }
]
export default function Dashboard() {
    const axios = useAxios();
    const store_id = localStorage.getItem("activeStore");
    const [loading, setLoading] = useState(false);
    const [currentStats, setCurrentStats] = useState("today");
    const [allStatsData, setAllStatsData] = useState([]);
    const [stats, setStats] = useState([]);

    const handleStats = value => {
        setLoading(true);
        setCurrentStats(value)
        setTimeout(() => {
            setLoading(false);
        }, 500)
    }

    useEffect(() => {
        const getStats = async () => {
            setLoading(true);
            const res = await axios.post("/stores/stats", {
                id: store_id
            })
            const { data, status } = res.data;
            if (status) {
                setAllStatsData(data);
                setStats(data[0][currentStats]);
                setLoading(false);
            } else {
                toast.error(data?.message || "Something went wrong");
                setLoading(false);
            }
        }
        getStats();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (allStatsData.length > 0) {
            setStats(allStatsData[0][currentStats]);
        }
    }, [currentStats, allStatsData])

    return (
        <section className='flex-1 z-0 overflow-y-auto bg-backgroundGray p-8 w-full'>
            <div className="max-w-7xl mx-auto">
                <UseHelmet title={"Dashboard "} />
                <div className='total-summery'>
                    <div className='flex lg:flex-row gap-3 flex-col lg:justify-start mb-5'>
                        <h1 className='text-2xl'>Today Summery</h1>
                        <div className='product-tab flex flex-wrap'>
                            {
                                statsSummery?.map((item, index) =>
                                    <div key={index} onClick={() => handleStats(item?.value)} className={cn(
                                        'shadow-sm cursor-pointer text-sm font-medium text-center py-1.5 md:px-4 px-2',
                                        index === 0 && 'rounded-l-md',
                                        index !== statsSummery.length - 1 && 'border-r',
                                        index === statsSummery.length - 1 && 'rounded-r-md',
                                        item.value === currentStats ? 'bg-primary-900 text-white' : 'bg-primary-100 text-primary-200'
                                    )}>
                                        <button className="tag_txt" type='button'>{item.name}</button>
                                    </div>
                                )
                            }
                        </div>
                    </div>

                    <div className="rubik-400">
                        <div className="grid gap-4 lg:gap-8 md:grid-cols-3">
                            <div className="relative p-6 rounded-md bg-white shadow">
                                <div className="space-y-2">
                                    <div
                                        className="flex items-center space-x-2 rtl:space-x-reverse text-sm font-medium text-gray-500 dark:text-gray-400">
                                        <span>Orders (Volume)</span>
                                    </div>

                                    <div className="text-3xl">
                                        {
                                            loading ? <HorizontalLoader /> :
                                                "৳" + stats?.orders_volume || 0
                                        }
                                    </div>

                                    <div className="hidden items-center space-x-1 rtl:space-x-reverse text-sm font-medium text-green-600">
                                        <span>৳  300 increase</span>
                                        <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                            aria-hidden="true">
                                            <path fillRule="evenodd"
                                                d="M12 7a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0V8.414l-4.293 4.293a1 1 0 01-1.414 0L8 10.414l-4.293 4.293a1 1 0 01-1.414-1.414l5-5a1 1 0 011.414 0L11 10.586 14.586 7H12z"
                                                clipRule="evenodd"></path>
                                        </svg>
                                    </div>
                                </div>
                            </div>

                            <div className="relative p-6 rounded-md bg-white shadow">
                                <div className="space-y-2">
                                    <div
                                        className="flex items-center space-x-2 rtl:space-x-reverse text-sm font-medium text-gray-500 dark:text-gray-400">
                                        <span>Orders (Total Number)</span>
                                    </div>

                                    <div className="text-3xl dark:text-gray-100">
                                        {
                                            loading ? <HorizontalLoader /> :
                                                stats?.orders_number || 0
                                        }
                                    </div>

                                    <div className="hidden items-center space-x-1 rtl:space-x-reverse text-sm font-medium text-red-600">
                                        <span>3% decrease</span>
                                        <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                            aria-hidden="true">
                                            <path fillRule="evenodd"
                                                d="M12 13a1 1 0 100 2h5a1 1 0 001-1V9a1 1 0 10-2 0v2.586l-4.293-4.293a1 1 0 00-1.414 0L8 9.586 3.707 5.293a1 1 0 00-1.414 1.414l5 5a1 1 0 001.414 0L11 9.414 14.586 13H12z"
                                                clipRule="evenodd"></path>
                                        </svg>
                                    </div>
                                </div>
                            </div>

                            <div className="relative p-6 rounded-md bg-white shadow">
                                <div className="space-y-2">
                                    <div
                                        className="flex items-center space-x-2 rtl:space-x-reverse text-sm font-medium text-gray-500 dark:text-gray-400">
                                        <span>Customers</span>
                                    </div>

                                    <div className="text-3xl dark:text-gray-100">
                                        {
                                            loading ? <HorizontalLoader /> :
                                                stats?.customers || 0
                                        }
                                    </div>

                                    <div className="hidden items-center space-x-1 rtl:space-x-reverse text-sm font-medium text-green-600">
                                        <span>7% increase</span>

                                        <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                            aria-hidden="true">
                                            <path fillRule="evenodd"
                                                d="M12 7a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0V8.414l-4.293 4.293a1 1 0 01-1.414 0L8 10.414l-4.293 4.293a1 1 0 01-1.414-1.414l5-5a1 1 0 011.414 0L11 10.586 14.586 7H12z"
                                                clipRule="evenodd"></path>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}