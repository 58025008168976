import {
  DELETE_ORDER_HISTORY,
  GET_EDIT_CUSTOMER,
  SET_IS_NEW_CUSTOMER,
  SET_LOADING,
  SET_ORDER_HISTORY,
  SET_SOCIAL_LINK,
  SET_TAB_ACTIVATED,
} from "./actionTypes/customerEditActionTypes";

const initialValue = {
  activeTab: "general",
  loading: true,
  isNewCustomer: false,
  editCustomer: [],
  socialLinks: [],
  orderHistory: [],
};
export const customerEditReducer = (state = initialValue, action) => {
  switch (action.type) {
    case SET_TAB_ACTIVATED:
      return {
        ...state,
        activeTab: action.payload,
      };
    case SET_IS_NEW_CUSTOMER:
      return {
        ...state,
        isNewCustomer: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_EDIT_CUSTOMER:
      return {
        ...state,
        editCustomer: action.payload,
      };
    case SET_ORDER_HISTORY:
      return {
        ...state,
        orderHistory: action.payload,
      };
    case SET_SOCIAL_LINK:
      return {
        ...state,
        socialLinks: action.payload,
      };
    case DELETE_ORDER_HISTORY:
      return {
        ...state,
        orderHistory: state.orderHistory.filter(
          (item) => item.id !== action.payload
        ),
      };

    default:
      return state;
  }
};
