import React from 'react'
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

export default function Chat() {
  return (
    <div>
      <p>Please find the live chat button at the bottom right of this page.</p>

      <TawkMessengerReact
        propertyId="6663031e9a809f19fb3b0b8b"
        widgetId="1hvpc8cda" />
    </div>
  )
}
