import React, { useEffect, useState } from 'react'
import PricingCard from './PricingCard';
import { useAuthAxios } from '../../../Auth/useAuthAxios';

export default function PricingTable() {
    const [selected, setSelected] = React.useState("monthly");
    const [monthlyPricing, setMonthlyPricing] = useState([]);
    const [yearlyPricing, setYearlyPricing] = React.useState([]);
    const axios = useAuthAxios();

    useEffect(() => {
        (async () => {
            const res = await axios.post("/users/pricing")
            setMonthlyPricing([res.data.data[0]]);
            setYearlyPricing([res.data.data[1]]);
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <div>
            <div className="">
                <div className="px-4 py-2 bg-gray-100 rounded max-w-[220px] w-full justify-between flex">
                    <button className={`px-8 py-2 rounded-md font-medium ${selected === "monthly" && "bg-white shadow-sm"}`} onClick={() => setSelected("monthly")}>মাসিক</button>
                    <button className={`px-8 py-2 rounded-md font-medium ${selected === "yearly" && "bg-white shadow-sm"}`} onClick={() => setSelected("yearly")}>বার্ষিক</button>
                </div>
            </div>

            <div className="mt-10">
                {
                    (monthlyPricing && yearlyPricing) && (selected === "monthly" ? monthlyPricing : yearlyPricing)?.map((data, index) => {
                        return <PricingCard key={index} data={data} />
                    })
                }

            </div>
        </div>
    )
}
