import axios from "axios";

export const useAuthAxios = () => {
	let baseURL = "https://api.bonik.io";

	if (window.location.href.includes("stage")) {
		baseURL = "https://stageapi.bonik.io";
	} else if (window.location.href.includes("localhost")) {
		baseURL = "http://dokaani.local";
	}

	baseURL += "/query/v1";

	return axios.create({ baseURL });
};
