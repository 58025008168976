import StoreEdit from "./StoreEdit";
import Customers from "./Customers";
import EditCustomer from "./Customers/EditCustomer";
import Orders from "./Orders";
import Products from "./Products";
import EditProduct from "./Products/EditProduct";
import NewStore from "./Stores/NewStore";
import Support from "./Supports/Support";
import Dashboard from "./Dashboard";
import Billing from "./Billing/Billing";
import Subscribers from "./Resellers/subscribers/Subscribers";
import Settings from "./Resellers/Settings";
import StuffDashboard from "./Resellers/Dashboard";

import { getLocalStorage } from "../hooks/useLocalStorage";
import SubscriberDetails from "./Resellers/subscribers/SubscriberDetails";
import OrderDetails from "./Orders/OrderDetails";

const merchantRoutes = [
	{ path: "/dashboard", element: <Dashboard /> },
	{ path: "/products", element: <Products /> },
	{ path: "/product/:id", element: <EditProduct /> },
	{ path: "/customers", element: <Customers /> },
	{ path: "/customer/:id", element: <EditCustomer /> },
	{ path: "/orders", element: <Orders /> },
	{ path: "/order/:id", element: <OrderDetails /> },
	{ path: "/store/new", element: <NewStore /> },
	{ path: "/store", element: <StoreEdit /> },
	{ path: "/billing", element: <Billing /> },
	{ path: "/support", element: <Support /> },
];

const ResellerRoutes = [
	{ path: "/reseller/dashboard", element: <StuffDashboard /> },
	{ path: "/reseller/subscribers", element: <Subscribers /> },
	{ path: "/reseller/subscribers/:id", element: <SubscriberDetails /> },
	{ path: "/reseller/settings", element: <Settings /> },
];

let PageRoutes = [];
const utype = getLocalStorage("utype") && atob(getLocalStorage("utype"));

if (utype !== null && utype === "reseller") {
	PageRoutes = ResellerRoutes;
} else {
	PageRoutes = merchantRoutes;
}

export default PageRoutes;
