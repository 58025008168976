import { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import useAxios from '../hooks/useAxios';
import { added_full_name, added_user_info, added_user_type } from '../../redux/user/actions';
import { SetCookie } from '../hooks/Cookies';
import { setLocalStorage } from '../hooks/useLocalStorage';
import CannySDK from '../hooks/CannySDK';

const AuthGuard = ({ children }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const axios = useAxios();
    const { pathname } = useLocation();
    const [loading, setLoading] = useState(true);
    const [isAuth, setIsAuth] = useState(false);

    useEffect(() => {
        (async () => {
            const response = await axios.post("/users");
            const { status } = response.data;
            if (status === 404 || status === 400) {
                localStorage.clear();
                SetCookie("ask", "", -1);
                setLoading(false);
                navigate("/");
            }

            if (response.data.status) {
                setIsAuth(true);

                // set user type
                const data = response.data?.data;
                dispatch(added_user_type(data?.type))
                const userType = data?.type
                const hashUserType = btoa(userType)
                setLocalStorage("utype", hashUserType)
                dispatch(added_user_info(data));
                dispatch(added_full_name(data?.full_name));
                setLoading(false);
            }
        })();
        // eslint-disable-next-line
    }, [])

    CannySDK.init();
    // eslint-disable-next-line
    Canny("initChangelog", {
        appID: "663123a3512239729cbbdd5e",
        position: "right",
        align: "top",
        theme: "light",
    })

    return (
        <>
            {loading ?
                // loader spinner
                <div className="loader-container">
                    <div className="loader"></div>
                </div>
                :
                (isAuth ? children : <Navigate replace to="/" state={{ from: pathname }} />)}
        </>
    );
};

export default AuthGuard;