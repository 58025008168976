import React from 'react'

export default function Feed() {
  return (
    <div>
      {/* <iframe title='feed' width='100%' height='600px' src="https://www.facebook.com/v17.0/plugins/page.php?app_id=&channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df3108c86b406dac%26domain%3Dboonik.org%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Fboonik.org%252Ff20c3ecd6284be%26relation%3Dparent.parent&container_width=575&height=800&hide_cover=false&hide_cta=false&href=https%3A%2F%2Fwww.facebook.com%2Fboonik.org&locale=en_US&sdk=joey&show_facepile=true&small_header=false&tabs=timeline%2Cevents&width=500px"></iframe> */}
      <iframe
        src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fdokaanidotcom&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=false&hide_cover=false&show_facepile=true&appId"
        width="340" 
        height="500" 
        scrolling="no"
        frameborder="0"
        allowfullscreen="true"
        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    </div>
  )
}
