import { Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import * as Yup from "yup";
import { validateNumber } from './validateNumber';
import { set_is_modal_close } from '../../redux/globals/actions';
import { useDispatch } from 'react-redux';

// payment method data and shipping method data
const method = [{ id: 7001, value: "Bkash" }, { id: 7002, value: "Nagod" }, { id: 7003, value: "Rocket" }, { id: 7004, value: "Cash On Delivery" }, { id: 7099, value: "Others" }];
const shippingMethod = [{ id: 9001, value: "Carrier" }, { id: 9002, value: "Red-x" }];

export default function DokaaniForm({ btnLabel, formData, setFormData, isSubmit, setShowModal }) {

    const dispatch = useDispatch();
    const [fieldValue, setFieldValue] = useState([]);
    // set form initial value 
    let initialValues = {};
    useEffect(() => {
        // set initial value looping user JSON file
        let fieldData = formData?.fields;
        for (var i = 0; i < fieldData?.length; i++) {
            initialValues[fieldData[i]?.name] = fieldData[i]?.value;
        }
        // eslint-disable-next-line
    }, [formData.fields]);

    /**
     * validation schema
     * @param
     * @returns
     */
    const validationSchema = Yup.object().shape(
        formData?.validation
    );

    // All State 
    const [data,] = useState(initialValues);
    const [divisions, setDivisions] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [subDistricts, setSubDistricts] = useState([]);
    const [selectDivision, setSelectDivision] = useState(initialValues?.division ? initialValues.division : "");
    const [selectDistrict, setSelectDistrict] = useState(initialValues?.district ? initialValues.district : "");
    const [, setSelectSubDistrict] = useState(initialValues?.sub_district ? initialValues.sub_district : "");
    const [stuffStatus, setStuffStatus] = useState([{ id: "active", value: "Active" }, { id: "suspended", value: "Suspended" }]);
    const [phoneError, setPhoneError] = useState('');
    const [getPhone, setGetPhone] = useState('');

    // division data load
    const isDivision = formData?.fields.find(item => item.type === "divisions");
    useEffect(() => {
        isDivision && fetch("/bdapi/divisions.json")
            .then((res) => res.json())
            .then((data) => setDivisions(data?.data));
        // eslint-disable-next-line
    }, []);

    // load District data and select the Sub-district
    useEffect(() => {
        selectDivision && fetch("/bdapi/divisions.json")
            .then((res) => res.json())
            .then((data) => {
                const divisions = data.data;
                const districts = divisions.find(division => division.division === selectDivision);
                setDistricts(districts?.districts)

                // if any district select customer then show those sub-districts
                if (selectDistrict) {
                    let findDistricts = districts?.districts?.find((d) => {
                        const sameDistrict = d?.district === selectDistrict;
                        return sameDistrict;
                    });
                    // find the sub-districts and store [setSubDistricts] state
                    setSubDistricts(findDistricts?.upazilla);
                }
            });
    }, [selectDivision, selectDistrict, divisions]);

    /**
     * Handle division change
     * @param {*} e event of division field
     */
    const handleDivisionChange = (e) => {
        setSelectDivision(e.target.value);
    }

    /**
     * Handle district change
     * @param {*} e event of district
     */
    const handleDistrictChange = e => {
        setSelectDistrict(e.target.value)
    }

    /**
     * Handle sub-district change
     * @param {*} e event of sub-district
     */
    const handleChangeSubDistrict = (e) => {
        setSelectSubDistrict(e.target.value)
    }

    /**
     * Validate phone number
     * @param {function} event
     * @returns
     */
    function handlePhoneValidate(event) {

        const restrictedKeys = /Enter|Backspace|Tab|ArrowRight|ArrowLeft|Delete|Home|End/;
        const isKeyRestricted = restrictedKeys.test(event.key);

        if (event.target.value.indexOf(0) !== 0) {
            setPhoneError('Invalid phone number');
        }

        if (event.target.value.length === 11 && !isKeyRestricted) {
            event.preventDefault();
        }
    }

    const handlePhoneNumber = e => {
        // check input is number or not
        if (isNaN(Number(e.target.value))) {
            e.preventDefault();
            return;
        } else {
            setGetPhone(e.target.value);
        }
    }

    useEffect(() => {
        if (getPhone.length > 0 && !validateNumber(getPhone)) {
            setPhoneError("Invalid phone number");
        } else {
            setPhoneError('');
        }
    }, [getPhone]);

    /**
    * handle all basic field change
    * @param {*} e event
    */
    const handleFieldChange = e => {
        setFieldValue({ ...fieldValue, [e.target.name]: e.target.value });
    }

    /**
     * handle form submit
     * @param {*} values form value
     * @param {*} param1 reset the form
     */
    const handleFormSubmit = async (values, { resetForm }) => {
        setFormData(values);
        isSubmit(true);
        setShowModal && setShowModal(false);
        dispatch(set_is_modal_close(true));
        resetForm();
    };

    return (
        <>
            <div className="modal-body">
                <Formik validateOnBlur={false} onSubmit={handleFormSubmit} validationSchema={validationSchema} initialValues={data}>
                    {({ values, touched, errors, handleBlur, handleChange, handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            {
                                formData?.fields?.map((item, index) =>
                                    <div key={index}>
                                        {
                                            // only basic form fields
                                            !["districts", "divisions", "subDistricts", "stuffStatus", "radio", "textarea", "checkbox", "method", "shippingMethod", "phone", "order_value", "payment_due", "disabled"].includes(item?.type) && <div className="form-group">
                                                <label htmlFor={`${item?.name}`}>{item?.label}</label>
                                                <input placeholder={`${item?.placeholder}`} readOnly={item?.readonly ? true : false} onBlur={handleBlur} value={values[item?.name] || ''} onChange={handleChange} className="form-control" type={`${item?.type}`} name={`${item?.name}`} id={`${item?.name}`} />
                                                {touched[item?.name] && errors[item?.name] && <div className='form-error'>{errors[item?.name]}</div>}
                                            </div>
                                        }

                                        {
                                            // only phone field
                                            ["phone"].includes(item?.type) && <div className="form-group">
                                                <label htmlFor={item?.name}>{item?.label}</label>
                                                <input placeholder={`${item?.placeholder}`} readOnly={item?.readonly ? true : false} value={values[item?.name] || ''} onChange={(e) => { handleChange(e); handlePhoneNumber(e) }} onKeyDown={(e) => handlePhoneValidate(e)} className="form-control" type="text" name={`${item?.name}`} id={`${item?.name}`} />
                                                {phoneError && <div className='form-error'>{phoneError}</div>}
                                            </div>
                                        }
                                        {
                                            // only phone field
                                            ["checkbox"].includes(item?.type) && <div className="form-group">
                                                <label className="inline-flex items-center">
                                                    <input type="checkbox" className="form-checkbox rounded text-indigo-600 h-5 w-5" name={`${item?.name}`} id={`${item?.name}`} onBlur={handleBlur} onChange={handleChange} />
                                                    <span className="ml-2" htmlFor={item?.name}>{item?.label}</span>
                                                </label>
                                            </div>
                                        }

                                        {
                                            // only textarea field
                                            ["textarea"].includes(item?.type) && <div className="form-group">
                                                <label htmlFor={item?.name}>{item?.label}</label>
                                                <textarea name={item?.name} onChange={handleChange} id={item?.name} className="form-control" value={values[item?.name] || ''} placeholder={item?.placeholder} cols="6" rows="3"></textarea>
                                                {touched[item?.name] && errors[item?.name] && <div className='form-error'>{errors[item?.name]}</div>}
                                            </div>
                                        }
                                        {
                                            // only textarea field
                                            ["disabled"].includes(item?.type) && <div className="form-group">
                                                <label htmlFor={item?.name}>{item?.label}</label>
                                                <input name={item?.name} id={item?.name} onChange={handleChange} className="form-control disabled" value={values[item?.name] || ''} placeholder={item?.placeholder} />
                                                {touched[item?.name] && errors[item?.name] && <div className='form-error'>{errors[item?.name]}</div>}
                                            </div>
                                        }

                                        {
                                            // only payment method
                                            item?.type === "method" && <div className="form-group">
                                                <label htmlFor={item?.name}>{item?.label}</label>
                                                <select value={values[item?.name]} className="form-control form-control" onChange={e => { handleChange(e); handleFieldChange(e) }} data-controller="select" data-action="change->select#change" name={item?.name} id={item?.name}>
                                                    <option defaultValue={values[item?.name]} disabled >{item?.label}</option>
                                                    {method?.map((m, indx) => <option defaultValue={m.value} key={indx}>{m.value}</option>)}
                                                </select>
                                            </div>
                                        }
                                        {
                                            // only show shipping method   
                                            item?.type === "shippingMethod" && <div className="form-group">
                                                <label htmlFor={item?.name}>{item?.label}</label>
                                                <select value={values[item?.name]} className="form-control form-control" onChange={e => { handleChange(e); handleFieldChange(e) }} data-controller="select" data-action="change->select#change" name={item?.name} id={item?.name}>
                                                    <option defaultValue={values[item?.name]} disabled >{item?.label}</option>
                                                    {shippingMethod?.map((m, indx) => <option defaultValue={m.value} key={indx}>{m.value}</option>)}
                                                </select>
                                            </div>
                                        }
                                        {/* divisions related data select option functionality */}
                                        {
                                            item?.type === "divisions" && <div className="form-group">
                                                <label htmlFor="divisions">{item?.label}</label>
                                                <select className="form-control form-control" value={values[item?.name]} onChange={(e) => { handleChange(e); handleDivisionChange(e) }} data-controller="select" data-action="change->select#change" name={item?.name} id="divisions">
                                                    <option defaultValue={values[item?.name]} disabled>Select Division</option>
                                                    {divisions?.map((d, indx) => <option defaultValue={d.division} key={indx}>{d.division}</option>)}
                                                </select>
                                            </div>
                                        }

                                        {
                                            item?.type === "districts" && <div className="form-group">
                                                <label htmlFor="district">{item?.label}</label>
                                                <select className="form-control form-control" value={values[item?.name]} onChange={(e) => { handleChange(e); handleDistrictChange(e) }} data-controller="select" data-action="change->select#change" name={item?.name} id="district">
                                                    <option defaultValue={values[item?.name]} disabled >Select District</option>
                                                    {districts?.map((d, indx) => <option defaultValue={d.district} key={indx}>{d.district}</option>)}
                                                </select>
                                            </div>
                                        }

                                        {
                                            item?.type === "subDistricts" && <div className="form-group">
                                                <label htmlFor="subDistricts">{item?.label}</label>
                                                <select className="form-control form-control" value={values[item?.name]} onChange={(e) => { handleChange(e); handleChangeSubDistrict(e) }} data-controller="select" data-action="change->select#change" name={item?.name} id="subDistricts">
                                                    <option defaultValue={values[item?.name]} disabled >Select Sub-District</option>
                                                    {subDistricts?.map((d, indx) => <option defaultValue={d} key={indx}>{d}</option>)}
                                                </select>
                                            </div>
                                        }

                                        {
                                            item?.type === "stuffStatus" && <div className="form-group">
                                                <label htmlFor="stuffStatus">{item?.label}</label>
                                                <select className="form-control form-control" value={values[item?.name]} onChange={(e) => { handleChange(e); }} data-controller="select" data-action="change->select#change" name={item?.name} id="stuffStatus">
                                                    <option defaultValue={values[item?.name]}>Select Stuff</option>
                                                    {stuffStatus?.map((d, indx) => <option defaultValue={d.id} key={indx}>{d.value}</option>)}
                                                </select>
                                            </div>
                                        }
                                    </div>
                                )
                            }
                            {/* cancel and submit form area */}
                            <div className="modal-f mt-7">
                                <div className="secondary-action">
                                    {phoneError ? <button type="button" className='btn btn-primary btn-md cursor-default' disabled>{btnLabel}</button> : <button type="submit" className='btn btn-primary btn-md'>{btnLabel}</button>}
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </>
    )
}
