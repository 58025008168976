import {
  ADD_ATTR_NAME,
  ADD_ATTR_VALUE,
  ADD_PRODUCT,
  DELETE_PRODUCT_COUNT,
  DELETE_PRODUCT_FAIL,
  LOADED_PRODUCT,
  REMOVE_PRODUCT,
  SEARCH_PRODUCT,
  SET_IS_NEW_PRODUCT,
  SET_LOADING,
  SET_SHOW_TABLE_PRODUCT,
  SINGLE_PRODUCT,
  UPDATE_PRODUCT,
} from "../actionTypes/productActionTypes";

/**
 * Adds a product to the system.
 *
 * @param {object} product - The product to be added.
 * @return {object} - Returns an action object with the type ADD_PRODUCT and the payload as the product.
 */
export const addProduct = (product) => {
  return {
    type: ADD_PRODUCT,
    payload: product,
  };
};

export const set_is_new_product = (value) => {
  return {
    type: SET_IS_NEW_PRODUCT,
    payload: value,
  };
};

/**
 * Sets the loading state of the application.
 *
 * @param {boolean} loading - Indicates whether the application is currently loading or not.
 * @return {object} - An action object with the type SET_LOADING and the loading value as the payload.
 */
export const set_is_loading = (loading) => {
  return {
    type: SET_LOADING,
    payload: loading,
  };
};

/**
 * Delete the product count.
 *
 * @param {number} id - The ID of the product count to be deleted.
 * @return {object} The action object with the type DELETE_PRODUCT_COUNT and the payload as the ID.
 */
export const delete_product_count = (id) => {
  return {
    type: DELETE_PRODUCT_COUNT,
    payload: id,
  };
};

/**
 * Deletes a product and returns an object with the type DELETE_PRODUCT_FAIL and a payload containing the error message.
 *
 * @param {string} msg - The error message.
 * @returns {object} An object with the type DELETE_PRODUCT_FAIL and a payload containing the error message.
 */
export const delete_product_fail = (msg) => {
  return {
    type: DELETE_PRODUCT_FAIL,
    payload: msg,
  };
};

/**
 * Returns an object representing a single product.
 *
 * @param {Object} product - The product object.
 * @return {Object} - The object representing a single product.
 */
export const single_product = (product) => {
  return {
    type: SINGLE_PRODUCT,
    payload: product,
  };
};

/**
 * Adds an attribute name.
 *
 * @param {type} attr_name - The attribute name to be added.
 * @return {type} The result of adding the attribute name.
 */
export const add_attr_name = (attr_name) => {
  return {
    type: ADD_ATTR_NAME,
    payload: attr_name,
  };
};

/**
 * Creates an action to add an attribute value.
 *
 * @param {any} attr_value - The attribute value to be added.
 * @return {object} The action object with the type and payload.
 */
export const add_attr_value = (attr_value) => {
  return {
    type: ADD_ATTR_VALUE,
    payload: attr_value,
  };
};

/**
 * Removes a product with the given id.
 *
 * @param {any} id - The id of the product to be removed.
 * @return {object} An action object with the type REMOVE_PRODUCT and the id as payload.
 */
export const removeProduct = (id) => {
  return {
    type: REMOVE_PRODUCT,
    payload: id,
  };
};

/**
 * Sets the bulk action ID for a list of IDs.
 *
 * @param {Array} ids - The list of IDs to set the bulk action ID for.
 * @return {Object} - The action object with the type and payload.
 */
export const set_bulk_action_id = (ids) => {
  return {
    type: SET_SHOW_TABLE_PRODUCT,
    payload: ids,
  };
};

/**
 * Updates a product.
 *
 * @param {object} product - The product to be updated.
 * @return {object} An action object with the type UPDATE_PRODUCT and the updated product payload.
 */
export const updateProduct = (product) => {
  return {
    type: UPDATE_PRODUCT,
    payload: product,
  };
};

/**
 * Returns an action object to indicate that the products have been loaded.
 *
 * @param {array} products - The array of loaded products.
 * @return {object} An action object with the type and payload.
 */
export const loaded_products = (products) => {
  return {
    type: LOADED_PRODUCT,
    payload: products,
  };
};

/**
 * Sets the table of product.
 *
 * @param {type} tableOfProduct - The table of product.
 * @return {type} The result of setting the table of product.
 */
export const setTableOfProduct = (tableOfProduct) => {
  return {
    type: SET_SHOW_TABLE_PRODUCT,
    payload: tableOfProduct,
  };
};

/**
 * Creates an action to search for a product.
 *
 * @param {Object} product - The product to search for.
 * @return {Object} An action object with the type and payload.
 */
export const searchProduct = (product) => {
  return {
    type: SEARCH_PRODUCT,
    payload: product,
  };
};
