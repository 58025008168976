import { useContext, useEffect } from 'react'
import axios from "axios";
import { GetCookie } from "./Cookies";
import { parentContext } from '../App';

export default function useAxios() {

	const { environment, setEnvironment } = useContext(parentContext);

	let secure_auth = atob(GetCookie("ask")) || "",
		baseURL = "https://api.bonik.io",
		thisEnvironment = environment;

	if (window.location.href.includes("stage")) {
		baseURL = "https://stageapi.bonik.io";
		thisEnvironment = "stage";
	} else if (window.location.href.includes("localhost")) {
		baseURL = "http://dokaani.local";
		thisEnvironment = "local";
	}

	useEffect(() => {
		setEnvironment(thisEnvironment);
	})

	baseURL += "/query/v1";

	return axios.create({
		baseURL,
		headers: {
			Authorization: `Bearer ${secure_auth}`,
		},
	});
}
