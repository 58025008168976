import React, {
    useEffect,
    useState,
    useLayoutEffect,
    useCallback,
    Suspense,
} from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ProductSkeleton from "../../components/skeletonLoader/ProductSkeleton";
import { getLocalStorage, setLocalStorage } from "../../hooks/useLocalStorage";
import productFieldsValidation from "../../../util/validation/useValidation";
import { set_is_new_product } from "../../../redux/products/actions/productActions";
import { UseHelmet, useAxios } from "../../hooks";
import toast from "react-hot-toast";
import { cn } from "../../../util/cn";
import { PreLoaders, SimpleSpinner } from "../../helpers";
import { UpdateLoadingIcon } from "../../helpers/icons/Icons";

const GeneralTab = React.lazy(() => import("../../components/products/TabEditProduct/GeneralTab"));
const AttributesTab = React.lazy(() =>
    import("../../components/products/TabEditProduct/AttributesTab")
);
const VariationTab = React.lazy(() => import("../../components/products/TabEditProduct/VariationTab"));
const ChargesTab = React.lazy(() => import("../../components/products/TabEditProduct/ChargesTab"));

function EditProduct() {
    const axios = useAxios();
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isNewProduct } = useSelector((state) => state.product);
    const store_id = getLocalStorage("activeStore");
    const [showActionBar, setShowActionBar] = useState(false);
    const [isSaveLoading, setIsSaveLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState("general");
    const [anyError, setAnyError] = useState(false);
    const [fieldValue, setFieldValue] = useState([]);
    const [fieldErrors, setFieldErrors] = useState({});
    const [chargesData, setChargesData] = useState([]);
    const [stocks, setStocks] = useState([]);
    const [dataUnsaved, setDataChange] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [selectProductType, setSelectProductType] = useState(
        getLocalStorage("activeProductType") || "simple"
    );

    // attr data
    const [attrData, setAttrData] = useState([]);
    const [variationsData, setVariationsData] = useState([]);

    /**
     * Generates variations for a product.
     */
    const generateVariations = async () => {
        const allVariation = await axios.post("/variations/add", {
            product_id: id,
            force_regenerate: true,
        });
        if (allVariation?.data?.data) {
            (async () => {
                const response = await axios.get(
                    `/product?store_id=${store_id}&id=${id}`
                );
                const data = response?.data?.data;
                setVariationsData(data?.variations);
            })();
        }
    };

    /**
     * Fetches product data from the server and updates the component state.
     */
    useLayoutEffect(() => {
        (async () => {
            const response = await axios.get(
                `/product?store_id=${store_id}&id=${id}`
            );
            setLoading(true);
            const { status, data } = response?.data;
            const { type, regular_price, sale_price, stock, shipping_cost, production_cost, sku, category, tags, product_thumb } = data;
            setLocalStorage("activeProductType", type);
            setSelectProductType(type);

            if (data === false || status === false) {
                setLoading(false);
                navigate("/products");
            }

            setFieldValue({
                name: data.name,
                id: data.id,
                description: data.description,
                store_id: data.store_id,
                user_id: data.user_id,
                regular_price,
                sale_price,
                stock,
                shipping_cost,
                production_cost,
                sku,
                category,
                tags,
                product_thumb
            });

            setVariationsData(data?.variations);
            setChargesData(data?.charges);
            setStocks(data?.stocks);
            if (data.variations.length === 0) {
                generateVariations();
            }
            setLoading(false);
        })();
        // eslint-disable-next-line
    }, [store_id, id]);

    useEffect(() => {
        // if any attribute add the generate the variation
        if (attrData[0]?.attribute_values.length === 1) {
            generateVariations();
        }
        // eslint-disable-next-line
    }, [attrData]);

    /**
     * Displays a toast message when the status parameter is set to "success".
     */
    useEffect(() => {
        // when the status parameter is set to "success", display a toast message
        if (isNewProduct) {
            toast.success("Product created");
            dispatch(set_is_new_product(false));
        }
        // eslint-disable-next-line
    }, []);

    /**
     * Set the product type.
     */
    useEffect(() => {
        setSelectProductType(getLocalStorage("activeProductType"));
        // eslint-disable-next-line
    }, [selectProductType]);

    /**
     * Fetches all attributes for the product from the server and updates the component state.
     * Retrieves all attributes for a given product ID.
     *
     * @param {number} id - The product ID.
     * @return {Promise<void>} - A promise that resolves when the attributes data is set.
     */
    useEffect(() => {
        (async () => {
            const allAttr = await axios.post("/attributes/all", { product_id: id });
            setAttrData(allAttr.data?.data);
        })();
        // eslint-disable-next-line
    }, []);

    /**
     * Handles the variation change.
     *
     * @param {object} e - The event object.
     * @param {number} ids - The IDs of the variation.
     * @return {void}
     */
    const handleVariationChange = useCallback(
        (e, ids) => {
            setDataChange(true);
            // check variation data number positive or not
            if (e.currentTarget.name !== "stock" && e.currentTarget.value < 0) {
                e.currentTarget.value = 0;
            }

            let { name, value } = e.currentTarget;
            const oldData = [...variationsData];
            // set attr value data only changeable data
            setVariationsData(
                oldData.map((item) => {
                    if (item.variation_id === ids) {
                        item[name] = value;
                    }
                    return item;
                })
            );
        },
        [variationsData]
    );

    //set active page
    useEffect(() => {
        const edit_product_id = getLocalStorage("edit_product_id");
        if (edit_product_id !== null) {
            if (edit_product_id !== id) {
                setLocalStorage("p_a_t", "general");
                setLocalStorage("edit_product_id", id);
            }
        } else {
            setLocalStorage("edit_product_id", id);
        }
    }, [id]);

    useEffect(() => {
        const tabActive = getLocalStorage("p_a_t");
        if (tabActive === null) {
            setActiveTab("general");
        } else {
            setActiveTab(getLocalStorage("p_a_t"));
        }
    }, [activeTab]);

    /**
     * Handle tab change and save the locally
     * @param {*} e Tab Event
     * @returns
     */
    const tabChange = (e) => {
        setActiveTab(e.target?.id);
        setLocalStorage("p_a_t", e.target?.id);
    };

    const handleQuickAction = () => {
        setShowActionBar(!showActionBar);
    };

    /**
     * Deletes a charge from the chargesData array and sends a request to delete the charge on the server.
     *
     * @param {number} chargesID - The ID of the charge to be deleted.
     */
    const handleChargesDelete = async (chargesID) => {
        setChargesData(chargesData.filter((item) => item.id !== chargesID));
        const response = await axios.post("/charges/delete", {
            id: chargesID,
            product_id: id,
        });
        if (response.data.status) {
            toast.success("Extra Fees deleted.");
        }
    };

    /**
     * Function to remove local storage when returning to the products page.
     */
    const backBtn = () => {
        // remove local storage
        setLocalStorage("p_a_t", null);
    };

    /**
     * when data change then reload the page unsaved data then show alert
     * @param {*} e event
     */
    const beforeunload = (e) => {
        if (dataUnsaved) {
            e.preventDefault();
            e.returnValue = true;
        }
    };

    /**
     * if user forget to save
     */
    useEffect(() => {
        window.addEventListener("beforeunload", beforeunload);

        return () => {
            window.removeEventListener("beforeunload", beforeunload);
        };
        // eslint-disable-next-line
    }, [dataUnsaved]);


    /**
     * Submit product
     */
    const submitProduct = async () => {
        setIsSaveLoading(true);
        const validation = productFieldsValidation(
            fieldValue,
            fieldErrors,
            setFieldErrors
        );

        if (
            selectProductType === "simple" &&
            (validation.name ||
                validation.regular_price ||
                validation.sale_price ||
                validation.description)
        ) {
            setAnyError(true);
            setIsSaveLoading(false);
            return;
        } else {
            const productData = {
                ...fieldValue,
                store_id,
                variations: variationsData,
                type: selectProductType,
            };
            if (dataUnsaved) {
                setSaveLoading(true);
                const updateData = await axios.post("products/update", productData);
                if (updateData.data.status) {
                    toast.success("Product updated");
                    setDataChange(false);
                    setSaveLoading(false);

                    setIsSaveLoading(false);
                } else {
                    setIsSaveLoading(false);
                }
            } else {
                setIsSaveLoading(false);
            }
        }
    };


    return (
        <ProductSkeleton loading={loading}>
            <main
                className="flex-1 z-0 overflow-y-auto bg-backgroundGray p-8"
                onClick={() => showActionBar && handleQuickAction()}
            >
                <UseHelmet title={` #${id} - ${fieldValue?.name}`} />
                <div className="max-w-7xl mx-auto">
                    <div className="section-heading borderless">
                        <Link className="back-link btn btn-white btn-xs" data-controller="link" to="/products" onClick={backBtn}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                role="img"
                                className="icon h-5 w-5"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M11 17l-5-5m0 0l5-5m-5 5h12"
                                ></path>
                            </svg>
                        </Link>
                        <div className="title">
                            <h2>
                                <span className="text-2xl font-bold">
                                    #{id} - {fieldValue?.name}
                                </span>
                            </h2>
                        </div>
                        <div className="actions sm-hidden">
                            {
                                saveLoading ?
                                    <button type="button" className={`btn btn-primary btn-sm ${activeTab === ("attributes" || "charges") ? "disabled" : ""}`}>
                                        <UpdateLoadingIcon />
                                        <span>Updating...</span>
                                    </button>
                                    : <button
                                        type="button"
                                        className={`btn btn-primary btn-sm ${activeTab === ("attributes" || "charges") ? "disabled" : ""}`}
                                        onClick={submitProduct}
                                    >
                                        Save Changes
                                    </button>
                            }
                        </div>
                    </div>

                    <div className="sm-show hidden">
                        <div className="flex flex-end pb-4 ">
                            {((anyError || activeTab) !== ("attributes" || "charges")) ? (
                                <button
                                    type="button"
                                    className="btn btn-primary btn-sm"
                                    onClick={submitProduct}
                                >
                                    Save Changes
                                </button>
                            ) : (
                                <button
                                    type="button"
                                    className="btn btn-primary btn-sm disabled"
                                >
                                    Save Changes
                                </button>
                            )}
                        </div>
                    </div>

                    <div className="sm-relative">
                        <div
                            className="table-wrapper relative"
                            data-table-target="tableWrapper"
                        >
                            <div className="overflow-auto border border-gray-100 bg-gray-50 p-1 py-2 btw-0 flex justify-between items-center">
                                <ul className="flex items-center gap-4 text-md font-medium">
                                    <li className="list-none tab">
                                        <p
                                            onClick={tabChange}
                                            id="general"
                                            className={`ml-3 capitalize px-3 py-2 ${activeTab === "general"
                                                ? "inline-flex cursor-pointer items-center gap-2 rounded-lg hover:bg-white hover:text-gray-700 hover:shadow bg-white shadow text-gray-700"
                                                : "text-gray-500 cursor-pointer hover:bg-white hover:text-gray-700 hover:shadow hover:rounded-lg "
                                                }`}
                                        >
                                            General
                                        </p>
                                    </li>
                                    {selectProductType === "variable" && (
                                        <div className="flex items-center text-md font-medium">
                                            <li className="list-none">
                                                <p
                                                    onClick={tabChange}
                                                    id="attributes"
                                                    className={cn(
                                                        activeTab === "attributes" &&
                                                        "inline-flex cursor-pointer items-center rounded-lg px-3 py-2 hover:bg-white hover:text-gray-700 hover:shadow bg-white shadow text-gray-700",
                                                        "capitalize text-gray-500 cursor-pointer hover:bg-white hover:text-gray-700 hover:shadow hover:rounded-lg px-3 py-2"
                                                    )}
                                                >
                                                    Attributes
                                                </p>
                                            </li>
                                            <li className="list-none">
                                                <p
                                                    onClick={(e) => {
                                                        variationsData.length === 0
                                                            ? alert(
                                                                "First add attribute and attribute value!"
                                                            )
                                                            : tabChange(e);
                                                    }}
                                                    id="variant"
                                                    className={`ml-2 ${activeTab === "variant"
                                                        ? "inline-flex cursor-pointer items-center rounded-lg px-3 py-2 hover:bg-white hover:text-gray-700 hover:shadow bg-white shadow text-gray-700"
                                                        : "text-gray-500 cursor-pointer hover:bg-white hover:text-gray-700 hover:shadow hover:rounded-lg px-3 py-2"
                                                        } capitalize`}
                                                >
                                                    Variations
                                                </p>
                                            </li>
                                        </div>
                                    )}
                                    <li className="list-none">
                                        <p
                                            onClick={tabChange}
                                            id="charges"
                                            className={`pr-4 ${activeTab === "charges"
                                                ? "inline-flex cursor-pointer items-center rounded-lg px-3 py-2 hover:bg-white hover:text-gray-700 hover:shadow bg-white shadow text-gray-700"
                                                : "text-gray-500 cursor-pointer hover:bg-white hover:text-gray-700 hover:shadow hover:rounded-lg px-3 py-2"
                                                }`}
                                        >
                                            Charges
                                        </p>
                                    </li>
                                </ul>
                            </div>
                            {
                                // when save product is loading
                                isSaveLoading && <PreLoaders />
                            }
                            <div className="px-6 my-8">
                                <Suspense fallback={<SimpleSpinner />}>
                                    {activeTab === "general" && (
                                        <GeneralTab
                                            allStock={stocks}
                                            setStocks={setStocks}
                                            setDataChange={setDataChange}
                                            fieldErrors={fieldErrors}
                                            fieldValue={fieldValue}
                                            setFieldValue={setFieldValue}
                                            selectProductType={selectProductType}
                                            setSelectProductType={setSelectProductType}
                                        />
                                    )}
                                    {activeTab === "attributes" && (
                                        <AttributesTab data={attrData} setData={setAttrData} />
                                    )}
                                    {activeTab === "variant" && (
                                        <VariationTab
                                            data={variationsData}
                                            setData={setVariationsData}
                                            handleFieldChange={handleVariationChange}
                                        />
                                    )}
                                    {activeTab === "charges" && (
                                        <ChargesTab
                                            chargesData={chargesData}
                                            handleDelete={handleChargesDelete}
                                            setChargesData={setChargesData}
                                        />
                                    )}
                                </Suspense>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </ProductSkeleton>
    );
}

export default EditProduct;
