import React, { useContext, useEffect, useState } from 'react';
import { parentContext } from '../App';
import DokaaniForm from './DokaaniForm';
import { useDispatch, useSelector } from 'react-redux';
import { set_is_modal_close, set_is_modal_open } from '../../redux/globals/actions';
import { useSearchParams } from 'react-router-dom';
import { ModalCloseIcon, ModalPlusIcon } from '../helpers/icons/Icons';

export default function ModalWithTable({ showHardModal, btnLabel, tableName, newTableName, onlyHeading, children, formData, twoColumn, setFormData, isSubmit, isFormSubmit }) {
    const { setModalIndex } = useContext(parentContext);
    const { isModalOpen } = useSelector(state => state.global);
    const [searchParams,] = useSearchParams();
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (searchParams.get('create') === "true") {
            setShowModal(true);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setShowModal(showHardModal);
        // eslint-disable-next-line
    }, [showHardModal]);

    useEffect(() => {
        setModalIndex(isModalOpen);
        // eslint-disable-next-line
    }, [isModalOpen]);

    useEffect(() => {
        if (isFormSubmit === true) {
            setShowModal(false);
        }
    }, [isFormSubmit, isSubmit]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape' && isModalOpen) {
                dispatch(set_is_modal_close(false));
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isModalOpen, dispatch]);

    return (
        <main className={`flex-1 z-0 overflow-y-auto ${twoColumn ? "" : "bg-backgroundGray p-8"}`}>
            <div className="max-w-7xl mx-auto">
                {tableName && (
                    <div className="section-heading pb-0 border-b-none flex items-center justify-center">
                        <div className="title">
                            <h2>
                                <span>{tableName}</span>
                            </h2>
                        </div>
                        {!onlyHeading && (
                            <div className="actions">
                                {newTableName.includes("No products") ? (
                                    <p className='text-red-600 font-semibold'>{newTableName}</p>
                                ) : (
                                    <button type="button" onClick={() => { setShowModal(!showModal); dispatch(set_is_modal_open(true)) }} className="btn btn-transparent btn-sm" data-turbo-frame="modal" data-controller="link">
                                        <ModalPlusIcon />
                                        <span>{newTableName}</span>
                                    </button>
                                )}
                            </div>
                        )}
                    </div>
                )}
                {children}
                <div className={`modal-wrapper ${isModalOpen ? 'pr20 animate-fade-in' : "!hidden"}`} data-controller="modal" aria-modal="true">
                    <div className="modal-inner modal-align-responsive">
                        <div className="modal-overlay" aria-hidden="true" data-action="click->modal#close"></div>
                        <span className="modal-center-trick" aria-hidden="true">​</span>
                        <turbo-frame data-modal-target="turboFrame" id="modal" target="_top" src="https://app.dokaani.com" complete="">
                            <div className="modal w-responsive">
                                <button className="close" type="button" onClick={() => (dispatch(set_is_modal_close(false)))} data-action="click->modal#close">
                                    <ModalCloseIcon />
                                </button>
                                <div className="modal-header border-b-1">
                                    <h3>{newTableName}</h3>
                                </div>
                                {(formData && isModalOpen) && <DokaaniForm btnLabel={btnLabel} formData={formData} setFormData={setFormData} isSubmit={isSubmit} setShowModal={setShowModal} />}
                            </div>
                        </turbo-frame>
                    </div>
                </div>
            </div>
        </main>
    );
}
