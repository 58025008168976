import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { parentContext } from '../App';
import useAxios from '../hooks/useAxios';
import { validateNumber } from '../hooks/validateNumber';
import { GetCookie, SetCookie } from '../hooks/Cookies';
import { useLayoutEffect } from 'react';
import { useAuthAxios } from './useAuthAxios';
import { UseHelmet } from '../hooks';
import { EyeCloseIcon, EyeOpenIcon } from '../helpers/icons/Icons';

export default function Registration() {
    const loggedAxios = useAxios();
    const axios = useAuthAxios();
    const { setProgress } = useContext(parentContext);
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const { register, handleSubmit, watch, formState: { errors } } = useForm();

    /**  
     * check user logged in or not
     * if user logged in, navigate to dashboard
     * if user not logged in, navigate to login
     */
    useLayoutEffect(() => {
        // check auth true or not
        const checkCookie = atob(GetCookie("ask"));

        // if auth true then navigate to dashboard
        if (checkCookie) {
            setLoading(true);
            setProgress(40)
            // check user valid auth or not
            const checkUserAuth = async () => {
                const response = await loggedAxios.post("/users");
                setProgress(60);
                if (response.data.status === true) {
                    setLoading(false);
                    setProgress(100);
                    navigate("/dashboard");
                } else {
                    setLoading(false);
                    setProgress(100);
                    // clear all local storage
                    localStorage.clear();
                    // clear ask cookies
                    SetCookie('ask', '', -1);
                    setProgress(100);
                }
            }
            checkUserAuth();
            setProgress(100);
        }
        // eslint-disable-next-line
    }, [])

    // realtime phone number validation
    useEffect(() => {
        if (watch().phone.length > 1 && !validateNumber(watch().phone)) {
            setError("Invalid phone number");
        } else {
            setError('');
        }
        // eslint-disable-next-line
    }, [watch().phone]);

    /**
     * handle phone number length validation
     * @param {*} event phone event
     */
    function handleKeyDown(event) {
        const allowedKeys = ["Enter", "Backspace", "Tab", "ArrowRight", "ArrowLeft", "Ctrl", "c", "a", "x", "v", "Delete", "Home", "End"];
        const isMaxLengthReached = event.target.value.length >= 11;

        if (!allowedKeys.includes(event.key) && isMaxLengthReached) {
            event.preventDefault();
        }
    }

    /**
     * Handles the toggle of the password visibility.
     *
     * @return {void} 
     */
    const handleTogglePin = () => {
        setShowPassword(!showPassword);
    }

    /**
     * Handle pin number event.
     *
     * @param {Event} event - The event object.
     */
    const handlePinNumber = (event) => {
        //allow only numbers
        const restrictedKeys = /Enter|Backspace|Tab|ArrowRight|ArrowLeft|Ctrl|c|a|x|v|Delete|Home|Tab|End/;
        const isKeyRestricted = restrictedKeys.test(event.key);
        if (!isKeyRestricted && isNaN(Number(event.key))) {
            event.preventDefault();
        }
    }

    /**
     * when submit form then store to database
     * Save to user hash in local storage and navigate to new store page
     * @param {*} userData mobile and password value
     * */
    const onSubmit = async userData => {

        setProgress(20);
        try {
            const validatePhone = validateNumber(userData.phone);
            // when phone number is validate
            if (validatePhone) {
                setProgress(40);
                // save the user
                const response = await axios.post(`/users/register`,
                    { full_name: userData?.full_name, mobile: userData?.phone, pin: userData?.password }
                );
                // get data from response
                const { status } = response.data;
                setProgress(60);

                // if status is true then save user hash in local storage
                if (status === true) {
                    localStorage.setItem("mobile", userData?.phone);
                    navigate("/verify_otp");
                } else {
                    // if status is false then show error msg
                    setProgress(100);
                    setError("User already exits!");
                }
            } else {
                // when phone number is not validate
                setProgress(100);
                setError("Enter Bangladeshi phone number");
            }
        } catch (e) {
            setProgress(100);
        }

    }

    return (
        <>
            <UseHelmet title="Registration" />
            {
                !loading ?
                    <section className='h-screen flex'>
                        <main className="w-full p-6 max-w-sm m-auto">
                            <img src="/assets/images/login/dokaani-en.svg" className="m-auto mb-8 h-10 w-auto" style={{ width: "160px" }} alt="Dokaani logo" />
                            {/* start registration form */}
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="basic-heading space-y-1 mb-4 px-4">
                                    <h2 className="text-xl text-center font-semibold">
                                        Create a New Account
                                    </h2>
                                    {
                                        /* show error msg */
                                        error && <p className="form-group-error text-xl text-center text-red-600 pt-2">{error && error}</p>
                                    }
                                </div>

                                <div className="form-group">
                                    <label htmlFor="full_name">Full Name</label>
                                    <input autoComplete="off" placeholder="MR Karim" autoFocus={true} {...register("full_name", { required: true, minLength: 3 })} className="form-control" type="text" name="full_name" id="full_name" />
                                    {errors.full_name && <span className='form-error'>Enter a valid name.</span>}
                                </div>

                                <div className="form-group">
                                    <label htmlFor="phone">Phone Number</label>
                                    <input autoComplete="off" placeholder="01XXXXXXXXX" onKeyDown={handleKeyDown} {...register("phone", { required: true, maxLength: 11 })} maxLength={11} className="form-control" type="number" name="phone" id='phone' />
                                    {errors.phone && <span className='form-error'>Enter a valid phone number.</span>}
                                </div>

                                <div className="form-group">
                                    <label htmlFor="pin">Pin Number</label>
                                    <div className="relative">
                                        <input autoComplete="off" placeholder="******" onKeyDown={handlePinNumber} {...register("password", { required: true, minLength: 5 })} className="form-control" type={showPassword ? "text" : "password"} name="password" id="pin" />
                                        {
                                            !showPassword ? <EyeCloseIcon handleToggleIcon={handleTogglePin} /> : <EyeOpenIcon handleToggleIcon={handleTogglePin} />
                                        }
                                    </div>
                                    {errors.password && <span className='form-error'>PIN more than 5 character.</span>}
                                </div>
                                <button type="submit" disabled={error ? true : false} className="btn btn-primary btn-lg btn-block" data-controller="form-submit"><span>Sign Up</span></button>
                            </form>
                            <p className="text-center mt-4 px-4">
                                You agree to Dokaani's <a href="/registration" target="blank">Privacy Policy</a> and <a href="/registration" target="blank">Terms of Service</a>.
                            </p>
                            <div className="basic-links flex space-x-4 justify-center mt-4">
                                <Link data-controller="link" to="/"><span>Already signed up? Sign In</span></Link>
                            </div>
                        </main>
                    </section>
                    :
                    //  loading spinner
                    <div className="loader-container">
                        <div className="loader"></div>
                    </div>
            }
        </>
    )
}
