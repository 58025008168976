import React, { useContext } from 'react'
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { parentContext } from '../App';
import FormatePhoneNumber from '../helpers/FormatePhoneNumber';
import { added_available_order_status } from '../../redux/user/actions';
import { setLocalStorage } from '../hooks/useLocalStorage';

// Parent component SideBar
export default function StoreToggle({ storeInfo, setStoreInfo, store, storeToggle, setStoreToggle }) {
    const { setProgress } = useContext(parentContext);
    const dispatch = useDispatch()

    /**
     * Handle Change store
     * when click on store button 
     * Change the store data 
     * @param {*} id store id
     * @returns
     */
    const handleStore = async (id) => {
        setProgress(20);
        const changeStore = store?.find(store => store.id === id);
        setProgress(40);
        setTimeout(() => {
            setProgress(60);
            setStoreInfo(changeStore);
            setLocalStorage("activeStore", id);
            setLocalStorage("storeInfo", changeStore);
            // window.location.reload();
            dispatch(added_available_order_status(changeStore?.order_statuses));
            setProgress(100);
        }, 1000);
    }

    return (
        <div className="sidebar-account">
            {storeInfo && <div className="dropdown flex-1 w-full" data-controller="dropdown">
                <button onClick={() => setStoreToggle(!storeToggle)} type="button" className="btn btn-transparent w-full" aria-expanded="true" aria-haspopup="true" data-dropdown-target="button" data-action="dropdown#toggleMenu">
                    <span className="avatar h-10 w-10"><span className="text-sm font-medium leading-none text-primaryGray">{<span className='text-sm capitalize'>
                        {storeInfo?.name && storeInfo?.name.slice(0, 1).toUpperCase()}
                    </span>}
                    </span>
                    </span>
                    <div className="btn-content">
                        {
                            <span className='text-sm capitalize '>{storeInfo?.name}</span>
                        }
                        <span>{FormatePhoneNumber(storeInfo?.phone)}</span>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" role="img" className="icon h-4 w-5">
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                            <g transform="translate(1.000000, 1.000000)" stroke="currentColor" strokeWidth="2">
                                <polyline id="Path" transform="translate(7.000000, 3.500000) rotate(180.000000) translate(-7.000000, -3.500000) " points="14 0 7 7 0 0"></polyline>
                                <polyline id="Path" points="14 15 7 22 0 15"></polyline>
                            </g>
                        </g>
                    </svg>
                </button>

                <div className={`absolute md:w-[16rem] z-50 mt-2 flex flex-col rounded-md bg-primary p-4 gap-3 shadow-lg  origin-top-right left-full ml-2 top-0 responsive-toggle-box ${storeToggle ? "!hidden" : ""}`} role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1" data-dropdown-target="menu">
                    <span className="uppercase">My Stores</span>
                    {
                        //Store List and Toggle Store Button
                        store?.map(str => <div key={str?.id} id={str?.id} onClick={() => handleStore(str?.id)} className='cursor-pointer' data-controller="link">
                            <span className="avatar h-7 w-7 mr-2">
                                <span className="text-sm font-medium leading-none text-primaryGray">{str?.name && str?.name.slice(0, 1).toUpperCase()}</span>
                            </span>
                            <span className="truncate flex-1 capitalize">{str?.name}</span>
                        </div>)
                    }
                    <Link to="/store/new" data-controller="link">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" role="img" className="icon h-5 w-5">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4"></path>
                        </svg>
                        <span>New Store</span>
                    </Link>
                </div>
            </div>}
        </div>
    )
}
