import React, { memo, useCallback } from 'react';
import UseHelmet from '../../hooks/UseHelmet';
import ProductSkeleton from '../../components/skeletonLoader/ProductSkeleton';
import { useState } from 'react';
import CurrentPlan from '../../components/billing/tabs/CurrentPlan';
import Addon from '../../components/billing/tabs/Addon';

const Billing = memo(() => {
    const [activeTab, setActiveTab] = useState("plans")

    // change the active tab
    const tabChange = useCallback((e) => {
        setActiveTab(e.target?.id);
    }, [])

    return (
        <ProductSkeleton>
            <main className="flex-1 z-0 overflow-y-auto bg-backgroundGray p-8">
                <UseHelmet title="Billings" />
                <div className="max-w-7xl mx-auto">
                    <div className="section-heading borderless ">
                        <div className="title">
                            <h2>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" role="img" className="icon h-7 w-7 d-inline">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                </svg>

                                <span>Plans &amp; Billing</span>
                            </h2>
                        </div>
                        <div className="actions">

                        </div>
                    </div>
                    <div data-controller="table">
                        <div className="table-wrapper " data-table-target="tableWrapper">
                            <div className="table-filters">
                                <div className="tabs flex flex-between">
                                    <nav>
                                        <p onClick={tabChange} id="plans" className={`${activeTab === "plans" ? "active-link exact-active-link" : ''}`}>Current Plan</p>
                                        <p onClick={tabChange} id="addons" className={`${activeTab === "addons" ? "active-link exact-active-link" : ''}`}>Addons</p>
                                    </nav>
                                </div>
                            </div>

                            <div className="px-6 my-8" style={{ minHeight: "300px" }}>
                                {activeTab === "plans" && <CurrentPlan />}
                                {activeTab === "addons" && <Addon />}

                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </ProductSkeleton>
    )
})

export default Billing
