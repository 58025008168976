export const ordersInitialValues = {
	activeTab: "products-tab",
	isNewOrder: false,
	isWalkInCustomer: false,
	showFilter: false,
	editOrderLoading: true,
	isLoading: true,
	deleteOrderLoading: false,
	allOrder: [],
	tableOfOrders: [],
	searchOrders: [],
	ifSearchFalse: [],
	orderData: [],
	orderedPayment: [],
	addressFormData: [],
	addressData: [],
	allProducts: [],
	productDetails: [],
	discountInOrder: [],
	totalOrderAmount: 0,
	totalPaymentAmount: 0,
	dueAmount: 0,
	showAddressModal: false,
	addressDataSubmit: false,
	orderProductItem: [],
	isAddOrderProductLoading: false,
	isDeleteOrderPaymentLoading: 0,
	isDeleteOrderProductLoading: 0,
	addressName: "Select Address",
	fullAddress: "",
	addressID: "",
	orderStatus: "",
};
