import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { parentContext } from '../../../App';
import { set_is_new_product_modal } from '../../../../redux/globals/actions';
import useProductFunc from '../../../../util/products/useProductFunc';
import { setLocalStorage } from '../../../hooks/useLocalStorage';
import { cn } from '../../../../util/cn';
import LoadingButton from '../../Buttons/LoadingButton';

const productTypes = [
	{ id: 1, name: "simple" },
	{ id: 2, name: "variable" },
	{ id: 3, name: "group" },
];

export default function AddProductModal() {
	const { setModalIndex } = useContext(parentContext);
	const dispatch = useDispatch();
	const { isNewProductModalOpen } = useSelector(state => state.global);
	const { register, handleSubmit, formState: { errors } } = useForm();
	const { saveProduct } = useProductFunc();
	const activeStore = localStorage.getItem("activeStore");
	const [selectProductType, setSelectProductType] = useState("simple");
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setModalIndex(isNewProductModalOpen);
		// eslint-disable-next-line
	}, [isNewProductModalOpen]);

	// After clicking the ESC button the modal will be closed
	useEffect(() => {
		const handleEsc = (event) => {
			if (event.key === 'Escape') {
				dispatch(set_is_new_product_modal(false));
			}
		};
		document.addEventListener('keydown', handleEsc);
		return () => {
			document.removeEventListener('keydown', handleEsc);
		};
	}, [dispatch]);



	const onSubmit = async (data) => {
		await saveProduct({ ...data, type: selectProductType }, activeStore, setLoading);
	}

	const handleProductType = (name) => {
		setSelectProductType(name);
		setLocalStorage("activeProductType", name);
	};

	return (
		<div className={`modal-wrapper  ${isNewProductModalOpen ? 'pr20 animate-fade-in' : "!hidden"}`} data-controller="modal" aria-modal="true">
			<div className="modal-inner modal-align-responsive">
				<div className="modal-overlay" aria-hidden="true" data-action="click->modal#close"></div>
				<span className="modal-center-trick" aria-hidden="true">​</span>
				<turbo-frame data-modal-target="turboFrame" id="modal" target="_top" src="https://app.dokaani.com" complete="">
					<div className="modal w-responsive">
						<button className="close" type="button" onClick={() => dispatch(set_is_new_product_modal(false))} data-action="click->modal#close">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" role="img" className="icon h-6 w-6">
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
							</svg>
						</button>

						<div className="modal-header border-b-1">
							<h3>Add Product</h3>
						</div>

						<div className="modal-body">
							<form onSubmit={handleSubmit(onSubmit)}>
								<div className="form-group">
									<label htmlFor="name">Product Name</label>
									<input autoComplete='off' placeholder="T-shirt" {...register("name", { required: true })} className="form-control" type="text" name="name" id="name" />
									{errors.name && <span className='form-error mt-2 block'>Enter a valid name.</span>}
								</div>

								<div className="form-group">
									<label htmlFor="desc">Product Description</label>
									<textarea autoComplete='off' placeholder="Best for men." {...register("description", { required: false })} className="form-control" type="text" name="description" id="desc" />
								</div>
								<div className="form-group">
									<label htmlFor="type">Product Type</label>
									<div className="flex">
										{
											productTypes?.map((item, index) =>
												<div key={index} onClick={() => handleProductType(item?.name)} className={cn(
													'shadow-sm cursor-pointer text-sm font-medium text-center py-1.5 md:px-4 px-2',
													index === 0 && 'rounded-l-md',
													index !== productTypes.length - 1 && 'border-r',
													index === productTypes.length - 1 && 'rounded-r-md',
													item.name === selectProductType ? 'bg-primary-900 text-white' : 'bg-primary-100 text-primary-200',
													item.name === "group" && 'disabled'
												)}>
													<button className="tag_txt" type='button'>{item.name}</button>
												</div>
											)
										}
									</div>
								</div>
								<div className="modal-f mt-7">
									<div className="secondary-action">
										{
											loading ? <LoadingButton title={"Creating"} />
												:
												<button type="submit" className='btn btn-primary btn-md'>Create Product</button>
										}
									</div>
								</div>
							</form>
						</div>

					</div>
				</turbo-frame>
			</div>
		</div>
	)
}
