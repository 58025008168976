import React, { memo } from "react";

const data = [
    {
        id: 1,
        title: "Expiry Time (in Month)",
        svg: <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
        </svg>
        ,
        cap: "2",
        ocp: "1",
    },

    {
        id: 88,
        title: "Stores",
        svg: <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z" />
        </svg>
        ,
        cap: "5",
        ocp: "3",
    },
    {
        id: 2,
        title: "Products",
        svg: <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
        </svg>
        ,
        cap: "5",
        ocp: "3",
    },
    {
        id: 3,
        title: "Customers",
        svg: <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
        </svg>
        ,
        cap: "10",
        ocp: "5",
    },
    {
        id: 4,
        title: "Orders",
        svg: <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
        </svg>
        ,
        cap: "10",
        ocp: "5",
    },
    {
        id: 8,
        title: "Payment",
        svg: <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        ,
        cap: "10",
        ocp: "5",
    },
    {
        id: 9,
        title: "Reports",
        svg: <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" />
        </svg>
        ,
        cap: "10",
        ocp: "5",
    },
    {
        id: 10,
        title: "SMS",
        svg: <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
        </svg>
        ,
        cap: "10",
        ocp: "5",
    },
    {
        id: 5,
        title: "Invoices",
        svg: <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z" />
        </svg>,
        cap: "10",
        ocp: "5",
    },
    {
        id: 6,
        title: "Coupons",
        svg: <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.666 3.888A2.25 2.25 0 0013.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 01-.75.75H9a.75.75 0 01-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 01-2.25 2.25H6.75A2.25 2.25 0 014.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 011.927-.184" />
        </svg>
        ,
        cap: "10",
        ocp: "5",
    },
    {
        id: 7,
        title: "Shipping",
        svg: <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205l3 1m1.5.5l-1.5-.5M6.75 7.364V3h-3v18m3-13.636l10.5-3.819" />
        </svg>
        ,
        cap: "10",
        ocp: "5",
    }
]

const Addon = memo(() => {
    return (
        <div>
            <div className="mb-8">
                <h2 className="text-2xl font-semibold mb-8">Basic options</h2>
                <div className="grid md:grid-cols-2 md:auto-cols-auto lg:grid-cols-3 xl:grid-cols-4 sm:grid-cols-1 gap-6">
                    {
                        data.map(item => (
                            <div key={item.id} className="border-grayCust-250 border rounded-xl mx-auto relative w-full ">
                                <div className="flex items-center px-3 py-4 rounded-tl-xl rounded-tr-xl bg-white">
                                    <div className="bg-white rounded-lg w-10 h-10 cursor-pointer flex justify-center items-center card-bg">
                                        {item.svg}
                                    </div>
                                    <div className="ml-1">
                                        <div className="text-grayCust-800 text-sm font-semibold cursor-pointer text-center truncate mr-1 max-w-[170px]">{item.title}</div>
                                    </div>
                                </div>
                                <span className="relative z-0 flex justify-between items-center pl-4 pb-4 rounded-md bg-grayCust-50 rounded-bl-xl rounded-br-xl">
                                    <div className="flex items-center">
                                        {/* calculate increment or decrement */}
                                        <button type="button" className="relative w-9 h-9 inline-flex items-center justify-center rounded-l-md border border-grayCust-250 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary-900 focus:border-primary-900">
                                            <svg width="9" height="2" viewBox="0 0 9 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" fill="black" d="M0.666626 1.0002C0.666626 0.788022 0.750912 0.584539 0.900941 0.43451C1.05097 0.284481 1.25445 0.200195 1.46663 0.200195H7.86663C8.0788 0.200195 8.28228 0.284481 8.43231 0.43451C8.58234 0.584539 8.66663 0.788022 8.66663 1.0002C8.66663 1.21237 8.58234 1.41585 8.43231 1.56588C8.28228 1.71591 8.0788 1.8002 7.86663 1.8002H1.46663C1.25445 1.8002 1.05097 1.71591 0.900941 1.56588C0.750912 1.41585 0.666626 1.21237 0.666626 1.0002Z"></path>
                                            </svg>
                                        </button>
                                        <input className="text-center disabled relative w-14 h-9 inline-flex items-center justify-center border border-grayCust-250 bg-white text-sm font-semibold text-grayCust-800 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-none focus:border-none" readOnly="" value={item.cap} />
                                        <button type="button" className="relative w-9 h-9 inline-flex items-center justify-center rounded-r-md border border-grayCust-250 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary-900 focus:border-primary-900">
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M6.26663 0C6.4788 0 6.68228 0.0842854 6.83231 0.234315C6.98234 0.384344 7.06663 0.587827 7.06663 0.8V4.8H11.0666C11.2788 4.8 11.4823 4.88429 11.6323 5.03431C11.7823 5.18434 11.8666 5.38783 11.8666 5.6C11.8666 5.81217 11.7823 6.01566 11.6323 6.16569C11.4823 6.31571 11.2788 6.4 11.0666 6.4H7.06663V10.4C7.06663 10.6122 6.98234 10.8157 6.83231 10.9657C6.68228 11.1157 6.4788 11.2 6.26663 11.2C6.05445 11.2 5.85097 11.1157 5.70094 10.9657C5.55091 10.8157 5.46663 10.6122 5.46663 10.4V6.4H1.46663C1.25445 6.4 1.05097 6.31571 0.900941 6.16569C0.750911 6.01566 0.666626 5.81217 0.666626 5.6C0.666626 5.38783 0.750911 5.18434 0.900941 5.03431C1.05097 4.88429 1.25445 4.8 1.46663 4.8H5.46663V0.8C5.46663 0.587827 5.55091 0.384344 5.70094 0.234315C5.85097 0.0842854 6.05445 0 6.26663 0Z" fill="black"></path>
                                            </svg>
                                        </button>
                                    </div>
                                </span>
                                <div className="p-4">
                                    <div className="flex items-center justify-between pb-2">
                                        <div className="flex items-center">
                                            <p className="text-sm font-medium text-grayCust-1600">Occupied</p>
                                        </div>
                                        <div>
                                            <p className="text-sm font-medium text-grayCust-1600">{item.ocp}</p>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center">
                                            <p className="text-sm font-medium text-grayCust-1600">Capabilities</p>
                                        </div>
                                        <div>
                                            <p className="text-sm font-medium text-grayCust-1600">{item.cap}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }

                </div>
            </div>
        </div>
    );
})
export default Addon;